import ClassName from 'classnames';
// import 'antd/dist/antd.css';
// import { Input } from 'antd';
import React, {useMemo, useState} from 'react';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export default function TestPage() {
  const { t, i18n } = useTranslation();
  const { connected, account, network, connect, ...rest } = useWallet();

  console.log(`i18n: `, i18n);
  // debugger;

  const changeLanguage = (lng) => {
    console.log(`lng: `, lng);
    // debugger;
    i18n.changeLanguage(lng);
  };

  return (
    <div className="">
      <div className='my-[100px]'>
      {t('Welcome to React')}
      </div>

      <button type="button" onClick={() => changeLanguage('ko')}>
        ko
      </button>
      <br/>
      <button type="button" onClick={() => changeLanguage('en')}>
        en
      </button>
    </div>
  );
}
