// import TwiceConfirmModal from 'components/Modal/TwiceConfirmModal';
import SubmitCompH5 from './SubmitCompH5';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import back from 'assets/img/icon/back.png';

import iconDatabase from 'assets/img/icon/icon-database.png';
import iconHand from 'assets/img/icon/icon-hand.png';
import { config } from 'utils/sdk.v2.js';
import { useNavigate, useLocation } from 'react-router-dom';
import className from 'classnames';
import { SDKContext } from 'contexts/SDKProvider';
import { openErrorNotification } from 'utils/notifications';
import { notification } from '../../components/Antd';
import { decimalsCfg } from 'config/decimals';
import { useTranslation } from 'react-i18next';


export default function WithdrawPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(`history: `, location);

  // @ts-ignore
  const { depositSelectListData, withdrawSelectListData, setIsTransfer, setDepositSelectListData, setRouteWithdrawParam } = useContext(SDKContext);
  const { connected, account, network, connect, signAndSubmitTransaction, ...rest } = useWallet();
  const [withdrawAmount, setWithdrawAmount] = useState<string>('');
  const [isWithdrawBtnDisable, setIsWithdrawBtnDisable] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  let selectWithdrawData = withdrawSelectListData.find(item => item.isSelect);
  let withdraw_wallet_value = selectWithdrawData?.wallet_value;
  let underlying_amount = selectWithdrawData?.underlying_amount || 0;
  // @ts-ignore
  // let max_to_redeem = selectWithdrawData ? Number(window?.state?.max_to_redeem(selectWithdrawData?.coin_type)?.format(selectWithdrawData?.decimals))?.toFixed(decimalsCfg?.[selectWithdrawData?.name]) : 0;
  let max_to_redeem = selectWithdrawData ? Math.floor(Number(window?.state?.max_to_redeem(selectWithdrawData?.coin_type)?.format(selectWithdrawData?.decimals)) * Math.pow(10, decimalsCfg[selectWithdrawData?.name])) / Math.pow(10, decimalsCfg[selectWithdrawData?.name]) : 0;

  // @ts-ignore
  window.selectWithdrawData = selectWithdrawData;

  const checkWithdrawBtn = (value) => {
    setTimeout(() => {
      if (Number(value) > 0 && Number(value) <= Number(underlying_amount)) {
        setIsWithdrawBtnDisable(false);
      } else {
        setIsWithdrawBtnDisable(true);
      }
    });
  }

  const reg = /^\D*(\d*(?:\.\d{0,5})?).*$/g;
  const onChangeWithdraw = (e: any) => {
    // console.log('changed', e.target.value);
    const value = e.target.value;
    if (!value) return setWithdrawAmount('');
    if (!reg.test(value)) {
      let newValue = value.replace(reg, '$1');
      // debugger;
      setWithdrawAmount(newValue);
      return
    }
    // if (Number(value) > Number(underlying_amount)) {
    if (Number(value) > max_to_redeem) {
      setWithdrawAmount(String(max_to_redeem));
      checkWithdrawBtn(max_to_redeem);
      return
    }
    setWithdrawAmount(value);
    checkWithdrawBtn(value);
  };

  const onBlurWithdraw = (e: any) => {
    let value = e.target.value;
    if (!value) return;
    if (!reg.test(value)) {
      let newValue = value.replace(reg, '$1');
      // debugger;
      setWithdrawAmount(newValue);
      return
    }
    // if (Number(value) > Number(underlying_amount)) {
    if (Number(value) > max_to_redeem) {
      // debugger;
      setWithdrawAmount(String(max_to_redeem));
      checkWithdrawBtn(max_to_redeem);
      return
    }
    if (Number(value) <= 0) {
      // debugger;
      setWithdrawAmount(underlying_amount);
      checkWithdrawBtn(underlying_amount);
      return
    }
    setWithdrawAmount(value);
    checkWithdrawBtn(value);
  }

  const onClickSetWithdrawMax = (e: any) => {
    // // console.log(`selectData: `, selectData);
    // let value = Number(underlying_amount) || 0;
    // @ts-ignore
    // let value = Math.floor()Number(window?.state?.max_to_redeem(selectWithdrawData?.coin_type)?.format(selectWithdrawData?.decimals))?.toFixed(decimalsCfg?.[selectWithdrawData?.name]) || 0;
    let value = Math.floor(Number(window?.state?.max_to_redeem(selectWithdrawData?.coin_type)?.format(selectWithdrawData?.decimals)) * Math.pow(10, decimalsCfg[selectWithdrawData?.name])) / Math.pow(10, decimalsCfg[selectWithdrawData?.name]) || 0;
    // @ts-ignore
    setWithdrawAmount(value);
    checkWithdrawBtn(value);
  }

  const onClickWithdraw = async () => {
    // @ts-ignore;
    // console.log(`Number(depositAmount ** 100000000): `, Number(withdrawAmount * 100000000));
    // debugger;
    // todo user is login and active
    try {
      // @ts-ignore
      let pending_tx = await signAndSubmitTransaction({
        type: 'entry_function_payload',
        function: `${config.LENDING}::periphery::redeem`, // withdraw
        type_arguments: [selectWithdrawData.coin_type],
        // @ts-ignore
        arguments: [Number(Math.floor(withdrawAmount * Math.pow(10, selectWithdrawData.decimals)))?.toString()]
      });

      // @ts-ignore
      let tx = await window?.loader?.client?.waitForTransactionWithResult(pending_tx.hash, { checkSuccess: true });
      // console.log(`tx: `, tx);

      // @ts-ignore
      // // console.log(`window?.state: `, await window.aptos.account());
      // @ts-ignore
      // let address = await window.aptos.account();
      // @ts-ignore
      await window?.loader.reload_user_balance(account?.address);
      // @ts-ignore
      // console.log(`window?.state: `, window?.state);
      // @ts-ignore
      await loader.reload_user_coin(window?.state, selectWithdrawData.coin_type);
      debugger;
      // @ts-ignore
      setIsTransfer(false);
      setIsModalVisible(false);
      // @ts-ignore
      // console.log(`loader: `, window?.loader);
      // debugger;
      notification.destroy();
      openErrorNotification({ type: 'success', detail: tx.vm_status });

      setTimeout(() => {
        // @ts-ignore
        window.location.reload();
      }, 3000);
    } catch (error) {
      if (error?.message) {
        notification.destroy();
        openErrorNotification({ detail: error.message });
      }
      setIsTransfer(false);
    }
  }

  const onClickWithdrawAll = async () => {
    // @ts-ignore;
    // console.log(`Number(depositAmount ** 100000000): `, Number(withdrawAmount * 100000000));
    // debugger;
    // todo user is login and active
    try {
      // @ts-ignore
      let pending_tx = await signAndSubmitTransaction({
        type: 'entry_function_payload',
        function: `${config.LENDING}::periphery::redeem_all`, // withdraw
        type_arguments: [selectWithdrawData.coin_type],
        // @ts-ignore
        arguments: []
      });

      // @ts-ignore
      let tx = await window?.loader?.client?.waitForTransactionWithResult(pending_tx.hash, { checkSuccess: true });
      // console.log(`tx: `, tx);

      // @ts-ignore
      // // console.log(`window?.state: `, await window.aptos.account());
      // @ts-ignore
      // let address = await window.aptos.account();
      // @ts-ignore
      await window?.loader.reload_user_balance(account?.address);
      // @ts-ignore
      // console.log(`window?.state: `, window?.state);
      // @ts-ignore
      await loader.reload_user_coin(window?.state, selectWithdrawData.coin_type);
      debugger;
      // @ts-ignore
      setIsTransfer(false);
      setIsModalVisible(false);
      // @ts-ignore
      // console.log(`loader: `, window?.loader);
      // debugger;
      notification.destroy();
      openErrorNotification({ type: 'success', detail: tx.vm_status });

      setTimeout(() => {
        // @ts-ignore
        window.location.reload();
      }, 3000);
    } catch (error) {
      if (error?.message) {
        notification.destroy();
        openErrorNotification({ detail: error.message });
      }
      setIsTransfer(false);
    }
  }

  useEffect(() => {
    // console.log(`location: `, location);
    let name = decodeURIComponent(window.location.hash.slice(1));
    if (!name) return;
    setRouteWithdrawParam(name);
  }, [location])


  // @ts-ignore
  return (
    <div className="flex md:hidden min-h-[100vh] flex-col items-center">
      <section className="w-full flex flex-col justify-start min-h-[82vh]">
        <section className="w-full px-[3%]">
          <section className="w-full">

            {/* back */}
            <section className="flex w-full" onClick={() => {
              navigate('/deposit-list');
            }}>
              <section className="h-[49px] px-[20px] text-[#31767A] flex items-center justify-center cursor-pointer border border-[#000] rounded-full hover:bg-[white]">
                <section className="w-[20px] mr-3">
                  <img src={back} alt='' className="w-full" />
                </section>
                {
                  t('back')
                }
              </section>
            </section>

            {/* tabs */}
            <section className="w-full flex justify-between mt-[20px]">
              <section className="w-full flex h-[36px] border border-[#454545] rounded-xl cursor-pointer text-sm">
                <div onClick={() => {
                  navigate(`/deposit${location.hash}`);
                }} className={className('flex items-center justify-center w-1/2 rounded-l-xl ')}>
                  <div className="w-[15px] mr-3">
                    <img src={iconDatabase} alt='' className="w-full" />
                  </div>
                  {
                    t('Deposit')
                  }
                </div>

                <div className={className('flex items-center justify-center w-1/2 rounded-r-xl border-l border-[#454545] bg-primeBlackTab')}>
                  <div className="w-[15px] mr-3">
                    <img src={iconHand} alt='' className="w-full" />
                  </div>
                  {
                    t('Withdraw')
                  }
                </div>
              </section>
            </section>

            <section className="">
              <SubmitCompH5 isBtnDisable={isWithdrawBtnDisable}
                inputValue={withdrawAmount}
                selectListData={withdrawSelectListData}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                onClickSetMax={onClickSetWithdrawMax}
                onBlur={onBlurWithdraw}
                setAmount={setWithdrawAmount}
                onChangeInput={onChangeWithdraw}
                onSubmitFn={onClickWithdraw} options={{
                  // name: 'withdraw',
                  // key: '',
                  name: 'withdraw',
                  hoverColor: 'withdrawHover',
                  buttonText: 'Withdraw',
                  coinName: selectWithdrawData?.name,
                  price: selectWithdrawData?.price,
                  wallet_value: withdraw_wallet_value,
                  underlying_amount: underlying_amount || 0
                }} />
            </section>

          </section>
        </section>
      </section>

      {/*<TwiceConfirmModal visible={isModalVisible} onCancel={() => setIsModalVisible(false)} onOk={onClickDeposit} />*/}
    </div>
  );
}
