import { Antd } from 'components';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import commonAction from 'modules/common/actions';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { getLayoutHeight } from 'modules/common/reducer';
import { useSelector } from 'react-redux';
import useCurrentPage from 'hooks/useCurrentPage';
import classNames from 'classnames';
// @ts-ignore
import styles from './index.scss';
import { useLocation } from 'react-router-dom';

const { Content } = Antd.Layout;
// const noHeaderList = ['OfficeWebsite']

const noHeaderList = ['/'];
const PageLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  // const history = useHistory();
  const contentHeight = useSelector(getLayoutHeight);

  useEffect(() => {
    // if (containerRef?.current && containerRef?.current?.clientHeight && !contentHeight)
    //   dispatch(commonAction.SET_LAYOUT_HEIGHT(containerRef?.current?.clientHeight));
    dispatch(commonAction.SET_GLOBAL_STATE());
  }, [containerRef, contentHeight, dispatch]);

  const isMain = noHeaderList.some((item) => item === pathname);

  // const [currentPageName] = useCurrentPage();
  // console.log(`history currentPageName: `, currentPageName);
  // const isHidden = noHeaderList.some((item) => item === currentPageName);

  return (
    <div className='' id={isMain ? '' : 'lending-main'}>
      <Header />
      <Content className={classNames(styles.bg)}>
        {children}
      </Content>
      <Footer />
    </div>
  );
};

export default PageLayout;
