import { createReducer } from '@reduxjs/toolkit';
import { RootState } from 'modules/rootReducer';
import actions from './actions';
// import { state, config, ENV, actions as sdkActions } from 'utils/sdk.js';

interface CommonState {
  layoutHeight: number;
  showWalletConnector: boolean;
  isResourcesNotFound: boolean;
  globalState: Object;
  indexPageTableData: Array<Object>;
}

const initState: CommonState = {
  layoutHeight: 0,
  showWalletConnector: false,
  isResourcesNotFound: false,
  globalState: {},
  indexPageTableData: [],
};

// // console.log(`state, config, ENV, action as sdkActions: `, state, config, ENV, sdkActions);
// // @ts-ignore
// window.state = state;
// // @ts-ignore
// window.config = config;
// // @ts-ignore
// window.ENV = ENV;
// // @ts-ignore
// window.actions = sdkActions;

// // debugger;
// // @ts-ignore
// window.isInitUser= false;
// const initSDK = async (env = '', actionState: any) => {
//   // @ts-ignore
//   if (!window.isInitSDK) {
//     config.use(env || ENV.TEST);
//     await state.reconnect(config.NODE_URL);
//     await state.init_bank(config.BANK);
//     // setIsInitSDK(true);
//     // @ts-ignore
//     window.isInitSDK = true;
//     debugger;
//   }
//   // console.log(`state: `, state, config, ENV, actions);
//
//   try {
//     // @ts-ignore
//     let current_user = await window.aptos.account();
//     // @ts-ignore
//     state.wallet = window.aptos;
//
//     if (current_user && current_user.address) {
//       await state.init_user(current_user.address);
//     }
//     // console.log(`state: `, state);
//     // // debugger;
//     // @ts-ignore
//     window.globalState = state;
//     actionState.globalState = state;
//   } catch (e) {
//     // console.log(`e: `, e);
//   }
//   // console.log(`state: `, state);
//
//   let tableData = [];
//   for (const [coin_type, pool] of Object.entries(state?.lending_pools)) {
//     let o = {};
//     let vault = state?.vaults[coin_type];
//     o = {
//       coin_type,
//       // @ts-ignore
//       name: pool?.coin_info?.info?.name,
//       deposited_value: vault?.deposited_value?.format(9),
//       borrowed_value: vault?.borrowed_value?.format(9),
//       current_borrow_apr: vault?.current_borrow_apr()?.toString(),
//       current_supply_apr: vault?.current_supply_apr()?.toString(),
//       current_utilization: vault?.current_utilization()?.toString(),
//       wallet: '-'
//     }
//     tableData.push(o);
//   }
//
//   tableData.map(item => {
//     for (const [coin_type, coin_store] of Object.entries(state?.user_coins)) {
//       // @ts-ignore
//       if (item.name === coin_store.coin_info.info.name) {
//         // @ts-ignore
//         item.wallet = coin_store.formatted_value;
//       }
//     }
//   })
//   actionState.globalState = state;
//   actionState.indexPageTableData = tableData;
//   // setTableData(tableData);
//   // setGlobalState(state);
//
//   let rulesInfoData = [];
//   if (Object.entries(state?.vaults || {}).length) {
//     for (const [coin_type, vault] of Object.entries(state?.vaults || {})) {
//       // // console.log(`vault.borrowed_value.format(9): `, vault.borrowed_value.format(9));
//       // // console.log(`vault.current_borrow_apr.toString()  `, vault.current_borrow_apr().toString());
//       // // console.log(`state?.coin_prices: `, state?.coin_prices);
//       // // console.log(`coin_type: `, coin_type);
//       // // console.log(`state.coin_prices[coin_type].latest_price: `, state?.coin_prices[coin_type]?.latest_price);
//       // debugger;
//       rulesInfoData.push({
//         price: 21,
//         coin_type,
//         // @ts-ignore
//         name: vault?.coin_info?.info?.name,
//         // @ts-ignore
//         borrowed_value: vault?.borrowed_value?.format(9),
//         // @ts-ignore
//         deposited_value: vault?.deposited_value?.format(9),
//         // @ts-ignore
//         current_borrow_apr: vault?.current_borrow_apr()?.toString(),
//         // @ts-ignore
//         current_supply_apr: vault?.current_supply_apr()?.toString(),
//         // @ts-ignore
//         ltv: vault?.config?.asset_factor?.format(9),
//         // @ts-ignore
//         current_utilization: vault?.current_utilization()?.toString(),
//         // @ts-ignore
//         available_liquidity: vault?.coins?.format(9),
//         // @ts-ignore
//         pledge_limit: vault?.config?.pledge_limit?.format(9),
//         // @ts-ignore
//         borrow_limit: vault?.config?.borrow_limit?.format(9),
//         liquidation_Threshold: config?.LIQUIDATION_THRESHOLD?.toString(),
//         liquidation_penalty: config?.LIQUIDATION_FACTOR?.toString(),
//       })
//     }
//   }
//   // console.log(`res: `, rulesInfoData);
//   // // debugger;
//   // setRulesInfoData(rulesInfoData);
//
//   let userCoinInfo = [];
//   // Read user data
//   for (const [coin_type, coin_store] of Object.entries(state.user_coins)) {
//     userCoinInfo.push({
//       coin_type,
//       name: coin_type,
//       // @ts-ignore
//       value: coin_store?.formatted_value
//     })
//   }
//
//   let userDebtInfo = [];
//   // dashboard 债务和抵押物
//   for (const debt in state?.user_balance?.debts) {
//     // console.log(debt)
//     userDebtInfo.push({
//       debt
//     })
//   }
//
//   let userDepositInfo = [];
//   for (const asset in state?.user_balance?.assets) {
//     // console.log(asset)
//     userDepositInfo.push({
//       asset
//     })
//   }
//
//   // setUserInfo({
//   //   userCoinInfo,
//   //   userDebtInfo,
//   //   userDepositInfo,
//   // })
//
// }

export default createReducer(initState, (builder) => {
  builder
    .addCase(actions.SET_LAYOUT_HEIGHT, (state, { payload }) => {
      state.layoutHeight = payload;
    })
    .addCase(actions.TOGGLE_WALLET_CONNECTOR, (state, { payload }) => {
      state.showWalletConnector = payload === undefined ? !state.showWalletConnector : payload;
    })
    .addCase(actions.SET_RESOURCES_NOT_FOUND, (state, { payload }) => {
      state.isResourcesNotFound = payload;
    })
    .addCase(actions.SET_GLOBAL_STATE, (state, { payload }) => {
      // state.globalState = payload || '';
      // initSDK('', state);
    });
});

export const getLayoutHeight = (state: RootState) => state.common.layoutHeight;
export const getShowWalletConnector = (state: RootState) => state.common.showWalletConnector;
export const getIsResourcesNotFound = (state: RootState) => state.common.isResourcesNotFound;

export const getGlobalState = (state: RootState) => state.common.globalState;
export const getIndexPageTableData = (state: RootState) => state.common.indexPageTableData;
