
import React, { useContext, useMemo, useState, Fragment } from 'react';
// import sdk from '../sdk';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import logoWhite from 'assets/img/logo_white.png';
import iconMoney from 'assets/img/icon/icon-money.svg';
import iconSwitch from 'assets/img/icon/icon-switch.png';
import dashboard from 'assets/img/title/dashboard.png';
import lowRisk from 'assets/img/gif/low-risk.gif';
// import middleRisk from 'assets/img/gif/middle-risk.gif';
// import highRisk from 'assets/img/gif/high-risk.gif';
import { SDKContext } from 'contexts/SDKProvider';
import Msg from 'components/Modal/Msg';
import className from 'classnames';
import { Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { iconsCfg, decimalsCfg } from 'config/decimals';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import dashboardSlogan from 'assets/img/h5/slogan-text/dashboard.png';
import { DownOutlined } from '@ant-design/icons';


export default function DashboardH5Page() {
  // const { globalState } = useModel('bank');
  const { t } = useTranslation();
  // @ts-ignore
  const { globalState, dashboardPageData, SetDashboardPageData, appLoading } = useContext(SDKContext);
  const [currentTab, setCurrentTab] = useState<string>('Assets');
  const [currentUnit, setCurrentUnit] = useState<string>('usd');
  const [isShowRiskInfo, setIsShowRiskInfo] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);
  const { connected, account, network, connect, ...rest } = useWallet();
  const navigate = useNavigate();

  const { userDebtInfo, userDepositInfo } = dashboardPageData;

  const onClickShowMore = (tab, index) => {
    if (tab === 0) {
      let newUserDebtData = dashboardPageData?.userDebtInfo.map((item, i) => {
        if (index === i) {
          item.isShowMore = !item.isShowMore;
        } else {
          // item.isShowMore = false;
        }
        return item;
      });
      SetDashboardPageData({
        ...dashboardPageData,
        userDebtInfo: newUserDebtData,
      });
    } else {
      let newUserAssetData = dashboardPageData?.userDepositInfo.map((item, i) => {
        if (index === i) {
          item.isShowMore = !item.isShowMore;
        } else {
          // item.isShowMore = false;
        }
        return item;
      });
      SetDashboardPageData({
        ...dashboardPageData,
        userDepositInfo: newUserAssetData
      });
    }
  }

  const onClickTab = (tab) => {
    if (tab === 0) {
      setCurrentUnit('usd');
      let newUserDebtData = dashboardPageData?.userDebtInfo.map(item => {
        item.isShowUSD = true;
        return item;
      });
      let newUserAssetData = dashboardPageData?.userDepositInfo.map(item => {
        item.isShowUSD = true;
        return item;
      });
      SetDashboardPageData({
        ...dashboardPageData,
        userDebtInfo: newUserDebtData,
        userDepositInfo: newUserAssetData
      });
    } else {
      setCurrentUnit('amount');
      let newUserDebtData = dashboardPageData?.userDebtInfo.map(item => {
        item.isShowUSD = false;
        return item;
      });
      let newUserAssetData = dashboardPageData?.userDepositInfo.map(item => {
        item.isShowUSD = false;
        return item;
      });
      SetDashboardPageData({
        ...dashboardPageData,
        userDebtInfo: newUserDebtData,
        userDepositInfo: newUserAssetData
      });
    }
  }

  let riskIndex = globalState?.user_balance?.risk_index.format(9);
  let debt = (globalState?.user_balance?.total_vdebts_value?.format(9) / globalState?.user_balance?.max_vdebts_value?.format(9))?.toFixed(2);
  let debtLimit = (globalState?.user_balance?.total_vassets_value?.format(9) / globalState?.user_balance?.max_vdebts_value?.format(9))?.toFixed(2);
  let limitBuffer = (1 - globalState?.user_balance?.total_vdebts_value?.format(9) / globalState?.user_balance?.max_vdebts_value?.format(9))?.toFixed(2);

  return (
    <section className="flex lg:hidden">
      <section className='px-[3%] w-full'>
        <section className='min-h-[92vh] '>
          <div className='max-w-[360px] h-[46px] mt-[14px] px-[3%]'>
            <img src={dashboardSlogan} alt="" />
          </div>

          {/* total deposit */}
          <div className="my-[16px] border border-[#454545] p-2 rounded-[12px] relative text-[12px] bg-[#292d2ef7]">
            <div className="m-[3%]">
              <div className=" flex justify-between border-b border-[#B7B7B7]/20 pb-[10px] mb-[10px]">
                <div className="text-[#B4AFAE]">
                  {
                    t('totalDeposit')
                  }
                </div>
                <div className="text-[#E4E4E4]">
                  $ {globalState?.user_balance?.total_assets_value ?
                    Number(globalState?.user_balance?.total_assets_value.format(9))?.toFixed(2) : '-'}
                </div>
              </div>

              <div className="text-[#fff]/10 flex justify-between ">
                <div className="">
                  Accumulated income
                </div>
                <div className="">
                  Feature coming soon
                </div>
              </div>
            </div>

          </div>

          {/* total debt */}
          <div className="mb-[16px] border border-[#454545] p-2 rounded-[12px] relative text-[12px] bg-[#292d2ef7]">
            <div className="m-[3%]">
              <div className="text-[#B4AFAE] flex justify-between border-b border-[#B7B7B7]/20 pb-[10px] mb-[10px]">
                <div className="text-[#B4AFAE]">
                  {
                    t('Total debt')
                  }
                </div>
                <div className="text-[#E4E4E4]">
                  $ {globalState?.user_balance?.total_debts_value ?
                    Number(globalState?.user_balance?.total_debts_value.format(9))?.toFixed(2) : '-'}
                </div>
              </div>

              <div className="text-[#fff]/10 flex justify-between ">
                <div className="">
                  Accumulated income
                </div>
                <div className="">
                  Feature coming soon
                </div>
              </div>
            </div>

          </div>

          {/* rish index  */}
          <section className="h-[140px] flex flex-col w-full  border border-[#2B302E] rounded-xl bg-[#292d2ef7] items-center relative">
            <div className="flex items-center w-full px-[3%]">
              <div className='w-[40%] flex flex-col items-center justify-center '>

                <div className="w-[50px]  flex items-center justify-center " >
                  <img src={lowRisk} alt='' />
                </div>


                {
                  riskIndex < 0.5 ? (
                    <div className="bg-[#8EBE68] absolute left-0 bottom-[0] h-[4px] w-[88%] left-[50%] ml-[-46%] blur-md z-0" />
                  ) : (riskIndex >= 0.5 && riskIndex <= 0.7) ? (
                    <div className="bg-[#E2AE60] absolute left-0 bottom-[0] h-[4px] w-[88%] left-[50%] ml-[-46%] blur-md z-0" />
                  ) : (riskIndex > 0.7 && riskIndex <= 1) ? (
                    <div className="bg-[#E27860] absolute left-0 bottom-[0] h-[4px] w-[88%] left-[50%] ml-[-46%] blur-md z-0" />
                  ) : riskIndex > 1 ? (
                    <div className="bg-[#E27860] absolute left-0 bottom-[0] h-[4px] w-[88%] left-[50%] ml-[-46%] blur-md z-0" />
                  ) : (
                    <div className="bg-[#8EBE68] absolute left-0 bottom-[0] h-[4px] w-[88%] left-[50%] ml-[-46%] blur-md z-0" />
                  )
                }

                <div className="absolute right-2 top-2 cursor-pointer hidden" onClick={() => {
                  setIsShowRiskInfo(true);
                }}>
                  <QuestionCircleOutlined style={{
                    color: '#B4AFAE'
                  }} />
                </div>

                <div className="text-sm text-[#BBBBBB] mt-1 ">
                  {
                    t('Risk Index')
                  }
                </div>

                <div className="hidden text-[#D9FDAB] text-[#E2AE60] text-[#E27860] text-[#D9FDAB]" />
                {
                  riskIndex < 0.5 ? (
                    <div className="text-[#D9FDAB] text-sm">
                      {/*Low Risk*/}
                      {
                        t('Low Risk')
                      }
                    </div>
                  ) : (riskIndex >= 0.5 && riskIndex <= 0.7) ? (
                    <div className="text-[#E2AE60] text-sm">
                      {/*Medium Risk*/}
                      {
                        t('Medium Risk')
                      }
                    </div>
                  ) : (riskIndex > 0.7 && riskIndex <= 1) ? (
                    <div className="text-[#E27860] text-sm">
                      {/*High Risk*/}
                      {
                        t('High Risk')
                      }
                    </div>
                  ) : riskIndex > 1 ? (
                    <div className="text-[#D9FDAB] text-sm">
                      {/*Under liquidation*/}
                      {
                        t('Under liquidation')
                      }
                    </div>
                  ) : (
                    <div className="text-[#D9FDAB] text-sm">
                      {/*Low Risk*/}
                      {
                        t('Low Risk')
                      }
                    </div>
                  )
                }

              </div>

              <div className="mt-[10px] w-[60%] text-sm">
                <div className="flex justify-between py-[10px] border-b border-b-[#393C3A]">
                  <div className='text-[#B4AFAE]'>
                    {/*Debt*/}
                    {
                      t('Debt')
                    }
                  </div>
                  <div className='text-[#E4E4E4]'>{
                    isNaN(Number(debt)) ? '-' : debt
                  }</div>
                </div>

                <div className="flex justify-between py-[10px] border-b border-b-[#393C3A]">
                  <div className='text-[#B4AFAE]'>
                    {/*Debt limit*/}
                    {
                      t('Debt limit')
                    }
                  </div>
                  <div className='text-[#E4E4E4]'>{
                    isNaN(Number(debtLimit)) ? '-' : debtLimit
                  }</div>
                </div>

                <div className="flex justify-between py-[10px] border-b border-b-[#393C3A]">
                  <div className='text-[#B4AFAE]'>
                    {/*Liquidate buffer*/}
                    {
                      t('Liquidate buffer')
                    }
                  </div>
                  <div className='text-[#E4E4E4]'>{
                    isNaN(Number(limitBuffer)) ? '-' : limitBuffer
                  }
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className="my-[16px] flex flex-col justify-between w-full">

            <section className="flex h-[40px] border border-[#454545] rounded-xl cursor-pointer">

              <div onClick={() => {
                // onClickTab(1);
                setCurrentTab('Assets');
              }} className={className('flex items-center justify-center w-1/2 rounded-r-xl border-l border-[#454545] text-sm', currentTab === 'Assets' ? 'bg-[#1C1F20]' : '')}>
                <div className="w-[20px] mr-1">
                  <img src={iconSwitch} alt='' className="w-full" />
                </div>
                {/*Collateral details*/}
                {
                  t('Collateral details')
                }
              </div>

              <div onClick={() => {
                // onClickTab(0);
                setCurrentTab('Debt');
              }} className={className('flex items-center justify-center w-1/2  rounded-l-xl text-sm', currentTab === 'Debt' ? 'bg-[#1C1F20]' : '')}>
                <div className="w-[20px] mr-1">
                  <img src={iconMoney} alt='' className="w-full" />
                </div>
                {
                  t('Debt Details')
                }
              </div>


            </section>

            <div className="flex mt-[16px] w-[156px] h-[30px] bg-[#404647] rounded-full select-none text-[12px]">
              <div className="hidden text-[#31767A]" />
              <div onClick={() => {
                onClickTab(0);
                // setCurrentUnit('usd');
              }} className={className('flex justify-center items-center w-[78px] py-[8px] rounded-full cursor-pointer text-[#B4AFAE] ', currentUnit === 'usd' ? ' !text-[#31767A] bg-[#fff]' : ' hover:text-[#fff]')}>
                <span>
                  USD
                </span>
              </div>
              <div onClick={() => {
                onClickTab(1);
                // setCurrentUnit('amount');
              }} className={className('flex justify-center items-center w-[78px] py-[8px] rounded-full cursor-pointer text-[#B4AFAE] ', currentUnit === 'amount' ? ' !text-[#31767A] bg-[#fff] ' : ' hover:text-[#fff]')}>
                <span>
                  {/*Amount*/}
                  {
                    t('Amount')
                  }
                </span>
              </div>
            </div>

          </section>

          <section className={className(currentTab === 'Debt' ? ` mb-[100px] ` : 'hidden')}>

            <section className="">

              <div className="">
                <table
                  className=" w-full border-separate"
                  style={{
                    borderSpacing: '0',
                  }}
                >
                  <thead className={`h-[53px] text-sm`}>
                    <tr className={`text-left bg-[#25282A] text-center text-sm`}>
                      <th
                        className="px-4 py-2 text-[#E4E4E4]  font-light rounded-tl-xl"
                        style={{}}
                      >
                        {
                          t('Asset')
                        }
                      </th>
                      <th className="px-4 py-2 text-[#E4E4E4]  font-light">
                        {
                          t('Debt value')
                        }
                      </th>
                      {/*<th className="px-4 py-2 text-[#E4E4E4]  font-light">Accumulated profit</th>*/}
                      <th className="rounded-tr-xl px-4 py-2 text-[#E4E4E4]  font-light">
                        {
                          t('Borrow APY')
                        }
                      </th>

                    </tr>
                  </thead>
                  <tbody className=" bg-[#1D2021]" style={{}}>

                    {
                      userDebtInfo && userDebtInfo?.map((item, i) => {
                        return (
                          <Fragment key={item.name}>
                            <tr onClick={() => {
                            }} className={'group h-[53px] text-center '}>
                              <td className={className(' font-black text-[16px]  font-medium   px-4 py-3', '',
                                // i === (userDepositInfo?.length - 1) ? 'rounded-bl-xl' : ''
                              )} style={{
                                borderBottom: i !== (userDebtInfo?.length - 1) ? '2px solid #2F3132' : '0'
                              }}>
                                <div className='flex items-center text-sm'>
                                  <img className="px-1" src={iconsCfg?.[item.name]} alt='' />
                                  {item.name}
                                </div>
                              </td>

                              <td className={className('font-black text-sm  font-medium px-4 py-3', '')}
                                style={{
                                  borderBottom: i !== (userDebtInfo?.length - 1) ? '2px solid #2F3132' : '0'
                                }}>
                                {item.isShowUSD ? `$${Number(item?.value)?.toFixed(2)}` : Number(item?.underlying_amount)?.toFixed(decimalsCfg[item?.name])}
                              </td>

                              <td className={className('  font-black text-sm  font-medium  px-4 py-3', '')} style={{
                                borderBottom: i !== (userDebtInfo?.length - 1) ? '2px solid #2F3132' : '0'
                              }}>
                                <p className="flex items-end">
                                  <span className="text-[#4AFCDC] w-[54px]">
                                    {item?.current_supply_apr}
                                  </span>
                                  &nbsp;

                                  <DownOutlined className={
                                    `${item.isShowMore ? 'rotate-180' : ''}`
                                  } onClick={() => {
                                    onClickShowMore(0, i);
                                  }} />

                                </p>

                              </td>

                            </tr>
                            <tr className={
                              ` bg-red ${item.isShowMore ? ' ' : ' hidden'}`
                            }>
                              <td className='text-sm' colSpan={3}>
                                <div className=' px-3 flex flex-col '>
                                  <div className="px-3 py-3 bg-[#282C2DB2] rounded-xl ">
                                    <div className="flex justify-between">
                                      <div>
                                        {
                                          t('Debt value')
                                        }
                                      </div>
                                      <div className="">
                                        {item?.isShowUSD ?
                                          `$${Number(item?.value)?.toFixed(2)}`
                                          : Number(item?.underlying_amount)?.toFixed(decimalsCfg[item?.name] || 0)}

                                      </div>
                                    </div>

                                    <div className="flex justify-between mt-2">
                                      <div>
                                        {
                                          t('Borrow APY')
                                        }
                                      </div>
                                      <div className="">
                                        {item?.current_borrow_apr}
                                      </div>
                                    </div>

                                    <div className="flex justify-between mt-2">
                                      <div>
                                        {
                                          t('Wallet balance')
                                        }
                                      </div>
                                      <div className="">
                                        {item?.wallet_value}
                                      </div>
                                    </div>

                                  </div>

                                  <div className="flex py-3">
                                    <div className="w-1/2 bg-[#8ECE5B99] rounded-full justify-center flex px-3 py-3 mr-3" onClick={() => {
                                      navigate(`/borrow#${item?.name}`);
                                    }}>
                                      {
                                        t('Borrow')
                                      }
                                    </div>

                                    <div className="w-1/2 bg-[#E2786099] rounded-full justify-center flex px-3 py-3  " onClick={() => {
                                      navigate(`/repay#${item?.name}`);
                                    }}>
                                      {
                                        t('Repay')
                                      }
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })
                    }

                  </tbody>
                </table>
              </div>

              {
                (!userDepositInfo?.length && !appLoading) && (
                  <div className="h-[200px] bg-[#282C2D]/70 flex flex-col justify-center items-center rounded-b-3xl">
                    <div className="w-[144px] h-[100px] justify-center flex">
                      <img src={require('assets/img/dashboard/empty-collateral.svg').default} alt='' />
                    </div>
                    <div className="text-[#B4AFAE] my-3">
                      {
                        t('You do not have debt yet.')
                      }
                    </div>
                  </div>
                )
              }

              {
                <Skeleton active round loading={appLoading} className="my-6" />
              }

            </section>

          </section>

          <section className={className(currentTab === 'Assets' ? `mb-[100px]` : 'hidden')}>

            <div className="">
              <table
                className=" w-full border-separate"
                style={{
                  borderSpacing: '0',
                }}
              >
                <thead className={`h-[53px] text-sm`}>
                  <tr className={`text-left bg-[#25282A] text-center text-sm`}>
                    <th
                      className="px-4 py-2 text-[#E4E4E4]  font-light rounded-tl-xl"
                      style={{}}
                    >
                      {
                        t('Asset')
                      }
                    </th>
                    <th className="px-4 py-2 text-[#E4E4E4]  font-light">
                      {
                        t('Asset value')
                      }
                    </th>
                    {/*<th className="px-4 py-2 text-[#E4E4E4]  font-light">Accumulated profit</th>*/}
                    <th className="rounded-tr-xl px-4 py-2 text-[#E4E4E4]  font-light">
                      {
                        t('Deposit APY')
                      }
                    </th>

                  </tr>
                </thead>
                <tbody className=" bg-[#1D2021]" style={{}}>

                  {
                    userDepositInfo && userDepositInfo?.map((item, i) => {
                      return (
                        <Fragment key={item.name}>
                          <tr onClick={() => {
                          }} className={'group h-[53px] text-center '}>
                            <td className={className(' font-black text-[16px]  font-medium   px-4 py-3', '',
                              // i === (userDepositInfo?.length - 1) ? 'rounded-bl-xl' : ''
                            )} style={{
                              borderBottom: i !== (userDepositInfo?.length - 1) ? '2px solid #2F3132' : '0'
                            }}>
                              <div className='flex items-center text-sm'>
                                <img className="px-1" src={iconsCfg?.[item.name]} alt='' />
                                {item.name}
                              </div>
                            </td>

                            <td className={className('font-black text-sm  font-medium px-4 py-3', '')}
                              style={{
                                borderBottom: i !== (userDepositInfo?.length - 1) ? '2px solid #2F3132' : '0'
                              }}>
                              {item.isShowUSD ? `$${Number(item?.value)?.toFixed(2)}` : Number(item?.underlying_amount)?.toFixed(decimalsCfg[item?.name])}
                            </td>

                            <td className={className('  font-black text-sm  font-medium  px-4 py-3', '')} style={{
                              borderBottom: i !== (userDepositInfo?.length - 1) ? '2px solid #2F3132' : '0'
                            }}>
                              <p className="flex items-end">
                                <span className="text-[#4AFCDC] w-[54px]">
                                  {item?.current_supply_apr}
                                </span>
                                &nbsp;

                                <DownOutlined className={
                                  `${item.isShowMore ? 'rotate-180' : ''}`
                                } onClick={() => {
                                  onClickShowMore(1, i);
                                }} />

                              </p>

                            </td>

                          </tr>
                          <tr className={
                            ` bg-red ${item.isShowMore ? ' ' : ' hidden'}`
                          }>
                            <td className='text-sm' colSpan={3}>
                              <div className=' px-3 flex flex-col '>
                                <div className="px-3 py-3 bg-[#282C2DB2] rounded-xl ">
                                  <div className="flex justify-between">
                                    <div>
                                      {
                                        t('Debt value')
                                      }
                                    </div>
                                    <div className="">
                                      {item?.isShowUSD ?
                                        `$${Number(item?.value)?.toFixed(2)}`
                                        : Number(item?.underlying_amount)?.toFixed(decimalsCfg[item?.name] || 0)}

                                    </div>
                                  </div>

                                  <div className="flex justify-between mt-2">
                                    <div>
                                      {
                                        t('Deposit APY')
                                      }
                                    </div>
                                    <div className="">
                                      {item?.current_supply_apr}
                                    </div>
                                  </div>

                                  <div className="flex justify-between mt-2">
                                    <div>
                                      {
                                        t('Wallet balance')
                                      }
                                    </div>
                                    <div className="">
                                      {item?.wallet_value}
                                    </div>
                                  </div>

                                </div>

                                <div className="flex py-3">
                                  <div className="w-1/2 bg-[#8ECE5B99] rounded-full justify-center flex px-3 py-3 mr-3" onClick={() => {
                                    navigate(`/deposit#${item?.name}`);
                                  }}>
                                    {
                                      t('Deposit')
                                    }
                                  </div>

                                  <div className="w-1/2 bg-[#E2786099] rounded-full justify-center flex px-3 py-3  " onClick={() => {
                                    navigate(`/withdraw#${item?.name}`);
                                  }}>
                                    {
                                      t('Withdraw')
                                    }
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })
                  }

                </tbody>
              </table>
            </div>

            {
              (!userDepositInfo?.length && !appLoading) && (
                <div className="h-[200px] bg-[#282C2D]/70 flex flex-col justify-center items-center rounded-b-3xl">
                  <div className="w-[144px] h-[100px] justify-center flex">
                    <img src={require('assets/img/dashboard/empty-collateral.svg').default} alt='' />
                  </div>
                  <div className="text-[#B4AFAE] my-3">
                    {
                      t('You do not have collateral yet.')
                    }
                  </div>
                </div>
              )
            }

            {
              <Skeleton active round loading={appLoading} className="my-6" />
            }
          </section>


          <Msg visible={isShowRiskInfo} onCancel={() => setIsShowRiskInfo(false)} />

        </section>
      </section>
    </section>
  );
}

