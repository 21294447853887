import { createContext, FC, ReactNode, useCallback, useEffect, useState } from 'react';
// import SDK from 'utils/sdk.js';
// @ts-ignore
// import SDK from 'utils/sdk.umd.js';
// import { Spin } from 'antd';
// const SDK = require('utils/sdk.js').default;
// import { coinListClient, hippoTradeAggregator, hippoWalletClient } from 'config/hippoClients';
// import { HippoWalletClient, stdlib, CoinListClient } from '@manahippo/hippo-sdk';
// import { TradeAggregator } from '@manahippo/hippo-sdk/dist/aggregator/aggregator';
// import useAptosWallet from 'hooks/useAptosWallet';
// import { TTransaction } from 'types/hippo';
// import { useWallet } from '@manahippo/aptos-wallet-adapter';
// import { useDispatch } from 'react-redux';
// import swapAction from 'modules/swap/actions';
// import { RouteAndQuote } from '@manahippo/hippo-sdk/dist/aggregator/types';
// import { CoinInfo } from '@manahippo/hippo-sdk/dist/generated/coin_list/coin_list';
// import { Types } from 'aptos';
// import { openErrorNotification, openTxSuccessNotification } from 'utils/notifications';
// import Loading from 'components/LoadingComp/Loading';
import ModalLogin from 'components/Modal/LoginModal';
import { decimalsCfg } from 'config/decimals';
// import { useLocation } from 'react-router-dom';
// // // debugger;
// const { actions , config, ENV, state } = SDK;
// @ts-ignore
// const SDK = require('utils/sdk.v2.js');
import { config, ENV, Actions, AppState, DataLoader } from 'utils/sdk.v2.js';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
// // console.log(`SDK: `, SDK);
// const { config, ENV, Actions, AppState, DataLoader } = SDK

// // console.log(`state, config, ENV, action as sdkActions: `, state, config, ENV, actions);


// // @ts-ignore
// window.actions = actions;
let state = new AppState();

// @ts-ignore
window.state = state;
// @ts-ignore
window.config = config;
// @ts-ignore
window.ENV = ENV;
// @ts-ignore
window.DataLoader = DataLoader;

interface SDKContextType {
  // hippoWallet?: HippoWalletClient;
  // hippoAgg?: TradeAggregator;
  // tokenStores?: Record<string, stdlib.Coin.CoinStore>;
  // tokenInfos?: Record<string, CoinInfo>;
  // getTokenInfoByFullName: (fullName: string) => CoinInfo | undefined;
  // requestSwapByRoute: (
  //   routeAndQuote: RouteAndQuote,
  //   slipTolerance: number,
  //   options?: Partial<Types.SubmitTransactionRequest>
  // ) => Promise<boolean>;
  // requestSwap?: (
  //   fromSymbol: string,
  //   toSymbol: string,
  //   uiAmtIn: number,
  //   uiAmtOutMin: number,
  //   callback: () => void
  // ) => {};
  // transaction?: TTransaction;
  // setTransaction: (trans?: TTransaction) => void;
  // requestFaucet: (symbol: string) => Promise<boolean>;
}

interface TProviderProps {
  children: ReactNode;
}

const SDKContext = createContext<SDKContextType>({} as SDKContextType);

// @ts-ignore
window.isInitUser= false;

// const decimalsCfg = {
//   'USDC': 4,
//   'USDT': 4,
//   'Aptos Coin': 4,
//   'ETH': 4,
//   'BTC': 6,
// }

let loader;
// // @ts-ignore
// window.loader = loader;
const SDKProvider: FC<TProviderProps> = ({ children }) => {
  const { connected, account } = useWallet();
  const [appLoading, setAppLoading] = useState<boolean>(true);
  const [isTransfer, setIsTransfer] = useState<boolean>(false);
  const [isLoginModal, setIsLoginModal] = useState<boolean>(false);
  const [globalState, setGlobalState] = useState<Object>({});

  const [indexPageTableData, setIndexPageTableData] = useState<Array<Object>>([]);
  const [rulesPageData, setRulesPageData] = useState<Array<Object>>([]);
  const [dashboardPageData, SetDashboardPageData] = useState<Object>({});

  const [depositSelectListDataHasCoin, setDepositSelectListDataHasCoin] = useState<boolean>(true);

  const [depositSelectListData, setDepositSelectListData] = useState<Array<Object>>([]);
  const [withdrawSelectListData, setWithdrawSelectListData] = useState<Array<Object>>([]);
  const [repaySelectListData, setRepaySelectListData] = useState<Array<Object>>([]);
  const [borrowSelectListData, setBorrowSelectListData] = useState<Array<Object>>([]);

  const [routeDepositParam, setRouteDepositParam] = useState<string>('');
  const [routeWithdrawParam, setRouteWithdrawParam] = useState<string>('');
  const [routeBorrowParam, setRouteBorrowParam] = useState<string>('');
  const [routeRepayParam, setRouteRepayParam] = useState<string>('');

  // const [transaction, setTransaction] = useState<TTransaction>();
  // const [tokenStores, setTokenStores] = useState<Record<string, stdlib.Coin.CoinStore>>();
  // const [tokenInfos, setTokenInfos] = useState<Record<string, CoinInfo>>();
  // // const dispatch = useDispatch();
  // const getHippoWalletClient = useCallback(async () => {
  //   if (activeWallet) {
  //     const client = await hippoWalletClient(activeWallet);
  //     await client?.refreshStores();
  //     setHippoWallet(client);
  //   } else {
  //     setHippoWallet(undefined);
  //   }
  // }, [activeWallet]);
  //
  // const getHippoTradeAggregator = useCallback(async () => {
  //   setHippoAgg(await hippoTradeAggregator());
  // }, []);
  //
  // const getCoinListClient = useCallback(async () => {
  //   setCoinListCli(await coinListClient());
  // }, []);
  //
  // const getTokenInfoByFullName = useCallback(
  //   (fullName: string) => {
  //     return coinListCli?.fullnameToCoinInfo[fullName];
  //   },
  //   [coinListCli?.fullnameToCoinInfo]
  // );
  // useEffect(() => {
  //   getHippoWalletClient();
  // }, [getHippoWalletClient]);
  // useEffect(() => {
  //   getHippoTradeAggregator();
  // }, [getHippoTradeAggregator]);
  // useEffect(() => {
  //   getCoinListClient();
  // }, [getCoinListClient]);
  //
  // useEffect(() => {
  //   if (refreshWalletClient) {
  //     getHippoWalletClient();
  //     setRefreshWalletClient(false);
  //   }
  // }, [getHippoWalletClient, refreshWalletClient]);
  // useEffect(() => {
  //   if (hippoWallet) {
  //     setTokenStores(hippoWallet?.symbolToCoinStore);
  //   } else {
  //     setTokenStores(undefined);
  //   }
  // }, [hippoWallet?.symbolToCoinStore, hippoWallet]);
  //
  // useEffect(() => {
  //   setTokenInfos(coinListCli?.symbolToCoinInfo);
  //   dispatch(swapAction.SET_TOKEN_LIST(coinListCli?.getCoinInfoList()));
  // }, [coinListCli, dispatch]);
  // const requestFaucet = useCallback(
  //   async (symbol: string) => {
  //     let success = false;
  //     try {
  //       if (!activeWallet) throw new Error('Please login first');
  //       const uiAmtUsed = symbol === 'devBTC' ? 0.01 : 10;
  //       const payload = hippoWallet?.makeFaucetMintToPayload(uiAmtUsed, symbol, true);
  //       if (payload && tokenInfos) {
  //         let pl = payload as Types.TransactionPayload_EntryFunctionPayload;
  //         const result = await signAndSubmitTransaction(pl, {
  //           expiration_timestamp_secs: Math.floor(Date.now() / 1000) + 3 * 60
  //         });
  //         if (result) {
  //           openTxSuccessNotification(result.hash, `Requested ${uiAmtUsed} ${symbol} successfully`);
  //           await hippoWallet?.refreshStores();
  //           setRefreshWalletClient(true);
  //           success = true;
  //         }
  //       }
  //     } catch (error) {
  //       console.error('Request faucet error:', error);
  //       if (error instanceof Error) {
  //         openErrorNotification({ detail: error?.message });
  //       }
  //       success = false;
  //     } finally {
  //       return success;
  //     }
  //   },
  //   [activeWallet, hippoWallet, signAndSubmitTransaction, tokenInfos]
  // );
  // const requestSwapByRoute = useCallback(
  //   async (routeAndQuote: RouteAndQuote, slipTolerance: number, options = {}) => {
  //     let success = false;
  //     try {
  //       const input = routeAndQuote.quote.inputUiAmt;
  //       const minOut = routeAndQuote.quote.outputUiAmt * (1 - slipTolerance / 100);
  //       if (!activeWallet) throw new Error('Please connect wallet first');
  //       if (input <= 0) {
  //         throw new Error('Input amount needs to be greater than 0');
  //       }
  //       const payload = routeAndQuote.route.makePayload(input, minOut, true);
  //       const result = await signAndSubmitTransaction(
  //         payload as Types.TransactionPayload_EntryFunctionPayload,
  //         options
  //       );
  //       if (result) {
  //         openTxSuccessNotification(
  //           result.hash,
  //           `Swapped ${input} ${routeAndQuote.quote.inputSymbol} for ${routeAndQuote.quote.outputUiAmt} ${routeAndQuote.quote.outputSymbol}`
  //         );
  //         setRefreshWalletClient(true);
  //         success = true;
  //       }
  //     } catch (error) {
  //       console.error('Request swap by route error:', error);
  //       if (error instanceof Error) {
  //         openErrorNotification({ detail: error?.message });
  //       }
  //       success = false;
  //     } finally {
  //       return success;
  //     }
  //   },
  //   [activeWallet, signAndSubmitTransaction]
  // );
  
  const handleIndexPageData = () => {
    let IndexPageTableData = [];
    for (const [coin_type, pool] of Object.entries(state?.lending_pools)) {
      let o = {};
      let vault = state?.vaults[coin_type];
      // @ts-ignore
      let decimals = pool?.coin_info?.info.decimals;
      // @ts-ignore
      let name = pool?.coin_info?.info?.name;
      // console.log(`decimals: `, decimals);
      // debugger;
      o = {
        coin_type,
        name,
        deposited_value: vault?.deposited_value?.format(9),
        // @ts-ignore
        deposited_amount: Number(vault.total_underlying?.format(decimals)).toFixed(decimalsCfg[pool?.coin_info?.info?.name] || 2),
        borrowed_value: vault?.borrowed_value?.format(9),
        borrowed_amount: vault?.total_borrowed?.format(decimals),
        current_borrow_apr: Number(vault?.current_borrow_apr?.format(9) * 100).toFixed(2) + '%',
        current_supply_apr: Number(vault?.current_supply_apr?.format(9) * 100).toFixed(2) + '%',
        current_utilization: Number(vault?.current_utilization?.format(9) * 100).toFixed(2) + '%',
        // @ts-ignore
        price: Number(state?.coin_prices[coin_type]?.price?.format(9)).toFixed(decimalsCfg[vault?.coin_info?.info?.name] || 2) || '0',
        // @ts-ignore
        available_liquidity: Number(vault?.coins?.format(decimals)).toFixed(decimalsCfg[name] || 2),
        wallet_value: '-',
        isShowUSD: true,
      }
      IndexPageTableData.push(o);
    }
    IndexPageTableData.map(item => {
      for (const [coin_type, coin_store] of Object.entries(state?.user_coins)) {
        // @ts-ignore
        if (item.name === coin_store.coin_info.info.name) {
          // @ts-ignore
          // item.wallet_value = Number(coin_store.formatted_value).toFixed(decimalsCfg[item.name] || 4) || 0;
          item.wallet_value = Math.floor(Number(coin_store.formatted_value) * Math.pow(10, decimalsCfg[item.name])) / Math.pow(10, decimalsCfg[item.name]) || 0;
          // @ts-ignore
          // // console.log(`coin_store.formatted_value: `, item.wallet_value);
          // debugger;
        }
      }
    })
    return IndexPageTableData;
  }

  const handleRulesPageData = () => {
    // decimalsCfg
    let rulesPageData = [];
    if (Object.entries(state?.vaults || {}).length) {
      for (const [coin_type, vault] of Object.entries(state?.vaults || {})) {
        // // // debugger;
        // console.log(`state?.coin_prices[coin_type]: `, state?.coin_prices[coin_type]);
        // debugger;
        let config = state?.lending_pools[coin_type].config;
        // @ts-ignore
        let name = vault?.coin_info?.info?.name;
        // @ts-ignore
        let decimals = vault?.coin_info?.info?.decimals;
        rulesPageData.push({
          coin_type,
          borrow_fee: state?.lending_pools[coin_type]?.borrow_cap?.borrow_fee?.format(9), // borrow factor
          ld: config?.liquidation_factor?.format(9), // borrow factor
          bf: config?.debt_factor?.format(9), // borrow factor
          df: config?.asset_factor?.format(9), // borrow factor
          // @ts-ignore
          price: Number(state?.coin_prices[coin_type]?.price?.format(9)).toFixed(decimalsCfg[vault?.coin_info?.info?.name] || 2) || '0',
          // @ts-ignore
          name,
          // @ts-ignore
          decimals,
          // @ts-ignore
          borrowed_value: vault?.borrowed_value?.format(9),
          // @ts-ignore
          deposited_value: vault?.deposited_value?.format(9),
          // @ts-ignore
          // current_borrow_apr: vault?.current_borrow_apr?.toString(),
          current_borrow_apr: Number(vault?.current_borrow_apr?.format(9) * 100).toFixed(2) + '%',
          // @ts-ignore
          // current_supply_apr: vault?.current_supply_apr?.toString(),
          current_supply_apr: Number(vault?.current_supply_apr?.format(9) * 100).toFixed(2) + '%',
          // @ts-ignore
          ltv: vault?.config?.asset_factor?.format(9) || '-',
          // @ts-ignore
          // current_utilization: vault?.current_utilization?.toString(),
          current_utilization: Number(vault?.current_utilization?.format(9) * 100).toFixed(2) + '%',
          // @ts-ignore
          available_liquidity: vault?.coins?.format(vault?.coin_info?.info?.decimals),
          // @ts-ignore
          pledge_limit: config?.pledge_limit?.format(9) || '-',
          // @ts-ignore
          borrow_limit: config?.borrow_limit?.format(9) || '-',
          // liquidation_Threshold: config?.LIQUIDATION_THRESHOLD?.toString(),
          // liquidation_penalty: config?.LIQUIDATION_FACTOR?.toString(),
          // @ts-ignore
          liquidation_Threshold: config?.liquidation_threshold?.format(9),
          // @ts-ignore
          // liquidation_penalty: state?.lending_pools[coin_type]?.config?.liquidation_factor?.format(9),
          // @ts-ignore
          amount: vault?.coins?.format(9),
          // wallet_value: state.user_coins?.[coin_type]?.formatted_value
          // @ts-ignore
          // wallet_value: state.user_coins?.[coin_type]?.formatted_value ? Number(state.user_coins?.[coin_type]?.formatted_value).toFixed(decimalsCfg[vault?.coin_info?.info?.name]) : 0
          wallet_value: state.user_coins?.[coin_type]?.formatted_value ? Math.floor(Number(state.user_coins?.[coin_type]?.formatted_value) * Math.pow(10, decimalsCfg[name])) / Math.pow(10, decimalsCfg[name]): 0
        })
      }
    }
    // console.log(`res: `, rulesPageData);
    return rulesPageData;
  }

  const handleUserCoinInfo = () => {
    let userCoinInfo = [];
    // Read user data
    for (const [coin_type, coin_store] of Object.entries(state.user_coins)) {
      // @ts-ignore
      let name = coin_store?.coin_info?.info?.name;
      userCoinInfo.push({
        coin_type,
        // @ts-ignore
        name,
        // @ts-ignore
        wallet_value: Math.floor(coin_store?.formatted_value * Math.pow(10, decimalsCfg[name])) / Math.pow(10, decimalsCfg[name]) || 0,
        // @ts-ignore
        price: Number(state?.coin_prices[coin_type]?.price?.format(9)).toFixed(decimalsCfg[coin_store?.coin_info?.info?.name] || 2),
      })
    }
    // @ts-ignore
    window.userCoinInfo = userCoinInfo;
    return userCoinInfo;
  }

  const handleDepositSelectListData = (rulesPageData, userCoinInfo, isFirst = false) => {
    // setDepositSelectListData
    let depositSelectListData = [];
    rulesPageData?.map(pool => {
      userCoinInfo?.map(userCoin => {
        if (pool?.coin_type === userCoin?.coin_type) {
          // // // debugger;
          let decimals = state?.vaults?.[pool?.coin_type]?.coin_info?.info?.decimals;
          let name = state?.vaults?.[pool?.coin_type]?.coin_info?.info?.name;
          let underlying_amount = state?.user_balance?.assets?.find(item => item?.coin_type === pool?.coin_type)?.underlying_amount?.format(decimals);
          let coin_type = userCoin?.coin_type
          let vault = state?.vaults[coin_type];
          let o = {
            name,
            decimals,
            // @ts-ignore
            current_supply_apr: Number(vault?.current_supply_apr?.format(9) * 100).toFixed(2) + '%',
            coin_type,
            // wallet_value: userCoin?.wallet_value || 0,
            // wallet_value: Number(userCoin?.wallet_value).toFixed(decimalsCfg[name]) || 0,
            wallet_value: Math.floor((Number(userCoin?.wallet_value) * Math.pow(10, decimalsCfg[name]))) / Math.pow(10, decimalsCfg[name]) || 0,
            price: userCoin?.price,
            underlying_amount: Number(underlying_amount).toFixed(decimalsCfg[name]) || 0,
            isSelect: false,
            hasThisCoin: true
          };
          // console.log(`o: `, o);
          // debugger;
          if (!depositSelectListData.length) {
            // @ts-ignore
            o.isSelect = true;
          }
          depositSelectListData.push(o)
        }
      })
      if (!depositSelectListData.find(item => item?.coin_type === pool?.coin_type)) {
        let decimals = state?.vaults?.[pool?.coin_type]?.coin_info?.info?.decimals;
        let name = state?.vaults?.[pool?.coin_type]?.coin_info?.info?.name;
        let underlying_amount = state?.user_balance?.assets?.find(item => item?.coin_type === pool?.coin_type)?.underlying_amount?.format(decimals);
        let coin_type = pool?.coin_type
        let vault = state?.vaults[coin_type];
        let o = {
          name,
          coin_type,
          // @ts-ignore
          current_supply_apr: Number(vault?.current_supply_apr?.format(9) * 100).toFixed(2) + '%',
          // wallet_value: pool?.wallet_value || 0,
          // wallet_value: Number(pool?.wallet_value).toFixed(decimalsCfg[name]) || 0,
          wallet_value: Math.floor(Number(pool?.wallet_value) * Math.pow(10, decimalsCfg[name])) / Math.pow(10, decimalsCfg[name]) || 0,
          decimals,
          price: pool?.price,
          // underlying_amount: underlying_amount ? Number(underlying_amount).toFixed(decimalsCfg[name]) : 0,
          underlying_amount: underlying_amount ? Math.floor(Number(underlying_amount)  * Math.pow(10, decimalsCfg[name]) ) / Math.pow(10, decimalsCfg[name]) : 0,
          isSelect: false,
          hasThisCoin: name === 'Aptos Coin'
        }
        // console.log(`o: `, o);
        // debugger;
        if (!depositSelectListData.length) {
          // @ts-ignore
          o.isSelect = true;
        }
        depositSelectListData.push(o);
      }
    });

    // // // debugger;
    if (isFirst) {
      // @ts-ignore
      let name = decodeURIComponent(window.location.hash.slice(1));
      // // // debugger;
      // @ts-ignore
      let index = depositSelectListData.findIndex(item => item?.name === name);
      // console.log(`index: `, index, name);
      // // // debugger;
      if (index > -1) {
        let newData = [].slice.call(depositSelectListData).map(item => {
          item.isSelect = false
          return item;
        });
        let f = newData.splice(index, 1);
        f[0].isSelect = true;
        let list = [...f, ...newData];
        // @ts-ignore
        window.depositSelectListData = list;
        // // // debugger;
        // setDepositSelectListData(list);
        depositSelectListData = list;
      }
    }

    return depositSelectListData;
  }

  const handleWithdrawSelectListData = (rulesPageData, isFirst = false) => {
    let withdrawSelectListData = [];
    rulesPageData?.map(pool => {
      state?.user_balance?.assets?.map(userCoin => {
        if (pool?.coin_type === userCoin?.coin_type) {
          let name = state?.vaults?.[pool?.coin_type]?.coin_info?.info?.name;
          let decimals = state?.vaults?.[pool?.coin_type]?.coin_info?.info?.decimals;
          let underlying_amount = state?.user_balance?.assets?.find(item => item?.coin_type === pool?.coin_type)?.underlying_amount?.format(decimals)
          // // // debugger;
          let coin_type = userCoin?.coin_type
          let vault = state?.vaults[coin_type];
          let o = {
            name,
            decimals,
            // @ts-ignore
            current_supply_apr: Number(vault?.current_supply_apr?.format(9) * 100).toFixed(2) + '%',
            coin_type: userCoin?.coin_type,
            wallet_value: pool?.wallet_value || 0,
            price: pool?.price,
            // underlying_amount: Number(underlying_amount).toFixed(decimalsCfg[name]) || 0,
            // underlying_amount: Number(underlying_amount).toFixed(decimalsCfg[name]) || 0,
            underlying_amount: Math.floor(Number(underlying_amount) * Math.pow(10, decimalsCfg[name])) / Math.pow(10, decimalsCfg[name]) || 0,
            isSelect: false,
            hasThisCoin: true
          };
          if (!withdrawSelectListData.length) {
            // @ts-ignore
            o.isSelect = true;
          }
          withdrawSelectListData.push(o)
        }
      })
      // @ts-ignore
      if (!withdrawSelectListData.find(item => item?.coin_type === pool?.coin_type)) {
        let coin_type = pool?.coin_type
        let vault = state?.vaults[coin_type];
        let o = {
          name: state?.vaults?.[pool?.coin_type]?.coin_info?.info?.name,
          // @ts-ignore
          current_supply_apr: Number(vault?.current_supply_apr?.format(9) * 100).toFixed(2) + '%',
          coin_type: pool?.coin_type,
          wallet_value: pool?.wallet_value || 0,
          decimals: state?.vaults?.[pool?.coin_type]?.coin_info?.info?.decimals,
          price: pool?.price,
          underlying_amount: state?.user_balance?.assets?.find(item => item?.coin_type === pool?.coin_type)?.underlying_amount?.format(state?.vaults?.[pool?.coin_type]?.coin_info?.info?.decimals) || 0,
          isSelect: false,
          hasThisCoin: false
        }
        if (!withdrawSelectListData.length) {
          // @ts-ignore
          o.isSelect = true;
        }
        withdrawSelectListData.push(o);
      }
    });

    if (isFirst) {
      // @ts-ignore
      let name = decodeURIComponent(window.location.hash.slice(1));
      // @ts-ignore
      let index = withdrawSelectListData?.findIndex(item => item?.name === name);
      // console.log(`index: `, index, name);
      // // // debugger;
      if (index > -1) {
        let newData = [].slice.call(withdrawSelectListData).map(item => {
          item.isSelect = false
          return item;
        });
        let f = newData.splice(index, 1);
        f[0].isSelect = true;
        let list = [...f, ...newData];
        // @ts-ignore
        window.depositSelectListData = list;
        // @ts-ignore
        withdrawSelectListData = list;
      }
    }
    // console.log(`withdrawSelectListData: `, withdrawSelectListData);
    // // // debugger;
    return withdrawSelectListData;
  }

  const handleBorrowSelectListData = (rulesPageData, isFirst = false) => {
    let borrowSelectListData = [];
    // console.log(`indexPageTableData: `, indexPageTableData);
    rulesPageData?.map(pool => {
      state?.user_balance?.assets?.map(userCoin => {
        // console.log(`pool: `, pool, userCoin);
        // debugger
        let coin_type = pool?.coin_type
        let vault = state?.vaults[coin_type];
        if (pool?.coin_type === userCoin?.coin_type) {
          // debugger;
          let name = pool?.name;
          let decimals = pool?.decimals;
          let o = {
            name,
            decimals,
            // @ts-ignore
            current_borrow_apr: Number(vault?.current_borrow_apr?.format(9) * 100).toFixed(2) + '%',
            coin_type,
            wallet_value: pool?.wallet_value || 0,
            price: pool?.price,
            underlying_amount: pool?.underlying_amount?.format(decimals) || 0,
            // available_liquidity: Number(pool?.available_liquidity).toFixed(decimalsCfg[name]),
            available_liquidity: Math.floor(Number(pool?.available_liquidity) * Math.pow(10, decimalsCfg[name])) / Math.pow(10, decimalsCfg[name]),
            isSelect: false,
            hasThisCoin: true,
            isUSD: true,
            // max_borrow: Number(state?.max_to_borrow(coin_type)?.format(decimals)).toFixed(decimalsCfg[name])
            max_borrow: Math.floor(Number(state?.max_to_borrow(coin_type)?.format(decimals)) * Math.pow(10, decimalsCfg[name])) / Math.pow(10, decimalsCfg[name])
          };
          if (!borrowSelectListData.length) {
            // @ts-ignore
            o.isSelect = true;
          }
          borrowSelectListData.push(o)
        }
      })
      // @ts-ignore
      if (!borrowSelectListData.find(item => item?.coin_type === pool?.coin_type)) {
        let coin_type = pool?.coin_type
        let vault = state?.vaults[coin_type];
        let decimals = pool?.decimals;
        let name = pool?.name;
        let o = {
          name,
          decimals,
          coin_type,
          // @ts-ignore
          current_borrow_apr: Number(vault?.current_borrow_apr?.format(9) * 100).toFixed(2) + '%',
          wallet_value: pool?.wallet_value || 0,
          price: pool?.price,
          underlying_amount: pool?.underlying_amount?.format(pool?.decimals) || 0,
          available_liquidity: pool?.available_liquidity,
          isSelect: false,
          // max_borrow: Number(state?.max_to_borrow(coin_type)?.format(decimals)).toFixed(decimalsCfg[name]),
          max_borrow: Math.floor(Number(state?.max_to_borrow(coin_type)?.format(decimals)) * Math.pow(10, decimalsCfg[name])) / Math.pow(10, decimalsCfg[name]),
          hasThisCoin: false
        }
        if (!borrowSelectListData.length) {
          // @ts-ignore
          o.isSelect = true;
        }
        borrowSelectListData.push(o);
      }
    });

    // if (isFirst) {
    //   // @ts-ignore
    //   let name = decodeURIComponent(window.location.hash.slice(1));
    //   // @ts-ignore
    //   let index = borrowSelectListData?.findIndex(item => item?.name === name);
    //   // console.log(`index: `, index, name);
    //   // // // debugger;
    //   if (index > -1) {
    //     let newData = [].slice.call(borrowSelectListData).map(item => {
    //       item.isSelect = false
    //       return item;
    //     });
    //     let f = newData.splice(index, 1);
    //     f[0].isSelect = true;
    //     let list = [...f, ...newData];
    //     // @ts-ignore
    //     window.depositSelectListData = list;
    //     // @ts-ignore
    //     borrowSelectListData = list;
    //   }
    // }
    // console.log(`borrowSelectListData: `, borrowSelectListData);
    // @ts-ignore
    window.borrowSelectListData = borrowSelectListData;
    // debugger;
    return borrowSelectListData;
  }

  const handleRepaySelectListData = (rulesPageData, isFirst = false) => {
    let repaySelectListData = [];
    // console.log(`indexPageTableData: `, indexPageTableData);
    rulesPageData?.map(pool => {
      state?.user_balance?.debts?.map(userCoin => {
        // console.log(`pool: `, pool, userCoin);
        // // debugger
        let coin_type = pool?.coin_type
        let vault = state?.vaults[coin_type];
        if (pool?.coin_type === userCoin?.coin_type) {
          // // debugger;
          let o = {
            name: pool?.name,
            decimals: pool?.decimals,
            coin_type,
            // @ts-ignore
            current_borrow_apr: Number(vault?.current_borrow_apr?.format(9) * 100).toFixed(2) + '%',
            wallet_value: pool?.wallet_value || 0,
            price: pool?.price,
            underlying_amount: userCoin?.underlying_amount?.format(pool?.decimals) || 0,
            isSelect: false,
            hasThisCoin: true
          };
          if (!repaySelectListData.length) {
            // @ts-ignore
            o.isSelect = true;
          }
          repaySelectListData.push(o)
        }
      })
      // @ts-ignore
      if (!repaySelectListData?.find(item => item?.coin_type === pool?.coin_type)) {
        let coin_type = pool?.coin_type
        let vault = state?.vaults[coin_type];
        let o = {
          name: pool?.name,
          decimals: pool?.decimals,
          coin_type,
          // @ts-ignore
          current_borrow_apr: Number(vault?.current_borrow_apr?.format(9) * 100).toFixed(2) + '%',
          wallet_value: pool?.wallet_value || 0,
          price: pool?.price,
          underlying_amount: pool?.underlying_amount?.format(pool?.decimals) || 0,
          isSelect: false,
          hasThisCoin: false
        }
        if (!repaySelectListData.length) {
          // @ts-ignore
          o.isSelect = true;
        }
        repaySelectListData.push(o);
      }
    });

    // if (isFirst) {
    //   // @ts-ignore
    //   let name = decodeURIComponent(window.location.hash.slice(1));
    //   // @ts-ignore
    //   let index = borrowSelectListData?.findIndex(item => item?.name === name);
    //   // console.log(`index: `, index, name);
    //   // // // debugger;
    //   if (index > -1) {
    //     let newData = [].slice.call(borrowSelectListData).map(item => {
    //       item.isSelect = false
    //       return item;
    //     });
    //     let f = newData.splice(index, 1);
    //     f[0].isSelect = true;
    //     let list = [...f, ...newData];
    //     // @ts-ignore
    //     window.depositSelectListData = list;
    //     // @ts-ignore
    //     borrowSelectListData = list;
    //   }
    // }
    // console.log(`borrowSelectListData: `, repaySelectListData);
    // @ts-ignore
    window.repaySelectListData = repaySelectListData;
    // debugger;
    return repaySelectListData;
  }

  // const initSDK = async (env = window.location.hostname === 'app.patronus.fi' ? '' : '') => {
  const initSDK = async (env = window.location.hostname === 'app.qapatronus.fi' || window.location.hostname === 'app.patronus.fi' ? ENV.MAIN : '') => {
    // @ts-ignore
    if (!window.isInitSDK) {
      // config.use(env || ENV.TEST);
      // config.use(process.env.NODE_ENV === 'development' ? ENV.TEST : ENV.MAIN);
      // config.use(window.location.hostname !== 'app.patronus.fi' ? ENV.TEST : ENV.MAIN);
      // config.use(ENV.TEST);
      config.use(ENV.MAIN);
      loader = new DataLoader(config.NODE_URL)
      // @ts-ignore
      window.loader = loader;
      // await state.reconnect(config.NODE_URL);
      // await state.init_bank(config.BANK);
      // await state?.wallet?.connect();
      // console.log(`config: `, config);
      // debugger;
      await loader.init_oracle(state, config.ORACLE);
      await loader.init_bank(state, config.BANK);
      await loader.init_lending(state, config.LENDING);
      // @ts-ignore
      window.isInitSDK = true;
      // @ts-ignore
      window.loader = loader;
      setAppLoading(false);
    }
    // // console.log(`state: `, state, config, ENV, actions);


    let current_user;
    try {
      // debugger;
      // @ts-ignore
      current_user = await window.aptos.account();
      console.log('current_user: ', current_user);
      // @ts-ignore
      // state.wallet = window.aptos;
    } catch (e) {
      // console.log(`e: `, e);
    }

    try {
      if (!current_user || current_user.code) {
        // @ts-ignore
        current_user = await window.martian.connect();
      }
    } catch (e) {
      // console.log(`e: `, e);
    }

    if (current_user && current_user.address) {
      // await state.init_user(current_user.address);
      await loader.init_user(state, current_user);
    }

    // @ts-ignore
    window.globalState = state;

    // todo vaults user_balance user_coins
    // state.coins

    // @ts-ignore
    for (const [coin_type, coin_store] of Object.entries(state.coins)) {
      // 0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::
      // @ts-ignore
      let coin_type = coin_store?.coin_type;
      // @ts-ignore
      let name = coin_store?.info?.name;
      // @ts-ignore
      if (coin_type.indexOf('0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::') > -1 && coin_store && coin_store.info)  {
        // @ts-ignore
        coin_store.info.name = `lz${coin_store.info.symbol}`
      }
      console.log(`name------------------: `, name);
      // debugger;
      // userCoinInfo.push({
      //   coin_type,
      //   // @ts-ignore
      //   name,
      //   // @ts-ignore
      //   wallet_value: Math.floor(coin_store?.formatted_value * Math.pow(10, decimalsCfg[name])) / Math.pow(10, decimalsCfg[name]) || 0,
      //   // @ts-ignore
      //   price: Number(state?.coin_prices[coin_type]?.price?.format(9)).toFixed(decimalsCfg[coin_store?.coin_info?.info?.name] || 2),
      // })
    }

    // setGlobalState(state);

    let IndexPageTableData = handleIndexPageData();
    // @ts-ignore
    window.IndexPageTableData = IndexPageTableData;
    setIndexPageTableData(IndexPageTableData);
    // setGlobalState(state);
    // @ts-ignore
    // setGlobalState(Object.assign(window.globalState, state));
    setGlobalState(state);

    let rulesPageData = handleRulesPageData();
    // @ts-ignore
    window.rulesPageData = rulesPageData;
    setRulesPageData(rulesPageData);

    let userCoinInfo = handleUserCoinInfo();

    // let depositSelectListData = userCoinInfo.length > 1 ? handleDepositSelectListData(rulesPageData, userCoinInfo, true) : [];
    let depositSelectListData = handleDepositSelectListData(rulesPageData, userCoinInfo, true);
    // @ts-ignore
    window.depositSelectListData = depositSelectListData;
    setDepositSelectListData(depositSelectListData);

    let withdrawSelectListData = handleWithdrawSelectListData(rulesPageData, true);
    // @ts-ignore
    window.withdrawSelectListData = withdrawSelectListData;
    setWithdrawSelectListData(withdrawSelectListData);

    let borrowSelectListData = handleBorrowSelectListData(rulesPageData, true);
    setBorrowSelectListData(borrowSelectListData);

    let repaySelectListData = handleRepaySelectListData(rulesPageData, true);
    setRepaySelectListData(repaySelectListData);

    let userDebtInfo = [];
    // dashboard debt
    if (state?.user_balance?.debts) {
      for (const debt of state?.user_balance?.debts) {
        // console.log(debt)
        // // // debugger;
        let vault = state?.vaults[debt?.coin_type];
        let name = debt?.vault?.coin_info?.info?.name;
        let decimals = debt?.vault?.coin_info?.info?.decimals;
        let coin_type = debt?.coin_type;
        let config = state?.lending_pools[coin_type].config;
        let asset = state?.user_balance?.assets.find(item => item.coin_type === coin_type);
        userDebtInfo.push({
          name,
          coin_type,
          vd: asset?.underlying_amount?.format(decimals),
          // @ts-ignore
          ld: config?.liquidation_factor?.format(9), // borrow factor
          // @ts-ignore
          bf: config?.debt_factor?.format(9), // borrow factor
          // underlying_amount: Number(debt?.underlying_amount?.format(decimals)).toFixed(decimalsCfg[name]),
          underlying_amount: Math.floor(Number(debt?.underlying_amount?.format(decimals)) * Math.pow(10, decimalsCfg[name]) ) / Math.pow(10, decimalsCfg[name]),
          value: debt?.value?.format(9),
          isShowUSD: true,
          // wallet_value: state.user_coins?.[coin_type]?.formatted_value ? Number(state.user_coins?.[coin_type]?.formatted_value).toFixed(decimalsCfg[name]) : 0,
          wallet_value: state.user_coins?.[coin_type]?.formatted_value ? Math.floor(Number(state.user_coins?.[coin_type]?.formatted_value) * Math.pow(10, decimalsCfg[name])) / Math.pow(10, decimalsCfg[name]): 0,
          current_borrow_apr: Number(vault?.current_borrow_apr?.format(9) * 100).toFixed(2) + '%',
          isShowMore: false
        })
      }
    }

    // @ts-ignore
    window.userDebtInfo = userDebtInfo;

    let userDepositInfo = [];
    for (let i = 0; i < state?.user_balance?.assets?.length; i++) {
      let asset = state?.user_balance?.assets[i];
      // console.log(state?.user_balance?.assets[i]?.amount?.format(9))
      let name = asset?.vault?.coin_info?.info?.name;
      let decimals = asset?.vault?.coin_info?.info?.decimals;
      let coin_type = asset?.coin_type;
      let vault = state?.vaults[coin_type];
      let config = state?.lending_pools[coin_type].config
      // debugger;
      userDepositInfo.push({
        // @ts-ignore
        name,
        // @ts-ignore
        coin_type,
        // @ts-ignore
        ld: config?.liquidation_factor?.format(9), // borrow factor
        // @ts-ignore
        bf: config?.debt_factor?.format(9), // borrow factor
        // @ts-ignore
        // underlying_amount: Number(asset?.underlying_amount?.format(decimals)).toFixed(decimalsCfg[name]),
        underlying_amount: Math.floor(Number(asset?.underlying_amount?.format(decimals)) * Math.pow(10, decimalsCfg[name])) / Math.pow(10, decimalsCfg[name]),
        // @ts-ignore
        value: asset?.value?.format(9),
        coin: asset?.vault?.coin?.format(decimals),
        // wallet_value: state.user_coins?.[coin_type]?.formatted_value ? Number(state.user_coins?.[coin_type]?.formatted_value).toFixed(decimalsCfg[name]) : 0,
        wallet_value: state.user_coins?.[coin_type]?.formatted_value ? Math.floor(Number(state.user_coins?.[coin_type]?.formatted_value) * Math.pow(10, decimalsCfg[name]) ) / Math.pow(10, decimalsCfg[name]) : 0,
        current_supply_apr: Number(vault?.current_supply_apr?.format(9) * 100).toFixed(2) + '%',
        isShowUSD: true,
        isShowMore: false
      })
    }
    // @ts-ignore
    window.userDepositInfo = userDepositInfo;

    SetDashboardPageData({
      userCoinInfo,
      userDebtInfo,
      userDepositInfo,
    })

  }

  useEffect(() => {
    if (connected && account?.address) {
      // // console.log(`account?.address: `, account?.address);
      // debugger;
      state.user = null;
      state.user_balance = null;
      state.user_coins = {};
      loader.init_user(state, account);
      // setActiveWallet(hexStringV0ToV1(account?.address));
    } else {
      state.user = null;
      state.user_balance = null;
      state.user_coins = {};
    }
  }, [connected, account]);

  useEffect(() => {
    if (depositSelectListDataHasCoin) {
      let userCoinInfo = handleUserCoinInfo();
      let depositSelectListData = handleDepositSelectListData(rulesPageData, userCoinInfo);
      // @ts-ignore
      window.depositSelectListData = depositSelectListData;
      // // // debugger;
      setDepositSelectListData(depositSelectListData);
    } else {
      // @ts-ignore
      let newData = [].slice.call(depositSelectListData).filter(item => item?.hasThisCoin === true);
      // @ts-ignore
      window.depositSelectListData = newData;
      // // // debugger;
      setDepositSelectListData(newData);
    }
  }, [depositSelectListDataHasCoin]);

  useEffect(() => {
    let name = decodeURIComponent(window.location.hash.slice(1));
    // @ts-ignore
    let index = depositSelectListData?.findIndex(item => item?.name === name);
    // console.log(`index: `, index, name);
    // // // debugger;
    if (index > -1) {
      let newData = [].slice.call(depositSelectListData).map(item => {
        item.isSelect = false
        return item;
      });
      let f = newData.splice(index, 1);
      f[0].isSelect = true;
      let list = [...f, ...newData];
      // @ts-ignore
      window.depositSelectListData = list;
      // // // debugger;
      setDepositSelectListData(list);
    }
  }, [routeDepositParam]);

  useEffect(() => {
    let name = decodeURIComponent(window.location.hash.slice(1));
    // @ts-ignore
    let index = withdrawSelectListData?.findIndex(item => item?.name === name);
    // console.log(`index: `, index, name);
    // // // debugger;
    if (index > -1) {
      let newData = [].slice.call(withdrawSelectListData).map(item => {
        item.isSelect = false
        return item;
      });
      let f = newData.splice(index, 1);
      f[0].isSelect = true;
      let list = [...f, ...newData];
      // @ts-ignore
      window.withdrawSelectListData = list;
      // @ts-ignore
      setWithdrawSelectListData(list);
    }
  }, [routeWithdrawParam]);

  useEffect(() => {
    let name = decodeURIComponent(window.location.hash.slice(1));
    // @ts-ignore
    let index = borrowSelectListData?.findIndex(item => item?.name === name);
    // console.log(`index: `, index, name);
    // // // debugger;
    if (index > -1) {
      let newData = [].slice.call(borrowSelectListData).map(item => {
        item.isSelect = false
        return item;
      });
      let f = newData.splice(index, 1);
      f[0].isSelect = true;
      let list = [...f, ...newData];
      // @ts-ignore
      window.borrowSelectListData = list;
      // @ts-ignore
      setBorrowSelectListData(list);
    }
  }, [routeBorrowParam]);

  useEffect(() => {
    let name = decodeURIComponent(window.location.hash.slice(1));
    // @ts-ignore
    let index = repaySelectListData?.findIndex(item => item?.name === name);
    // console.log(`index: `, index, name);
    // // // debugger;
    if (index > -1) {
      let newData = [].slice.call(repaySelectListData).map(item => {
        item.isSelect = false
        return item;
      });
      let f = newData.splice(index, 1);
      f[0].isSelect = true;
      let list = [...f, ...newData];
      // @ts-ignore
      window.repaySelectListData = list;
      // @ts-ignore
      setRepaySelectListData(list);
    }
  }, [routeRepayParam]);


  useEffect(() => {
    initSDK();
  }, [])

  return (
    <SDKContext.Provider
      value={{
        globalState,
        indexPageTableData,
        setIndexPageTableData,
        appLoading,
        setAppLoading,
        rulesPageData,
        setRulesPageData,
        dashboardPageData,
        SetDashboardPageData,
        isLoginModal,
        setIsLoginModal,
        depositSelectListData,
        setDepositSelectListData,
        isTransfer, setIsTransfer, depositSelectListDataHasCoin, setDepositSelectListDataHasCoin,
        withdrawSelectListData, setWithdrawSelectListData,
        repaySelectListData, setRepaySelectListData,
        borrowSelectListData, setBorrowSelectListData,
        routeDepositParam, setRouteDepositParam,
        routeWithdrawParam, setRouteWithdrawParam,
        routeBorrowParam, setRouteBorrowParam,
        routeRepayParam, setRouteRepayParam,
      }}>
      {/*<Spin spinning={appLoading} indicator={<Loading />}>*/}
      {/*  {children}*/}
      {/*</Spin>*/}
      {children}
      <ModalLogin visible={isLoginModal} onCancel={() => setIsLoginModal(false)} />
    </SDKContext.Provider>
  );
};

export { SDKProvider, SDKContext };

