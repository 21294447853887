import TwiceConfirmModal from 'components/Modal/TwiceConfirmModal';
import SubmitCompH5 from './SubmitCompH5';
// import { Skeleton } from 'antd';
// import SDK from 'utils/sdk.js';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import back from 'assets/img/icon/back.png';

import iconDatabase from 'assets/img/icon/icon-database.png';
import iconHand from 'assets/img/icon/icon-hand.png';
// import arrowDown from 'assets/img/icon/arrow-down.png';
// import arrowUp from 'assets/img/icon/arrow-up.png';
// import { Collapse } from 'antd';
// const { Panel } = Collapse;
// const { actions , config, ENV, state } = SDK;
import btc from 'assets/img/icon/BTC.png';
// import { useModel } from '@plugin-model/useModel';
import { AppState, config, DataLoader } from 'utils/sdk.v2.js';
import ModalLogin from 'components/Modal/LoginModal';
import { useNavigate, useLocation } from 'react-router-dom';
import className from 'classnames';
import { SDKContext } from 'contexts/SDKProvider';
import { openErrorNotification } from 'utils/notifications';
import { notification } from '../../components/Antd';
import { useTranslation } from 'react-i18next';

export default function DepositPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(`history: `, location);

  // @ts-ignore
  const { depositSelectListData, withdrawSelectListData, setIsTransfer, setRouteDepositParam, AppState } = useContext(SDKContext);
  const { connected, account, network, connect, signAndSubmitTransaction, ...rest } = useWallet();
  const [depositAmount, setDepositAmount] = useState<string>('');
  const [isDepositBtnDisable, setIsDepositBtnDisable] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  let selectDepositData = depositSelectListData.find(item => item.isSelect);
  let selectWithdrawData = withdrawSelectListData.find(item => item.isSelect);
  let deposit_wallet_value = selectDepositData?.wallet_value;
  // @ts-ignore
  window.selectDepositData = selectDepositData;
  // @ts-ignore
  window.selectWithdrawData = selectWithdrawData;

  const checkDepositBtn = (value) => {
    setTimeout(() => {
      if (Number(value) > 0 && Number(value) <= Number(deposit_wallet_value)) {
        // debugger;
        setIsDepositBtnDisable(false);
      } else {
        // debugger;
        setIsDepositBtnDisable(true);
      }
    });
  }

  const reg = /^\D*(\d*(?:\.\d{0,5})?).*$/g;
  const onChangeDeposit = (e: any) => {
    // console.log('changed', e.target.value);
    const value = e.target.value;
    if (!value) return setDepositAmount('');
    if (!reg.test(value)) {
      let newValue = value.replace(reg, '$1');
      // debugger;
      setDepositAmount(newValue);
      return
    }
    if (Number(value) > Number(deposit_wallet_value)) {
      // debugger;
      setDepositAmount(deposit_wallet_value);
      checkDepositBtn(deposit_wallet_value);
      return
    }
    setDepositAmount(value);
    checkDepositBtn(value);
  };

  const onBlurDeposit = (e: any) => {
    const value = e.target.value;
    if (!value) return;
    if (!reg.test(value)) {
      let newValue = value.replace(reg, '$1');
      // debugger;
      setDepositAmount(newValue);
      return
    }
    if (Number(value) > Number(deposit_wallet_value)) {
      // debugger;
      setDepositAmount(deposit_wallet_value);
      checkDepositBtn(deposit_wallet_value);
      return
    }
    if (Number(value) <= 0) {
      // debugger;
      setDepositAmount(deposit_wallet_value);
      checkDepositBtn(deposit_wallet_value);
      return
    }
    setDepositAmount(value);
    checkDepositBtn(value);
  }

  const onClickSetDepositMax = (e: any) => {
    // console.log(`selectData: `, selectDepositData);
    let value = Number(deposit_wallet_value);
    // debugger;
    if (Number(deposit_wallet_value) <= 0 || isNaN(Number(deposit_wallet_value))) return;
    if (selectDepositData?.name === 'Aptos Coin') {
      value = value - value * 0.05;
      // @ts-ignore
      setDepositAmount(String(value));
    } else {
      // @ts-ignore
      setDepositAmount(deposit_wallet_value);
    }
    checkDepositBtn(value);
  }

  const onClickDeposit = async () => {
    // @ts-ignore
    // console.log(`Number(depositAmount * 100000000).toString(): `, Number(Math.floor(depositAmount * Math.pow(10, selectDepositData?.decimals)))?.toString());
    // debugger;
    // todo user is login and active
    try {
      // @ts-ignore
      // await actions.lending_pledge('0x1::aptos_coin::AptosCoin', Number(withdrawAmount * 100000000));
      let pending_tx = await signAndSubmitTransaction({
        type: 'entry_function_payload',
        function: `${config.LENDING}::periphery::pledge`, // deposit
        type_arguments: [selectDepositData.coin_type],
        // @ts-ignore
        arguments: [Number(Math.floor(depositAmount * Math.pow(10, selectDepositData?.decimals)))?.toString()]
      });
      // let loader = new DataLoader(config.NODE_URL);
      // debugger;
      // @ts-ignore
      let tx = await window?.loader?.client?.waitForTransactionWithResult(pending_tx.hash, { checkSuccess: true });
      // console.log(`tx: `, tx);

      // @ts-ignore
      // // console.log(`window?.state: `, await window.aptos.account());
      // @ts-ignore
      // let address = await window.aptos.account();
      // @ts-ignore
      await window?.loader.reload_user_balance(account?.address);
      // @ts-ignore
      // console.log(`window?.state: `, window?.state);
      // @ts-ignore
      await loader.reload_user_coin(window?.state, selectDepositData?.coin_type);
      debugger;
      setIsTransfer(false);
      setIsModalVisible(false);
      // @ts-ignore
      // console.log(`loader: `, window?.loader);
      debugger;
      notification.destroy();
      openErrorNotification({ type: 'success', detail: tx.vm_status });

      setTimeout(() => {
        // @ts-ignore
        window.location.reload();
      }, 3000);
    } catch (error) {
      // console.log(`e: `, error);
      debugger;
      error.message && openErrorNotification({ detail: error.message });
      setIsTransfer(false);
    }
  }

  useEffect(() => {
    // console.log(`location: `, location);
    let name = decodeURIComponent(window.location.hash.slice(1));
    if (!name) return;
    setRouteDepositParam(name);
  }, [location])


  // @ts-ignore
  return (
    <>
      <div className="min-h-[100vh] flex md:hidden flex-col items-center">
        <section className="flex flex-col justify-start min-h-[90vh] w-full">
          <section className="px-[3%]  w-full">

            <section className="w-full">

              {/* back */}
              <section className="flex" onClick={() => {
                navigate('/deposit-list');
              }}>
                <section className="h-[49px] px-[20px] text-[#31767A] flex items-center justify-center cursor-pointer border border-[#000] rounded-full hover:bg-[white]">
                  <section className="w-[20px] mr-3">
                    <img src={back} alt='' className="w-full" />
                  </section>
                  {/*BACK*/}
                  {
                    t('back')
                  }
                </section>
              </section>


              {/* tabs */}
              <section className="flex justify-between w-full mt-[20px]">
                <section className="flex  w-full h-[36px] border border-[#454545] rounded-xl cursor-pointer text-sm">
                  <div className={className('flex items-center justify-center w-1/2 rounded-l-xl bg-primeBlackTab')}>
                    <div className="w-[15px] mr-3">
                      <img src={iconDatabase} alt='' className="w-full" />
                    </div>
                    {/*Deposit*/}
                    {
                      t('Deposit')
                    }
                  </div>

                  <div onClick={() => {
                    navigate(`/withdraw${location.hash}`);
                    // setCurrentTab('Withdraw');
                  }} className={className('flex items-center justify-center w-1/2 rounded-r-xl border-l border-[#454545] ')}>
                    <div className="w-[15px] mr-3">
                      <img src={iconHand} alt='' className="w-full" />
                    </div>
                    {/*Withdraw*/}
                    {
                      t('Withdraw')
                    }
                  </div>
                </section>
              </section>

              <section className=''>
                <SubmitCompH5 isBtnDisable={isDepositBtnDisable}
                  inputValue={depositAmount}
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  selectListData={depositSelectListData}
                  onClickSetMax={onClickSetDepositMax}
                  onBlur={onBlurDeposit}
                  setAmount={setDepositAmount}
                  onChangeInput={onChangeDeposit}
                  onSubmitFn={onClickDeposit} options={{
                    // name: 'deposit',
                    // key: '',
                    name: 'deposit',
                    hoverColor: 'depositHover',
                    buttonText: 'Deposit',
                    coinName: selectDepositData?.name,
                    price: selectDepositData?.price,
                    wallet_value: deposit_wallet_value,
                  }} />
              </section>

            </section>
          </section>

        </section>

        {/*<TwiceConfirmModal visible={isModalVisible} onCancel={() => setIsModalVisible(false)} onOk={onClickDeposit} />*/}
      </div>
    </>
  );
}
