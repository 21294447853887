import className from 'classnames';
// import 'antd/dist/antd.css';
import { SDKContext } from 'contexts/SDKProvider';
import React, { useContext, useMemo, useState } from 'react';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
// import back from 'assets/img/icon/back.png';
// import { history } from 'umi';
// import iconDatabase from 'assets/img/icon/icon-database.png';
// import iconHand from 'assets/img/icon/icon-hand.png';
// import arrowDown from 'assets/img/icon/arrow-down.png';
// import arrowUp from 'assets/img/icon/arrow-up.png';
// import btc from 'assets/img/icon/BTC.png';
import deposit from 'assets/img/title/deposit.png';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import { iconsCfg } from 'config/decimals';
import { useTranslation } from 'react-i18next';
import ListH5 from './ListH5';

export default function DepositPage() {
  const { t } = useTranslation();
  // @ts-ignore
  const { globalState, indexPageTableData, appLoading, setIndexPageTableData } = useContext(SDKContext);
  const navigate = useNavigate();
  // const { globalState, setGlobalState, tableData, setTableData, } = useModel('bank');
  // const [currentTab, setCurrentTab] = useState<String>('Deposit');
  const { connected, account, network, connect, ...rest } = useWallet();
  const [depositAmount, setDepositAmount] = useState<String>('');
  const [withdrawAmount, setWithdrawAmount] = useState<String>('');
  const [currentUnit, setCurrentUnit] = useState<String>(indexPageTableData.every(item => item.isShowUSD) ? 'usd' : 'amount');

  const onClickTab = (tab) => {
    if (tab === 0) {
      setCurrentUnit('usd');
      let newData = indexPageTableData.map(item => {
        item.isShowUSD = true;
        return item;
      });
      setIndexPageTableData(newData);
    } else {
      let newData = indexPageTableData.map(item => {
        item.isShowUSD = false;
        return item;
      });
      // console.log(`newData: `, newData);
      setCurrentUnit('amount');
      setIndexPageTableData(newData);
    }
  }

  return (
    <>
      <ListH5 />
      <div className="hidden lg:flex min-h-[100vh] flex-col items-center">
        <section className="flex flex-col justify-start min-h-[100vh]">

          <section className="w-full lg:w-[1024px] xl:w-[1280px]">

            <section className="flex justify-between  h-[191px]">
              <section className="lg:w-[1024px] xl:w-[1280px] flex flex-col">
                <section className="text-[48px] font-['BioRhyme'] mt-[40px] mb-[10px] w-[500px] h-[82px]">
                  <img src={deposit} alt='' className="w-full" />
                </section>

                <section className=" text-[16px] font-['BioRhyme'] mb-[40px] text-[#B4AFAE]">
                  {
                    t('Deposit asset to earn income.')
                  }
                </section>
              </section>

              <section className="flex items-center">
                <div className="border border-[#161616] p-2 rounded-[12px] relative w-[394px] h-[114px]">
                  <div className="bg-[#34A7AE] absolute left-0 bottom-0 h-[4px] w-[92%] left-[50%] ml-[-46%] blur z-0" />

                  <div className="flex flex-col rounded-[12px] justify-end items-end border border-[#454545] h-[109px] pr-[16px]  pb-[16px] bg-[#292d2ef7]">
                    <div className="text-2xl pb-[8px]">
                      $ {globalState?.total_pledged_value ? parseInt(globalState?.total_pledged_value?.format(9)) : '-'}
                    </div>
                    <div className="text-[#BBBBBB]">
                      {/*Total Deposit*/}
                      {
                        t('Total Deposit')
                      }
                    </div>
                  </div>
                </div>
              </section>

            </section>

            <section className="flex justify-between items-center">
              <div className="text-[24px] hidden">
                {/*Deposit asset to earn income.*/}
                {
                  t('Deposit asset to earn income.')
                }
              </div>

              <div className="flex w-[156px] p-[2px] bg-[#404647] rounded-full select-none text-[12px]">
                <div className="hidden text-[#31767A]" />
                <div onClick={() => {
                  onClickTab(0);
                  // setCurrentUnit('usd');
                }} className={className('flex justify-center items-center w-[78px] py-[8px] rounded-full cursor-pointer text-[#B4AFAE] ', currentUnit === 'usd' ? ' !text-[#31767A] bg-[#fff]' : ' hover:text-[#fff]')}>
                <span>
                  USD
                </span>
                </div>
                <div onClick={() => {
                  onClickTab(1);
                  // setCurrentUnit('amount');
                }} className={className('flex justify-center items-center w-[78px] py-[8px] rounded-full cursor-pointer text-[#B4AFAE] ', currentUnit === 'amount' ? ' !text-[#31767A] bg-[#fff] ' : ' hover:text-[#fff]')}>
                <span>
                  {
                    t('Amount')
                  }
                </span>
                </div>
              </div>
            </section>
            {/* tabs */}

            <section className="mt-[25px] mb-[100px]">
              <div className="">
                <table
                  className=" lg:w-[1024px] xl:w-[1280px] border-separate"
                  style={{
                    borderSpacing: '0',
                  }}
                >
                  <thead className={`h-[53px] text-[18px]`}>
                  <tr className={`text-left bg-[#25282A] text-center`}>
                    <th
                      className="px-4 py-2 text-[#E4E4E4]  font-light rounded-tl-xl"
                      style={{
                        // textIndent: '20px',
                      }}
                    >
                      {/*Asset*/}
                      {
                        t('Asset')
                      }
                    </th>
                    <th className="px-4 py-2 text-[#E4E4E4]  font-light">
                      {/*Total Deposit*/}
                      {
                        t('Total Deposit')
                      }
                    </th>
                    <th className="px-4 py-2 text-[#E4E4E4]  font-light">
                      {/*Deposit APY*/}
                      {
                        t('Deposit APY')
                      }
                    </th>
                    <th className="px-4 py-2 text-[#E4E4E4]  font-light">
                      {/*Wallet Balance*/}
                      {
                        t('Wallet Balance')
                      }
                    </th>
                    <th className="py-2 text-[#E4E4E4]  font-light rounded-tr-xl pl-16">
                      {/*Operation*/}
                      {
                        t('Operation')
                      }
                    </th>
                  </tr>
                  </thead>
                  <tbody className=" bg-[#1D2021]" style={{}}>

                  {
                    indexPageTableData.map((item, i) => {
                      return (
                        <tr onClick={() => {
                        }} className={'group h-[53px] text-center '} key={item.name}>
                          <td className={className(' font-black text-[16px]  font-medium   border-b-[red] px-4 py-3', '',
                            i === (indexPageTableData?.length - 1) ? 'rounded-bl-xl' : ''
                          )} style={{
                            borderBottom: i !== (indexPageTableData?.length - 1) ? '2px solid #2F3132' : '0'
                          }}>
                            <div className='flex items-center '>
                              <img className="px-3" src={iconsCfg?.[item.name]} alt='' />
                              {item.name}
                            </div>
                            {/*{item.name}*/}
                          </td>

                          <td className={className('  font-black text-[16px]  font-medium px-4 py-3', '')}
                              style={{
                                borderBottom: i !== (indexPageTableData?.length - 1) ? '2px solid #2F3132' : '0'
                              }}>
                            {/*{parseInt(item.deposited_value)}*/}
                            {item.isShowUSD ? `$${Number(item.deposited_value)?.toFixed(2)}` : Number(item.deposited_amount)?.toFixed(2)}
                          </td>

                          <td className={className('  font-black text-[16px]  font-medium   px-4 py-3', '')} style={{
                            borderBottom: i !== (indexPageTableData?.length - 1) ? '2px solid #2F3132' : '0'
                          }}>
                            <p className="text-[#4AFCDC]">
                              {item.current_supply_apr}
                            </p>
                          </td>

                          <td className={className('  font-black text-[16px]  font-medium  px-4 py-3', '')} style={{
                            borderBottom: i !== (indexPageTableData?.length - 1) ? '2px solid #2F3132' : '0'
                          }}>
                            {item.wallet_value}
                          </td>

                          <td className={className('font-black text-[16px]  font-medium divide-y divide-red', ' py-3', ' ',
                            i === (indexPageTableData?.length - 1) ? 'rounded-br-xl' : ''
                          )} style={{
                            borderBottom: i !== (indexPageTableData?.length - 1) ? '2px solid #2F3132' : '0'
                          }}>
                            <div className="flex justify-end cursor-pointer mr-12">
                              <div className="w-[150px] text-[#79CDBE] bg-[#2A3C3E] hover:bg-[#232A2A] rounded-tl-full rounded-bl-full px-[30px] py-2" onClick={() => {
                                // navigate(`/deposit?tab=0#${item.name}`);
                                navigate(`/deposit#${item.name}`);
                              }}>
                                {
                                  t('Deposit')
                                }
                              </div>
                              <div>&nbsp;</div>
                              <div className="w-[150px] text-[#E2AE60] bg-[#352F28] hover:bg-[#2A2824] rounded-tr-3xl rounded-br-3xl px-[30px] py-2 cursor-pointer"  onClick={() => {
                                // navigate(`/deposit?tab=1#${item.name}`);
                                navigate(`/withdraw#${item.name}`);
                              }}>
                                {
                                  t('Withdraw')
                                }
                              </div>
                            </div>
                          </td>

                        </tr>
                      );
                    })
                  }

                  </tbody>
                </table>

                {
                  <Skeleton active round loading={appLoading || !indexPageTableData?.length} className="my-6" />
                }
              </div>
            </section>

          </section>

        </section>
        {/*<Footer />*/}
      </div>
    </>
  );
}
