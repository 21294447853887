import className from 'classnames';
// import 'antd/dist/antd.css';
import { SDKContext } from 'contexts/SDKProvider';
import React, { useContext, useMemo, useState } from 'react';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import { iconsCfg } from 'config/decimals';
import { useTranslation } from 'react-i18next';
import depositSlogan from 'assets/img/h5/slogan-text/deposit.png';

export default function DepositPage() {
  const { t } = useTranslation();
  // @ts-ignore
  const { globalState, indexPageTableData, appLoading, setIndexPageTableData } = useContext(SDKContext);
  const navigate = useNavigate();
  // const { globalState, setGlobalState, tableData, setTableData, } = useModel('bank');
  // const [currentTab, setCurrentTab] = useState<String>('Deposit');
  const { connected, account, network, connect, ...rest } = useWallet();
  const [depositAmount, setDepositAmount] = useState<String>('');
  const [withdrawAmount, setWithdrawAmount] = useState<String>('');
  const [currentUnit, setCurrentUnit] = useState<String>(indexPageTableData.every(item => item.isShowUSD) ? 'usd' : 'amount');

  const onClickTab = (tab) => {
    if (tab === 0) {
      setCurrentUnit('usd');
      let newData = indexPageTableData.map(item => {
        item.isShowUSD = true;
        return item;
      });
      setIndexPageTableData(newData);
    } else {
      let newData = indexPageTableData.map(item => {
        item.isShowUSD = false;
        return item;
      });
      // console.log(`newData: `, newData);
      setCurrentUnit('amount');
      setIndexPageTableData(newData);
    }
  }

  return (
    <>
      <section className="flex lg:hidden">
        <section className='px-[3%] w-full'>

          <section className='min-h-[92vh] '>

            <div className='max-w-[360px]  h-[46px] mt-[14px] px-[3%]'>
              <img src={depositSlogan} alt="" />
            </div>

            <section className=" text-[16px] mb-[24px] pl-[4%] font-['BioRhyme'] text-[#B4AFAE]">
              {
                t('Deposit asset to earn income.')
              }
            </section>

            <div className="border border-[#161616] p-2 rounded-[12px] relative ">
              <div className="bg-[#34A7AE] absolute left-0 bottom-0 h-[4px] w-[92%] left-[50%] ml-[-46%] blur z-0" />

              <div className="flex w-full flex-col rounded-[12px] justify-end items-end border border-[#454545] h-[109px] pr-[16px]  pb-[16px] bg-[#292d2ef7]">
                <div className="text-2xl pb-[8px]">
                  $ {globalState?.total_pledged_value ? parseInt(globalState?.total_pledged_value?.format(9)) : '-'}
                </div>
                <div className="text-[#BBBBBB]">
                  {/*Total Deposit*/}
                  {
                    t('Total Deposit')
                  }
                </div>
              </div>


            </div>

            <section className="flex justify-between px-[3%] mt-[24px]">
              

            <div className="flex w-[156px] p-[2px] bg-[#404647] rounded-full select-none text-[12px]">
                <div className="hidden text-[#31767A]" />
                <div onClick={() => {
                  onClickTab(0);
                  // setCurrentUnit('usd');
                }} className={className('flex justify-center items-center w-[78px] py-[8px] rounded-full cursor-pointer text-[#B4AFAE] ', currentUnit === 'usd' ? ' !text-[#31767A] bg-[#fff]' : ' hover:text-[#fff]')}>
                <span>
                  USD
                </span>
                </div>
                <div onClick={() => {
                  onClickTab(1);
                  // setCurrentUnit('amount');
                }} className={className('flex justify-center items-center w-[78px] py-[8px] rounded-full cursor-pointer text-[#B4AFAE] ', currentUnit === 'amount' ? ' !text-[#31767A] bg-[#fff] ' : ' hover:text-[#fff]')}>
                <span>
                  {
                    t('Amount')
                  }
                </span>
                </div>
              </div>
            </section>


            {/* table */}
            <section className="mb-[200px]">
              <div
                className="px-[3%]"
              >
                <table
                  className="w-full border-separate"
                  style={{
                    borderSpacing: '0 1rem',
                  }}
                >
                  <thead className={`h-[48px]`}>
                    <tr className={`text-center`}>
                      <th
                        className="px-3 py-2 text-[#E4E4E4] font-light text-sm"
                        style={{
                        }}
                      >
                        {
                          t('indexTableTheadAssets')
                        }
                      </th>

                      <th className="px-3 py-2 text-[#E4E4E4] font-light text-sm">
                        {/*Borrow APY*/}
                        {
                          t('Total Deposit')
                        }
                      </th>

                      <th className="px-3 py-2 text-[#E4E4E4] font-light text-sm">
                        {
                          t('indexTableTheadDepositAPY')
                        }
                      </th>

                    </tr>
                  </thead>
                  <tbody className="border text-center" style={{}}>

                    {
                      indexPageTableData.map((item: any) => {
                        return (
                          <tr onClick={() => {
                            navigate(`/deposit#${item.name}`);
                          }} key={item.name} className={'group h-[49px] cursor-pointer border hover:bg-[#282C2D]/50 bg-[#282C2D]/70'}>
                            <td className={className('rounded-tl-xl  text-[14px]  font-normal rounded-bl-xl ', 'px-4', 'py-2', '')}>
                              <div className='flex items-center '>
                                <img className="px-2" src={iconsCfg?.[item.name]} alt='' />
                                {item.name}
                              </div>
                            </td>

                            <td className={className(' text-[14px] font-normal ', 'px-4', 'py-2', '')}>
                              <div className="text-[#AAF56F]">
                              {item.isShowUSD ? `$${Number(item.deposited_value)?.toFixed(2)}` : Number(item.deposited_amount)?.toFixed(2)}
                              </div>
                            </td>

                            <td className={className('rounded-tr-xl rounded-br-xl text-[14px]  font-normal   ', 'px-4', 'py-2', '')}>
                              <div className="text-[#4AFCDC]">
                                {item.current_supply_apr}
                              </div>
                            </td>

                          </tr>
                        );
                      })
                    }

                  </tbody>
                </table>
                {
                  <Skeleton active round loading={appLoading || !indexPageTableData?.length} />
                }
              </div>

            </section>

          </section>

        </section>
      </section>
    </>
  );
}
