import { MaybeHexString } from 'aptos';
import { notification } from 'components/Antd';
import TextLink from 'components/TextLink';
import { ReactNode } from 'react';
import { CloseIcon, NotiErrorIcon, NotiInfoIcon, NotiSuccessIcon } from 'resources/icons';

type NotificationType = 'success' | 'error' | 'info' | 'warn';
// @ts-ignore
window.notification = notification;
interface INotificationArgs {
  detail: ReactNode;
  type?: NotificationType;
  title?: ReactNode;
  duration?: number;
}

const openNotification = ({ detail, type = 'success', title = '' }: INotificationArgs) => {
  if (!title) {
    title = type[0].toUpperCase() + type.slice(1);
  }

  let icon: ReactNode;
  if (type === 'success') {
    icon = <NotiSuccessIcon />;
  } else if (type === 'error') {
    icon = (<div> <img src={require('assets/img/icon/icon-error-yellow.svg').default} alt='' /> </div>);
  } else if (type === 'info') {
    icon = <NotiInfoIcon />;
  }

  notification.open({
    message: title,
    description: detail,
    placement: 'bottomRight',
    icon,
    className: `patronus-notification patronus-notification--${type}`,
    closeIcon: <div> <img src={require('assets/img/icon/icon-close.svg').default} alt='' /> </div>,
    duration: 10000
  });
};

export const openErrorNotification = (args: INotificationArgs) =>
  openNotification({ type: 'error', ...args });

export const openTxSuccessNotification = (txHash: MaybeHexString, content: string) => {
  const detail = (
    <p>
      <div>{content}</div>
      {/*<TextLink href={`https://explorer.aptoslabs.com/txn/${txHash}`}>View transaction</TextLink>*/}
    </p>
  );
  return openNotification({ detail, title: 'Transaction Success' });
};

export default openNotification;
