
import className from 'classnames';
import TwiceConfirmModal from 'components/Modal/TwiceConfirmModal';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
// @ts-ignore
import NP from 'number-precision';
import { message } from 'antd';
// import iconDatabase from 'assets/img/icon/icon-database.png';
// import iconHand from 'assets/img/icon/icon-hand.png';
import arrowDown from 'assets/img/icon/arrow-down.png';
import arrowUp from 'assets/img/icon/arrow-up.png';
// import { Collapse } from 'antd';
// const { Panel } = Collapse;
import { iconsCfg, decimalsCfg } from 'config/decimals';
import btc from 'assets/img/icon/BTC.png';
// import { useModel } from '@plugin-model/useModel';
// import ModalLogin from 'components/Modal/LoginModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { SDKContext } from 'contexts/SDKProvider';
import { useTranslation } from 'react-i18next';

// @ts-ignore
window.message = message;
// @ts-ignore
window.NP = NP;

interface SubmitCompProps {
  // options: {
  //   // name: string,
  //   // key: string,
  //   name: string,
  //   hoverColor: string,
  //   buttonText: string,
  //   coinName: string,
  //   price: string,
  //   wallet_value: string,
  // };
  options: any;
  onSubmitFn: (string) => void;
  onClickWithdrawAll?: (string) => void;
  onChangeInput: (e) => void;
  setIsModalVisible: (e) => void;
  setAmount: (e) => void;
  onClickSetMax: (e) => void;
  onBlur: (e) => void;
  inputValue: string;
  isClickMax?: boolean;
  isBtnDisable: boolean;
  isModalVisible: boolean;
  // isOnlyShowWalletCoin: boolean;
  selectListData: any;
  // onCancel?: () => void;
}

const buttonList = ['deposit', 'withdraw']

export default function SubmitComp(props: SubmitCompProps) {
  const { t } = useTranslation();
  const {onSubmitFn, options, onChangeInput, setAmount, onBlur, isClickMax, onClickWithdrawAll, inputValue, isBtnDisable, selectListData, onClickSetMax, isModalVisible, setIsModalVisible} = props;
  const navigate = useNavigate();
  const location = useLocation();
  // const location = useHis();
  // console.log(`history: `, location);

  // @ts-ignore
  const { globalState, depositSelectListDataHasCoin, setDepositSelectListDataHasCoin, setDepositSelectListData, setWithdrawSelectListData, dashboardPageData } = useContext(SDKContext);
  // const [currentTab, setCurrentTab] = useState<String>(  location.search === '?tab=1' ? 'Withdraw': 'Deposit');
  const { connected, account, network, connect, ...rest } = useWallet();

  // // const [depositAmount, setDepositAmount] = useState<string>('');
  // const [withdrawAmount, setWithdrawAmount] = useState<string>('');
  // // const [activeKey, setActiveKey] = useState<Array<string>>(['0']);
  // const [ isModalVisible, setIsModalVisible ] = useState<boolean>(false);
  const [ isOpen, setIsOpen ] = useState<boolean>(false);
  // const [ isOnlyShowWalletCoin, setIsOnlyShowWalletCoin ] = useState<boolean>(false);
  const [modalData, setModalData] = useState({
    inputValue,
    ...options
  });

  const {
    userDepositInfo,
  } = dashboardPageData;

  let currentSelect = selectListData?.find(item => item.isSelect);
  let currentLd = userDepositInfo?.find(item => item.name === currentSelect?.name)?.ld || 0;

  const onClickSelect = (index, j = 0) => {
    let newPoolCoinList = [].slice.call(selectListData);
    let tmp = newPoolCoinList[index];
    tmp.isSelect = true;
    newPoolCoinList[j].isSelect = false;
    newPoolCoinList[index] = newPoolCoinList[j];
    newPoolCoinList[j] = tmp;
    // console.log(`poolCoinList: `, newPoolCoinList);
    // debugger;
    if (options.name === 'deposit') {
      setDepositSelectListData(newPoolCoinList);
    } else {
      setWithdrawSelectListData(newPoolCoinList);
    }
    setIsOpen(false);
    setAmount('');
    // console.log(`tmp: `, tmp, navigate, location);
    // @ts-ignore
    window.location.hash = tmp.name;
    // debugger;
  }


  let user_balance = globalState?.user_balance;
  let originRiskIndex = user_balance?.total_vdebts_value?.format(9) / user_balance?.max_vdebts_value?.format(9) || 0;
  let riskIndex = user_balance?.total_vdebts_value?.format(9) /
    (
      options.name === 'deposit'
        ? Number(user_balance?.max_vdebts_value?.format(9)) + Number(inputValue) * Number(currentLd) * Number(currentSelect?.price)
        : Number(user_balance?.max_vdebts_value?.format(9)) - Number(inputValue) * Number(currentLd) * Number(currentSelect?.price)
    ) || 0;

  // console.log(`currentLd: riskIndex`, originRiskIndex, riskIndex);

  const onClickSetData = (index) => {
    switch (index){
      case buttonList[0]:
        // console.log(`modalData: `, modalData);
        // debugger;
        setModalData({
          ...modalData,
          ...options,
          inputValue,
          originRiskIndex,
          riskIndex,
        });
        break;
      case buttonList[1]:
        setModalData({
          ...modalData,
          ...options,
          inputValue,
          originRiskIndex,
          riskIndex,
        });
        break;
    }
  }

  // // console.log(`selectListData: `, selectListData);
  // debugger;

  // molecule
  // let molecule = userDebtInfo?.reduce((acc, item) => {
  //   return acc + Number(item?.value) * Number(item?.bf);
  // }, 0);
  //
  // let denominator = userDebtInfo?.reduce((acc, item) => {
  //   return acc + Number(item?.vd) * Number(item?.ld);
  // }, 0);

  // @ts-ignore
  // let maxWithdraw = currentSelect?.decimals ? Number(window?.state?.max_to_redeem(currentSelect?.coin_type)?.format(currentSelect?.decimals))?.toFixed(decimalsCfg[currentSelect?.name]) : 0;
  let maxWithdraw = currentSelect?.decimals ? Math.floor(Number(window?.state?.max_to_redeem(currentSelect?.coin_type)?.format(currentSelect?.decimals)) * Math.pow(10, decimalsCfg[currentSelect?.name]) ) / Math.pow(10, decimalsCfg[currentSelect?.name]) : 0;

  return (
    <>
      {
        buttonList.map(button => {
          return (
            <section key={button} className={options.name === button ? '' : 'hidden'}>
              <div className={className(`bg-[#242829] bg-deposit group-hover:bg-deposit group-focus:bg-deposit hover:bg-deposit focus:bg-deposit bg-depositHover hover:bg-depositHover hidden`)}/>
              <div className={className(`bg-withdraw group-hover:bg-withdraw group-focus:bg-withdraw hover:bg-withdraw focus:bg-withdraw bg-withdrawHover hover:bg-withdrawHover bg-withdrawHover  hidden`)}/>
              <div className={className(`bg-borrow group-hover:bg-borrow group-focus:bg-borrow hover:bg-borrow focus:bg-borrow bg-borrowHover hover:bg-borrowHover hidden`)}/>
              <div className={className(`bg-repay group-hover:bg-repay group-focus:bg-repay hover:bg-repay focus:bg-repay bg-repayHover hover:bg-repayHover hidden`)}/>

              <div className={className(`bg-[#404647] text-[#535859]  hidden`)}/>
              <section className="w-full md:w-[768px] lg:w-[1024px] xl:w-[1280px] min-h-[288px] mb-[60px] bg-primeBlack my-[26px] rounded-2xl">
                <section className='py-[24px] px-[30px]'>

                  <div className={className('flex items-center select-none', (options.name === 'deposit' || options.name === 'withdraw') ? ' ' : ' hidden')}>
                    {/*<label >*/}
                    <div className='' onClick={
                      () => setDepositSelectListDataHasCoin(!depositSelectListDataHasCoin)}>
                      <img src={
                        depositSelectListDataHasCoin ? require('assets/img/icon/icon-radios-chose.svg').default :
                        require('assets/img/icon/icon-radios-not.svg').default} alt='' />
                    </div>
                    <span className="ml-3 cursor-pointer" >
                      {/*Show wallet assets only*/}
                      {
                        t('Show wallet assets only')
                      }
                    </span>
                    {/*</label>*/}
                  </div>

                  <section className={className(`overflow-hidden my-3`)} style={{
                    height: isOpen ? `${selectListData?.length * 78}px` : '78px',
                    transition: `all .13s linear`
                  }}>
                    {
                      selectListData?.map((item, i) => {
                        if (i === 0) {
                          return (
                            <div key={i} className={className(`rounded-t-2xl group w-[530px] h-[78px] bg-[#242829] flex justify-between items-center px-[26px] cursor-pointer hover:bg-${button} focus:bg-${button} `,
                              (isOpen && selectListData.length > 1) ? `bg-${button} ` : ' rounded-b-2xl hover:rounded-b-2xl')} style={{
                              // boxShadow: activeKey[0] === '1' ? ' ': '7px 7px 25px rgba(0, 0, 0, 0.2)'
                            }}>
                              <div className="flex items-center">
                                <div className="w-[36px] h-[36px] flex items-center">
                                  <img src={iconsCfg?.[selectListData[i]?.name]} alt='' />
                                </div>
                                <div className="ml-1 text-[#fff]">{
                                  selectListData[i]?.name
                                }</div>

                                <div className="w-[24px] h-[24px] cursor-pointer ml-6" onClick={() => {
                                  if (selectListData.length < 2) {
                                    message.destroy();
                                    message.warn('no more coins!');
                                    return;
                                  }
                                  setIsOpen(!isOpen);
                                }}>
                                  <img src={isOpen ? arrowUp : arrowDown } alt='' />
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input type='text'
                                       onBlur={onBlur}
                                       onChange={onChangeInput}
                                       value={inputValue} className={ className(`text-[16px] middle text-right bg-[#242829] text-[#fff] 
                                       group-hover:bg-${button} group-focus:bg-${button} `,
                                  (isOpen && selectListData.length > 1) ? `bg-${button} ` : ' '
                                ) } style={{}} placeholder="Amount" />
                                <div className="text-[#E4E4E4] ml-3 cursor-pointer hove:text-[#BBBBBB]" onClick={onClickSetMax}>
                                  {
                                    t('MAX')
                                  }
                                </div>
                              </div>
                            </div>
                          )
                        } else {
                          return (
                            <div onClick={() => {
                              onClickSelect(i);
                            }} key={item?.name} className={
                              className(`bg-${button} w-[530px] cursor-pointer  `, i === (selectListData?.length - 1) ? 'rounded-b-2xl' : ' ' )}>
                              <div className="px-[26px] flex w-[530px] h-[78px] justify-between">
                                <div className="flex items-center">
                                  <div className="w-[36px] h-[36px] flex items-center">
                                    <img src={iconsCfg?.[selectListData[i]?.name]} alt='' />
                                  </div>
                                  <div className="ml-1 text-[#fff]">{item?.name}</div>
                                </div>
                                <div className="flex items-end text-[#fff] flex-col justify-center w-[130px]">
                                  <div className="text-[12px]">
                                    {options.name === 'deposit' ? 'Wallet Balance' : 'Collateral Amount'}
                                  </div>
                                  <div className="text-[16px] mt-1">
                                    {options.name === 'deposit' ? item?.wallet_value : item.underlying_amount}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })
                    }
                  </section>

                  <div className="flex  text-[16px] my-[32px]">
                    <div className="text-[#BBBBBB]">
                      {/*Wallet Balance*/}
                      {
                        t('Wallet Balance')
                      }
                    </div>
                    <div className="ml-[120px] text-[#E4E4E4]">
                      { currentSelect?.wallet_value } { currentSelect?.name }
                    </div>
                  </div>

                  <div className={className('flex text-[16px] my-[32px] ', button !== 'withdraw' && 'hidden')}>
                    <div className="text-[#BBBBBB]">
                      {/*Available to withdraw*/}
                      {
                        t('Available to withdraw')
                      }
                    </div>
                    <div className="ml-[61px] text-[#E4E4E4]">
                      {/*{ currentSelect?.underlying_amount }*/}
                      { maxWithdraw }&nbsp;
                      { currentSelect?.name }
                    </div>
                  </div>

                  <div className={className('flex text-[16px] my-[32px] ')}>
                    <div className="text-[#BBBBBB]">
                      {/*Deposit APY*/}
                      {
                        t('Deposit APY')
                      }
                    </div>
                    <div className="ml-[138px] text-[#E4E4E4]">
                      { currentSelect?.current_supply_apr }
                    </div>
                  </div>

                  <div className="flex text-[16px] ">
                    <div className="text-[#BBBBBB]">
                      {
                        t('Risk Index')
                      }
                    </div>
                    <div className="ml-[155px] text-[#E4E4E4]">
                      {
                        originRiskIndex?.toFixed(2)
                      }
                      {
                        (inputValue && riskIndex) && (
                          <>
                            -{'>'}&nbsp;<span className={`text-${modalData?.name}`}>
                              { riskIndex?.toFixed(2) }
                            </span>
                          </>
                        )
                      }
                    </div>
                  </div>

                </section>
              </section>
              <section className="my-[20px]">
                <section
                  onClick={() => {
                    if (isBtnDisable) return;
                    onClickSetData(button);
                    setIsModalVisible(true);
                  }}
                  className={
                    className(`relative z-10 cursor-pointer w-[200px] h-[48px] rounded-full border-${button} flex items-center justify-center  `,
                      isBtnDisable ? ` bg-[#404647] text-[#535859]` :  `bg-${button} hover:bg-${button}Hover`
                    )}>
                  {t(options.buttonText)}
                </section>
              </section>
            </section>
          )
        })
      }

      <TwiceConfirmModal modalData={modalData}
        visible={isModalVisible}
                         onCancel={() => setIsModalVisible(false)}
                         onOk={ onSubmitFn} />

    </>
  );
}
