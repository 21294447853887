import React, { useState, useEffect, useCallback, useRef } from 'react';

import classNames from 'classnames';
import { Modal, ModalProps } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
// @ts-ignore
import scss from './index.scss';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import { useTranslation } from 'react-i18next';

interface ModalLoginProps extends ModalProps {
  onOk?: () => void;
  onCancel?: () => void;
}

export default React.memo<ModalLoginProps>((props) => {
  const { t } = useTranslation();
  const { visible, onOk, onCancel } = props;
  const wallet = useWallet();

  return (
      <Modal
        wrapClassName={classNames('loginModal', scss.login_container)}
        open={visible}
        centered
        title={
          <>
            <span>{
              t('loginModalTitle')
            }</span>
          </>
        }
        onOk={onOk}
        onCancel={onCancel}
        footer={null}
      >
        <div className="w-full flex justify-center flex-col items-center" >
          {
            wallet.wallets.map(item => {
              return (
                <div onClick={async () => {
                  await wallet.select(item.adapter.name);
                }}
                    key={item.adapter.name} className="hover:border-[#fff] w-full md:w-[388px] h-[50px] my-3 rounded-full text-white border border-[#363839] flex items-center justify-center cursor-pointer">
                  <div className="w-[30px] h-[30px] rounded-xl mr-3">
                    <img className="rounded-xl w-full h-full" src={item.adapter.icon} alt='' />
                  </div>
                  <div className="text-[14px] text-[#fff]">{ item.adapter.name }</div>
                </div>
              )
            })
          }

        </div>

        <div className="flex px-[30px] items-center">
          <InfoCircleFilled
            style={{
              fontSize: `${(16 * 100) / 1920}vw`,
              color: '#6B7085',
            }}
          />
          <div className="flex ml-3">
            <span className="text-[#BBBBBB]">
              {
                t('loginModalDescribe')
              }
            </span>
          </div>
        </div>
      </Modal>
    // </div>
  );
});
