
// import usdc from 'assets/img/icon/usdc-icon.svg';
// import usdt from 'assets/img/icon/usdt-icon.svg';
// import btc from 'assets/img/icon/btc-icon.svg';
// import apt from 'assets/img/icon/apt-icon.svg';
// import eth from 'assets/img/icon/eth-icon.svg';

const stableCoinText = 'The lowest borrow APY for this currency is 2%. \n' +
  'The borrow APY is 8.3% when the utilization rate is 0.9, while the borrow APY is 80% when the utilization rate is 1. \n' +
  'When the utilization rate is less than or equal to 0.9, the Borrowing APY = 0.02 + 0.07 × utilization rate. When the utilization rate is greater than 0.9, the Borrowing APY = -6.37 + 7.17 × utilization rate.';


const btcEthText = 'The lowest borrow APY for this currency is 2%. \n' +
  'The borrow APY is 10% when the utilization rate is 0.8, while the borrow APY is 80% when the utilization rate is 1. \n' +
  'When the utilization rate is less than or equal to 0.8, the Borrowing APY = 0.02 + 0.1 × utilization rate. \n' +
  'When the utilization rate is greater than 0.8, the Borrowing APY = -2.7 + 3.5 × utilization rate.'

const aptText = 'The lowest borrow APY for this currency is 2%. \n' +
  'The borrow APY is 20% when the utilization rate is 0.5, while the borrow APY is 300% when the utilization rate is 1. \n' +
  'When the utilization rate is less than or equal to 0.5, the Borrowing APY = 0.02 + 0.36 × utilization rate. \n' +
  'When the utilization rate is greater than 0.8, the Borrowing APY = -2.6 + 5.6 × utilization rate.'


// export const modal = {
//   'USDC': stableCoinText,
//   'USDT': stableCoinText,
//   'Aptos Coin': aptText,
//   'ETH': btcEthText,
//   'BTC': btcEthText,
//   'SOL': stableCoinText,
// }

export const modal = {
  'USDC': 'stableCoinText',
  'USDT': 'stableCoinText',
  'lzUSDC': 'stableCoinText',
  'Aptos Coin': 'aptText',
  'ETH': 'btcEthText',
  'lzWETH': 'btcEthText',
  'BTC': 'btcEthText',
  'SOL': 'stableCoinText',
}

const base_apr = 0.02; // base borrow interest rate  #all the same
const system_fee = 0.15; // reserve interest rate  #all the same

const max_apr = 0.80; // max borrow interest rate  #not Apt
const apt_max_apr = 3.0; // max borrow interest rate  #not Apt

const stable_target_apr = 0.083; // use utilization interest rate  #stable/Apt/btc、eth
const apt_target_apr = 0.2; // use utilization interest rate  #stable/Apt/btc、eth
const btc_eth_target_apr = 0.1; // use utilization interest rate  #stable/Apt/btc、eth

const stable_target_utilization = 0.9; // use utilization stable/Apt/btc、eth
const apt_target_utilization = 0.5; // use utilization stable/Apt/btc、eth
const btc_eth_target_utilization = 0.8; // use utilization stable/Apt/btc、eth

export const modalCfg = {
  'USDC': {
    base_apr,
    target_apr: stable_target_apr,
    target_utilization: stable_target_utilization,
    max_apr,
    system_fee
  },
  'USDT': {
    base_apr,
    target_apr: stable_target_apr,
    target_utilization: stable_target_utilization,
    max_apr,
    system_fee
  },
  'lzUSDC': {
    base_apr,
    target_apr: stable_target_apr,
    target_utilization: stable_target_utilization,
    max_apr,
    system_fee
  },
  'Aptos Coin': {
    base_apr,
    target_apr: apt_target_apr,
    target_utilization: apt_target_utilization,
    max_apr: apt_max_apr,
    system_fee
  },
  'ETH': {
    base_apr,
    target_apr: btc_eth_target_apr,
    target_utilization: btc_eth_target_utilization,
    max_apr,
    system_fee
  },
  'lzWETH':{
    base_apr,
    target_apr: btc_eth_target_apr,
    target_utilization: btc_eth_target_utilization,
    max_apr,
    system_fee
  },
  'BTC': {
    base_apr,
    target_apr: btc_eth_target_apr,
    target_utilization: btc_eth_target_utilization,
    max_apr,
    system_fee
  },
  'SOL': {
    base_apr,
    target_apr: stable_target_apr,
    target_utilization: stable_target_utilization,
    max_apr,
    system_fee
  },
}

