import React from 'react';


const loginModal = {
  loginModalTitle: 'Choose a wallet',
  loginModalDescribe: 'Please DYOR before using and use at your own risk.',
  loginModalFaucet: 'Faucet'
}

const header = {
  marketOverview: 'Market Overview',
  depositWithdraw: 'Deposit/Withdraw',
  borrowRepay: 'Borrow/Repay',
  dashboard: 'Dashboard',
  Dashboard: 'Dashboard',
  Overview: 'Overview',
  faucet: 'Faucet',
  disconnect: 'Disconnect',
  connectWallet: 'Connect Wallet',
  whitePaper: 'White paper',
  feedback: 'Feedback',
  wiki: 'WIKI',
  Github: 'Github',
  Docs: 'Docs',
  'English': 'English',
  'Korean': 'Korean',
}

const indexPage = {
  ...header,
  totalDeposit: 'Total Deposit',
  Total: 'Total',
  totalBorrow: 'Total Borrowing',
  Borrowing: 'Borrowing',
  TVL: 'TVL',
  indexTableTitleMarket: 'Market',
  indexTableTitleAmount: 'Amount',
  indexTableTheadAssets: 'Asset',
  indexTableTheadDepositAPY: 'Deposit APY',
  indexTableTheadBorrowAPY: 'Borrow APY',
  indexTableTheadWalletBalance: 'Wallet Balance',
}

const rulesInfoPage = {
  back: 'Back',
  deposit: 'Deposit',
  borrow: 'Borrow',
  rulesInfoPageTab1: 'Deposit Info',
  rulesInfoPageTab2: 'Borrow Info',
  tokenPrice: 'Token Price',
  depositAPY: 'Deposit APY',
  collateralFactor: 'Collateral Factor',
  rulesInfoPageChartTitle: 'Deposit Interest Rate Model',
  interestRateModel: 'Interest Rate Model',
  interestRateModelDesc: 'Interest Rate Model',
  totalBorrowing: 'Total Borrowing',
  borrowAPY: 'Borrow APY',
  borrowFactor: 'Borrow Factor',
  borrowFee: 'Borrow Fee',
  utilizationRate: 'Utilization Rate',
  availableLiquidity: 'Available Liquidity',
  depositLimit: 'Deposit Limit',
  borrowLimit: 'Borrow Limit',
  liquidationThreshold: 'Liquidation Threshold',
  liquidationPenalty: 'Liquidation Penalty',

  stableCoinText: 'The lowest borrow APY for this currency is 2%. \n' +
    'The borrow APY is 8.3% when the utilization rate is 0.9, while the borrow APY is 80% when the utilization rate is 1. \n' +
    'When the utilization rate is less than or equal to 0.9, the Borrowing APY = 0.02 + 0.07 × utilization rate. When the utilization rate is greater than 0.9, the Borrowing APY = -6.37 + 7.17 × utilization rate.',
  btcEthText: 'The lowest borrow APY for this currency is 2%. \n' +
    'The borrow APY is 10% when the utilization rate is 0.8, while the borrow APY is 80% when the utilization rate is 1. \n' +
    'When the utilization rate is less than or equal to 0.8, the Borrowing APY = 0.02 + 0.1 × utilization rate. \n' +
    'When the utilization rate is greater than 0.8, the Borrowing APY = -2.7 + 3.5 × utilization rate.',
  aptText: 'The lowest borrow APY for this currency is 2%. \n' +
    'The borrow APY is 20% when the utilization rate is 0.5, while the borrow APY is 300% when the utilization rate is 1. \n' +
    'When the utilization rate is less than or equal to 0.5, the Borrowing APY = 0.02 + 0.36 × utilization rate. \n' +
    'When the utilization rate is greater than 0.8, the Borrowing APY = -2.6 + 5.6 × utilization rate.',
}

const dashboardPage = {
  'Accumulated income': 'Accumulated income',
  'Feature coming soon': 'Feature coming soon',
  'Accumulated borrow interest': 'Accumulated borrow interest',
  'Total debt': 'Total debt',
  'Risk Index': 'Risk Index',
  'Low Risk': 'Low Risk',
  'Medium Risk': 'Medium Risk',
  'High Risk': 'High Risk',
  'Under liquidation': 'Under liquidation',
  'Asset value': 'Asset value',
  'Deposit APY': 'Deposit APY',
  'Borrow APY': 'Borrow APY',
  'Amount': 'Amount',
  'Asset': 'Asset',
  'Debt': 'Debt',
  'Debt limit': 'Debt limit',
  'Liquidate buffer': 'Liquidate buffer',
  'Debt Details': 'Debt Details',
  'Collateral details': 'Collateral details',
  'Debt value': 'Debt value',
  'Wallet balance': 'Wallet balance',
  'Operation': 'Operation',
  'Borrow': 'Borrow',
  'Repay': 'Repay',
  'Deposit': 'Deposit',
  'Withdraw': 'Withdraw',
  'You do not have debt yet.': 'You do not have debt yet.',
  'You do not have collateral yet.': 'You do not have collateral yet.',
  'TheRiskIndexShows': 'The Risk Index shows four levels in this panel: ',
  'andUnderLiquidation': 'and Under Liquidation. ',
  'TheFollowingThreeFactors': 'The following three factors help you understand the specific risk components.\n' +
    '            1) Debt: A function of total collateralized assets and total borrowed assets, and is used to determine whether an account is under-collateralized. The higher the value, the higher the risk and the higher the probability of liquidation.\n' +
    '            2) Debt limit: A function of the total collateral assets and the theoretical maximum borrowing value, when 1) > 2), it means that your total borrowing assets value exceeds the limit, and the probability of liquidation is very high.\n' +
    '            3) Liquidate buffer: The percentage of buffer from liquidation, calculated from 1-1).'
}

const depositPage = {
  'Deposit asset to earn income.': 'Deposit asset to earn income.',
  'Total Deposit': 'Total Deposit',
  'Wallet Balance': 'Wallet Balance',
  'Show wallet assets only': 'Show wallet assets only',
  'MAX': 'MAX',
  'Pending': 'Pending',
  'Confirm': 'Confirm',
  'Value': 'Value',
  'My': 'My',
  'Available to withdraw': 'Available to withdraw',
}

const borrowPage = {
  'Users can borrow by depositing idle assets.': 'Users can borrow by depositing idle assets.',
  'Total Borrowing': 'Total Borrowing',
  'You are available to borrow': 'You are available to borrow',
  'Dynamic amount based on Oracle': 'Dynamic amount based on Oracle',
  'Your debt': 'Your debt',
  'Liquidity': 'Liquidity',
  'Utilization Rate': 'Utilization Rate',
}

export default {
  "Welcome to React": " lizi",
  ...loginModal,
  ...indexPage,
  ...rulesInfoPage,
  ...dashboardPage,
  ...depositPage,
  ...borrowPage,
}