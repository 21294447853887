// import TwiceConfirmModal from 'components/Modal/TwiceConfirmModal';
// @ts-ignore
import SubmitCompH5 from './SubmitCompH5';
// import { Input } from 'antd';
import { Actions } from 'utils/sdk.v2.js';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import back from 'assets/img/icon/back.png';

import iconDatabase from 'assets/img/icon/icon-database.png';
import iconHand from 'assets/img/icon/icon-hand.png';
// import arrowDown from 'assets/img/icon/arrow-down.png';
// import arrowUp from 'assets/img/icon/arrow-up.png';
// import { Collapse } from 'antd';
// const { Panel } = Collapse;
// const { actions , config, ENV, state } = SDK;
import { config } from 'utils/sdk.v2.js';
import btc from 'assets/img/icon/BTC.png';
// import { useModel } from '@plugin-model/useModel';
// import ModalLogin from 'components/Modal/LoginModal';
import { useNavigate, useLocation } from 'react-router-dom';
import className from 'classnames';
import { SDKContext } from 'contexts/SDKProvider';
import { openErrorNotification } from 'utils/notifications';
import { notification } from '../../components/Antd';
import { useTranslation } from 'react-i18next';


export default function RepayPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(`history: `, location);

  // @ts-ignore
  const { depositSelectListData, withdrawSelectListData, setIsTransfer, setDepositSelectListData, setRouteRepayParam, setRepaySelectListData, repaySelectListData } = useContext(SDKContext);
  const { connected, account, network, connect, signAndSubmitTransaction, ...rest } = useWallet();
  const [withdrawAmount, setWithdrawAmount] = useState<string>('');
  const [isWithdrawBtnDisable, setIsWithdrawBtnDisable] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isDisableInput, setIsDisableInput] = useState<boolean>(false);

  // let selectDepositData = depositSelectListData.find(item => item.isSelect);
  let selectRepayData = repaySelectListData?.find(item => item.isSelect);
  let withdraw_wallet_value = selectRepayData?.wallet_value;
  let underlying_amount = selectRepayData?.underlying_amount;

  // @ts-ignore
  window.repaySelectListData = repaySelectListData;

  const checkWithdrawBtn = (value) => {
    setTimeout(() => {
      if (Number(value) > 0 && Number(value) <= Number(underlying_amount)) {
        setIsWithdrawBtnDisable(false);
      } else {
        // debugger;
        setIsWithdrawBtnDisable(true);
      }
    });
  }

  const reg = /^\D*(\d*(?:\.\d{0,5})?).*$/g;
  const onChangeWithdraw = (e: any) => {
    // console.log('changed', e.target.value);
    const value = e.target.value;
    if (!value) return setWithdrawAmount('');
    if (!reg.test(value)) {
      let newValue = value.replace(reg, '$1');
      // debugger;
      setWithdrawAmount(newValue);
      return
    }
    if (Number(value) > Number(underlying_amount)) {
      // debugger;
      setWithdrawAmount(underlying_amount);
      checkWithdrawBtn(underlying_amount);
      return
    }
    setWithdrawAmount(value);
    checkWithdrawBtn(value);
  };

  const onBlurWithdraw = (e: any) => {
    let value = e.target.value;
    if (!value) return;
    if (!reg.test(value)) {
      let newValue = value.replace(reg, '$1');
      // debugger;
      setWithdrawAmount(newValue);
      return
    }
    if (Number(value) > Number(underlying_amount)) {
      // debugger;
      setWithdrawAmount(underlying_amount);
      checkWithdrawBtn(underlying_amount);
      return
    }
    if (Number(value) <= 0) {
      // debugger;
      setWithdrawAmount(underlying_amount);
      checkWithdrawBtn(underlying_amount);
      return
    }
    setWithdrawAmount(value);
    checkWithdrawBtn(value);
  }

  const onClickSetWithdrawMax = (e: any) => {
    // // console.log(`selectData: `, selectData);
    let value = Number(underlying_amount);
    // @ts-ignore
    setWithdrawAmount(underlying_amount);
    checkWithdrawBtn(value);
    if (isDisableInput) {
      setWithdrawAmount('');
    }
    setIsDisableInput(!isDisableInput);
  }

  // repay
  const onClickWithdraw = async () => {
    // @ts-ignore;
    // console.log(`Number(depositAmount ** 100000000): `, Number(withdrawAmount * 100000000));
    // debugger;
    // todo user is login and active
    try {
      // @ts-ignore
      let pending_tx = await signAndSubmitTransaction({
        type: 'entry_function_payload',
        function: `${config.LENDING}::periphery::repay`, // repay
        type_arguments: [selectRepayData.coin_type],
        // @ts-ignore
        arguments: [Number(Math.floor(withdrawAmount * Math.pow(10, selectRepayData.decimals)))?.toString()]
      })
      // let tx = await state.client.waitForTransactionWithResult(pending_tx.hash, {checkSuccess: true})
      // @ts-ignore
      let tx = await window?.loader?.client?.waitForTransactionWithResult(pending_tx.hash, { checkSuccess: true });
      // console.log(`tx: `, tx);

      // @ts-ignore
      // console.log(`window?.state: `, await window.aptos.account());
      // @ts-ignore
      // let address = await window.aptos.account();
      // @ts-ignore
      await window?.loader.reload_user_balance(account?.address);
      // @ts-ignore
      // console.log(`window?.state: `, window?.state);
      // @ts-ignore
      await loader.reload_user_coin(window?.state, selectRepayData.coin_type);
      // debugger;
      setIsTransfer(false);
      setIsModalVisible(false);
      // @ts-ignore
      // console.log(`loader: `, window?.loader);
      // debugger;
      notification.destroy();
      openErrorNotification({ type: 'success', detail: tx.vm_status });

      setTimeout(() => {
        // @ts-ignore
        window.location.reload();
      }, 3000);

    } catch (error) {
      error?.message && openErrorNotification({ detail: error.message });
      setIsTransfer(false);
    }
  }

  // repayAll
  const onClickRepayAll = async () => {
    // @ts-ignore;
    // console.log(`Number(depositAmount ** 100000000): `, Number(withdrawAmount * 100000000));
    // debugger;
    // todo user is login and active
    try {
      // @ts-ignore
      let pending_tx = await signAndSubmitTransaction({
        type: 'entry_function_payload',
        function: `${config.LENDING}::periphery::repay_all`, // repay
        type_arguments: [selectRepayData.coin_type],
        // @ts-ignore
        arguments: []
      })
      // @ts-ignore
      let tx = await window?.loader?.client?.waitForTransactionWithResult(pending_tx.hash, { checkSuccess: true });
      // console.log(`tx: `, pending_tx);
      // console.log(`tx: `, tx);
      // @ts-ignore
      // console.log(`window?.state: `, await window.aptos.account());
      // debugger;
      // @ts-ignore
      // let address = await window.aptos.account();
      // @ts-ignore
      await window?.loader.reload_user_balance(account?.address);
      // @ts-ignore
      // console.log(`window?.state: `, window?.state);
      // @ts-ignore
      await loader.reload_user_coin(window?.state, selectRepayData.coin_type);
      // debugger;
      setIsTransfer(false);
      setIsModalVisible(false);
      // @ts-ignore
      // console.log(`loader: `, window?.loader);
      notification.destroy();
      // @ts-ignore
      openErrorNotification({ type: 'success', detail: tx.vm_status });

      setTimeout(() => {
        // @ts-ignore
        window.location.reload();
      }, 3000);

    } catch (error) {
      // console.log(`error: `, error);
      debugger;
      error?.message && openErrorNotification({ detail: error.message });
      setIsTransfer(false);
    }
  }

  useEffect(() => {
    // console.log(`location: `, location);
    let name = decodeURIComponent(window.location.hash.slice(1));
    if (!name) return;
    setRouteRepayParam(name);
  }, [location])

  // console.log(`is with: `);

  // @ts-ignore
  return (
    <div className="flex md:hidden min-h-[100vh] flex-col items-center">
      <section className="w-full flex flex-col justify-start min-h-[82vh]">

        <section className="w-full ">
          <section className="px-[3%] ">

            {/* back */}
            <section className="flex" onClick={() => {
              navigate('/borrow-list');
            }}>
              <section className="h-[49px] px-[20px] text-[#31767A] flex items-center justify-center cursor-pointer border border-[#000] rounded-full hover:bg-[white]">
                <section className="w-[20px] mr-3">
                  <img src={back} alt='' className="w-full" />
                </section>
                {/*BACK*/}
                {
                  t('back')
                }
              </section>
            </section>

            {/* tabs */}
            <section className="w-full flex justify-between">
              <section className="w-full flex h-[36px] border border-[#454545] rounded-xl cursor-pointer text-sm">
                <div onClick={() => {
                  navigate(`/borrow${location.hash}`);
                  // setCurrentTab('Deposit');
                }} className={className('flex items-center justify-center w-1/2 rounded-l-xl ')}>
                  <div className="w-[15px] mr-3">
                    <img src={iconDatabase} alt='' className="w-full" />
                  </div>
                  {/*Borrow*/}
                  {
                    t('Borrow')
                  }
                </div>

                <div className={className('flex items-center justify-center w-1/2 rounded-r-xl border-l border-[#454545] bg-primeBlackTab')}>
                  <div className="w-[15px] mr-3">
                    <img src={iconHand} alt='' className="w-full" />
                  </div>
                  {/*Repay*/}
                  {
                    t('Repay')
                  }
                </div>
              </section>
            </section>


            <section className="">
              <SubmitCompH5 isDisableInput={isDisableInput}
                isModalVisible={isModalVisible}
                setIsDisableInput={setIsDisableInput}
                setIsModalVisible={setIsModalVisible}
                isBtnDisable={isWithdrawBtnDisable}
                inputValue={withdrawAmount}
                selectListData={repaySelectListData}
                onClickSetMax={onClickSetWithdrawMax}
                onBlur={onBlurWithdraw}
                setAmount={setWithdrawAmount}
                onChangeInput={onChangeWithdraw}
                onSubmitFn={isDisableInput ? onClickRepayAll : onClickWithdraw} options={{
                  // name: 'withdraw',
                  // key: '',
                  name: 'repay',
                  hoverColor: 'repayHover',
                  buttonText: 'Repay',
                  coinName: selectRepayData?.name,
                  price: selectRepayData?.price,
                  wallet_value: withdraw_wallet_value,
                  underlying_amount
                }} />
            </section>
          </section>

        </section>

      </section>

      {/*<TwiceConfirmModal visible={isModalVisible} onCancel={() => setIsModalVisible(false)} onOk={onClickDeposit} />*/}
    </div>
  );
}
