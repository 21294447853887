import TwiceConfirmModal from 'components/Modal/TwiceConfirmModal';
// @ts-ignore
import SubmitCompH5 from './SubmitCompH5';

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import back from 'assets/img/icon/back.png';

import iconDatabase from 'assets/img/icon/icon-database.png';
import iconHand from 'assets/img/icon/icon-hand.png';
import { config } from 'utils/sdk.v2.js';
import { useNavigate, useLocation } from 'react-router-dom';
import className from 'classnames';
import { SDKContext } from 'contexts/SDKProvider';
import { openErrorNotification } from 'utils/notifications';
import { notification } from '../../components/Antd';
import { decimalsCfg } from '../../config/decimals';
import { useTranslation } from 'react-i18next';


export default function BorrowH5Page() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  // console.log(`history: `, location);

  // @ts-ignore
  const { borrowSelectListData, withdrawSelectListData, setIsTransfer, setRouteBorrowParam } = useContext(SDKContext);
  const { connected, account, network, connect, signAndSubmitTransaction, ...rest } = useWallet();
  const [depositAmount, setDepositAmount] = useState<string>('');
  const [isDepositBtnDisable, setIsDepositBtnDisable] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isClickMax, setIsClickMax] = useState<boolean>(false);
  const [isDisableInput, setIsDisableInput] = useState<boolean>(false);

  let selectBorrowData = borrowSelectListData?.find(item => item.isSelect);
  // let deposit_wallet_value = selectBorrowData?.max_borrow;
  // @ts-ignore
  let deposit_wallet_value = selectBorrowData ? Math.floor(Number(window?.state?.max_to_borrow(selectBorrowData?.coin_type)?.format(selectBorrowData?.decimals)) * Math.pow(10, decimalsCfg[selectBorrowData?.name])) / Math.pow(10, decimalsCfg[selectBorrowData?.name]) : 0;
  // @ts-ignore
  window.selectBorrowData = selectBorrowData;


  const checkDepositBtn = (value) => {
    setTimeout(() => {
      if (Number(value) > 0 && Number(value) <= Number(deposit_wallet_value)) {
        // debugger;
        setIsDepositBtnDisable(false);
      } else {
        // debugger;
        setIsDepositBtnDisable(true);
      }
    });
  }

  const reg = /^\D*(\d*(?:\.\d{0,5})?).*$/g;
  const onChangeDeposit = (e: any) => {
    // console.log('changed', e.target.value);
    const value = e.target.value;
    setIsClickMax(false);
    if (!value) return setDepositAmount('');
    if (!reg.test(value)) {
      let newValue = value.replace(reg, '$1');
      // debugger;
      setDepositAmount(newValue);
      return
    }
    if (Number(value) > Number(deposit_wallet_value)) {
      debugger;
      setDepositAmount(String(deposit_wallet_value));
      checkDepositBtn(deposit_wallet_value);
      return
    }
    setDepositAmount(value);
    checkDepositBtn(value);
  };

  const onBlurDeposit = (e: any) => {
    const value = e.target.value;
    if (!value) return;
    if (!reg.test(value)) {
      let newValue = value.replace(reg, '$1');
      // debugger;
      setDepositAmount(newValue);
      return
    }
    if (Number(value) > Number(deposit_wallet_value)) {
      // debugger;
      setDepositAmount(String(deposit_wallet_value));
      checkDepositBtn(deposit_wallet_value);
      return
    }
    if (Number(value) <= 0) {
      // debugger;
      setDepositAmount(String(deposit_wallet_value));
      checkDepositBtn(deposit_wallet_value);
      return
    }
    setDepositAmount(value);
    checkDepositBtn(value);
  }

  const onClickSetDepositMax = (e: any) => {
    // console.log(`selectData: `, selectBorrowData);
    let value = Number(deposit_wallet_value);
    // debugger;
    if (selectBorrowData.name === 'BTC') {
      // @ts-ignore
      setDepositAmount(String(value?.toFixed(4)));
    } else {
      // @ts-ignore
      setDepositAmount(String(value?.toFixed(2)));
    }
    // @ts-ignore
    // setDepositAmount(selectBorrowData?.max_borrow);
    checkDepositBtn(value);
    setIsClickMax(true);
  }

  const onClickDeposit = async () => {
    // @ts-ignore
    // console.log(`Number(depositAmount * 100000000).toString(): `, Number(Math.floor(depositAmount * Math.pow(10, selectBorrowData.decimals)))?.toString());
    // console.log(`selectBorrowData.coin_type: `, selectBorrowData.coin_type);
    // debugger;
    // todo user is login and active
    try {
      // @ts-ignore
      // await actions.lending_pledge('0x1::aptos_coin::AptosCoin', Number(withdrawAmount * 100000000));
      let pending_tx = await signAndSubmitTransaction({
        type: 'entry_function_payload',
        function: `${config.LENDING}::periphery::borrow`, // deposit
        type_arguments: [selectBorrowData.coin_type],
        // @ts-ignore
        arguments: [Number(Math.floor(depositAmount * Math.pow(10, selectBorrowData.decimals)))?.toString()]
      })
      // let tx = await state.client.waitForTransactionWithResult(pending_tx.hash, {checkSuccess: true});
      // @ts-ignore
      let tx = await window?.loader?.client?.waitForTransactionWithResult(pending_tx.hash, { checkSuccess: true });
      // console.log(`tx: `, tx);

      // @ts-ignore
      // console.log(`window?.state: `, await window.aptos.account());
      // @ts-ignore
      // let address = await window.aptos.account();
      // @ts-ignore
      await window?.loader.reload_user_balance(account?.address);
      // @ts-ignore
      // console.log(`window?.state: `, window?.state);
      // @ts-ignore
      await loader.reload_user_coin(window?.state, selectBorrowData?.coin_type);
      // debugger;
      setIsTransfer(false);
      setIsModalVisible(false);
      // @ts-ignore
      // console.log(`loader: `, window?.loader);
      // debugger;
      notification.destroy();
      openErrorNotification({ type: 'success', detail: tx.vm_status });

      setTimeout(() => {
        // @ts-ignore
        window.location.reload();
      }, 3000);
    } catch (error) {
      // console.log(`e: `, error);
      debugger;
      error?.message && openErrorNotification({ detail: error.message });
      setIsTransfer(false);
    }
  }

  useEffect(() => {
    // console.log(`location: `, location);
    let name = decodeURIComponent(window.location.hash.slice(1));
    if (!name) return;
    setRouteBorrowParam(name);
  }, [location])

  // console.log(`is deposit: `);

  // @ts-ignore
  return (
    <div className="w-full  min-h-[100vh] flex md:hidden flex-col items-center">
      <section className="w-full flex flex-col justify-start min-h-[90vh]">

        <section className="">
          <section className=" px-[3%]">

            {/* back */}
            <section className="flex" onClick={() => {
              navigate('/borrow-list');
            }}>
              <section className="h-[49px] px-[20px] text-[#31767A] flex items-center justify-center cursor-pointer border border-[#000] rounded-full hover:bg-[white]">
                <section className="w-[20px] mr-3">
                  <img src={back} alt='' className="w-full" />
                </section>
                {/*BACK*/}
                {
                  t('back')
                }
              </section>
            </section>


            {/* tabs */}
            <section className="w-full flex justify-between mt-[20px]">
              <section className="w-full flex h-[36px] border border-[#454545] rounded-xl cursor-pointer text-sm">
                <div className={className('flex items-center justify-center w-1/2 rounded-l-xl bg-primeBlackTab')}>
                  <div className="w-[15px] mr-3">
                    <img src={iconDatabase} alt='' className="w-full" />
                  </div>
                  {
                    t('Borrow')
                  }
                </div>

                <div onClick={() => {
                  navigate(`/repay${location.hash}`);
                  // setCurrentTab('Withdraw');
                }} className={className('flex items-center justify-center w-1/2 rounded-r-xl border-l border-[#454545] ')}>
                  <div className="w-[15px] mr-3">
                    <img src={iconHand} alt='' className="w-full" />
                  </div>
                  {
                    t('Repay')
                  }
                </div>
              </section>
            </section>

            <section className=''>
              <SubmitCompH5
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                setIsDisableInput={setIsDisableInput}
                isBtnDisable={isDepositBtnDisable}
                inputValue={depositAmount}
                selectListData={borrowSelectListData}
                onClickSetMax={onClickSetDepositMax}
                isClickMax={isClickMax}
                onBlur={onBlurDeposit}
                setAmount={setDepositAmount}
                onChangeInput={onChangeDeposit}
                onSubmitFn={onClickDeposit} options={{
                  // name: 'deposit',
                  // key: '',
                  name: 'borrow',
                  hoverColor: 'borrowHover',
                  buttonText: 'Borrow',
                  coinName: selectBorrowData?.name,
                  price: selectBorrowData?.price,
                  wallet_value: deposit_wallet_value,
                }} />
            </section>
          </section>
        </section>
      </section>

      {/*<TwiceConfirmModal visible={isModalVisible} onCancel={() => setIsModalVisible(false)} onOk={onClickDeposit} />*/}
    </div>
  );
}
