import className from 'classnames';
// import 'antd/dist/antd.css';
// import { Input } from 'antd';
import React, { useContext, useMemo, useState } from 'react';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import back from 'assets/img/icon/back.png';
// import { history } from 'umi';
import { SDKContext } from 'contexts/SDKProvider';
import iconDatabase from 'assets/img/icon/icon-database.png';
import iconHand from 'assets/img/icon/icon-hand.png';
import arrowDown from 'assets/img/icon/arrow-down.png';
import arrowUp from 'assets/img/icon/arrow-up.png';
import btc from 'assets/img/icon/BTC.png';
import borrow from 'assets/img/title/borrow.png';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import { iconsCfg } from 'config/decimals';
import { useTranslation } from 'react-i18next';
import ListH5 from './ListH5';

// // // console.log(`history.location: `, history.location);
// // // console.log(`history.location: `, history.location.query.tab);

export default function DepositPage() {
  // const { globalState, setGlobalState, tableData, setTableData, } = useModel('bank');
  const { t } = useTranslation();
  const navigate = useNavigate();
  // @ts-ignore
  const { globalState, indexPageTableData, setIndexPageTableData, appLoading } = useContext(SDKContext);
  const [currentUnit, setCurrentUnit] = useState<String>(indexPageTableData.every(item => item.isShowUSD) ? 'usd' : 'amount');
  const { connected, account, network, connect, ...rest } = useWallet();
  const [depositAmount, setDepositAmount] = useState<String>('');
  const [withdrawAmount, setWithdrawAmount] = useState<String>('');

  const onClickTab = (tab) => {
    if (tab === 0) {
      setCurrentUnit('usd');
      let newData = indexPageTableData.map(item => {
        item.isShowUSD = true;
        return item;
      });
      setIndexPageTableData(newData);
    } else {
      let newData = indexPageTableData.map(item => {
        item.isShowUSD = false;
        return item;
      });
      // console.log(`newData: `, newData);
      setCurrentUnit('amount');
      setIndexPageTableData(newData);
    }
  }

  // console.log(`indexPageTableData: `, indexPageTableData);

  return (
    <>
      <ListH5 />
      <div className="hidden lg:flex min-h-[100vh] flex-col items-center">
        <section className="flex flex-col justify-start min-h-[100vh]">

          <section className="w-full lg:w-[1024px] xl:w-[1280px]">

            <section className="flex justify-between h-[191px]">
              <section className="w-full lg:w-[1024px] xl:w-[1280px] flex flex-col">
                <section className="text-[48px] font-['BioRhyme'] mt-[40px] mb-[10px] h-[75px]  w-[500px]">
                  <img src={borrow} alt='' className="w-full" />
                </section>

                <section className=" text-[16px] font-['BioRhyme'] mb-[40px] text-[#B4AFAE]">
                  {/*Users can borrow by depositing idle assets.*/}
                  {
                    t('Users can borrow by depositing idle assets.')
                  }
                </section>
              </section>

              <section className="flex items-center">
                <div className="border border-[#161616] p-2 rounded-xl relative w-[394px] h-[114px]">
                  <div className="bg-[#34A7AE] absolute left-0 bottom-0 h-[4px] w-[92%] left-[50%] ml-[-46%] blur z-0" />

                  <div className="flex flex-col rounded-xl justify-end items-end border border-[#454545] h-[109px] pr-[16px]  pb-[16px] bg-[#292d2ef7]">
                    <div className="text-2xl pb-[8px]">
                      $ {globalState?.total_borrowed_value ? parseInt(globalState?.total_borrowed_value?.format(9)) : '-'}
                    </div>
                    <div className="text-[#BBBBBB]">
                      {/*Total Borrowing*/}
                      {
                        t('Total Borrowing')
                      }
                    </div>
                  </div>
                </div>
              </section>

            </section>
            
            {/* tabs */}
            <div className="flex  w-[156px] p-[2px] bg-[#404647] rounded-full select-none text-[12px]">
              <div className="hidden text-[#31767A]" />
              <div onClick={() => {
                onClickTab(0);
                // setCurrentUnit('usd');
              }} className={className('flex justify-center items-center w-[78px] py-[8px] rounded-full cursor-pointer text-[#B4AFAE] ', currentUnit === 'usd' ? ' !text-[#31767A] bg-[#fff]' : ' hover:text-[#fff]')}>
                <span>
                  USD
                </span>
              </div>
              <div onClick={() => {
                onClickTab(1);
                // setCurrentUnit('amount');
              }} className={className('flex justify-center items-center w-[78px] py-[8px] rounded-full cursor-pointer text-[#B4AFAE] ', currentUnit === 'amount' ? ' !text-[#31767A] bg-[#fff] ' : ' hover:text-[#fff]')}>
                <span>
                  {
                    t('Amount')
                  }
                </span>
              </div>
            </div>

            <section className="mt-[25px] mb-[100px]">

              <div
                className=""
              >
                <table
                  className=" w-full lg:w-[1024px] xl:w-[1280px] border-separate"
                  style={{
                    borderSpacing: '0',
                  }}
                >
                  <thead className={`h-[53px] text-[18px]`}>
                  <tr className={`text-left bg-[#25282A] text-center`}>
                    <th className="px-4 py-2 text-[#E4E4E4]  font-light rounded-tl-xl">
                      {/*Asset*/}
                      {
                        t('Asset')
                      }
                    </th>
                    <th className="px-4 py-2 text-[#E4E4E4]  font-light">
                      {/*Total Deposit*/}
                      {
                        t('Total Deposit')
                      }
                    </th>
                    <th className="px-4 py-2 text-[#E4E4E4]  font-light">
                      {/*Liquidity*/}
                      {
                        t('Liquidity')
                      }
                    </th>
                    <th className="px-4 py-2 text-[#E4E4E4]  font-light">
                      {/*Borrow APY*/}
                      {
                        t('Borrow APY')
                      }
                    </th>
                    <th className="px-4 py-2 text-[#E4E4E4]  font-light">
                      {
                        t('Utilization Rate')
                      }
                    </th>
                    <th className="px-4 py-2 text-[#E4E4E4]  font-light rounded-tr-xl">
                      {
                        t('Operation')
                      }
                    </th>
                  </tr>
                  </thead>
                  <tbody className=" bg-[#1D2021]" style={{}}>

                  {
                    indexPageTableData.map((item, i) => {

                      return (
                        <tr onClick={() => {}} className={'group h-[53px] text-center '} key={item?.name}>
                          <td className={className(' font-black text-[16px]  font-medium   border-b-[red] px-4 py-3', '',
                            i === (indexPageTableData?.length - 1) ? 'rounded-bl-xl' : ''
                          )} style={{
                            borderBottom: i !== (indexPageTableData?.length - 1) ? '2px solid #2F3132' : '0'
                          }}>
                            <div className='flex items-center '>
                              <img className="px-3" src={iconsCfg?.[item.name]} alt='' />
                              {item.name}
                            </div>
                            {/*{item?.name}*/}
                          </td>

                          <td className={className('  font-black text-[16px]  font-medium px-4 py-3', '')}
                              style={{
                                borderBottom: i !== (indexPageTableData?.length - 1) ? '2px solid #2F3132' : '0'
                              }}>
                            {/*{parseInt(item?.deposited_value)}*/}
                            { item?.isShowUSD ? `$${Number(item?.deposited_value)?.toFixed(2)}` : Number(item?.deposited_amount)?.toFixed(2) }
                          </td>

                          <td className={className('  font-black text-[16px]  font-medium   px-4 py-3', '')} style={{
                            borderBottom: i !== (indexPageTableData?.length - 1) ? '2px solid #2F3132' : '0'
                          }}>
                            { item?.isShowUSD ? `$${Number(String(item?.available_liquidity * item?.price))?.toFixed(2)}` : Number(item?.available_liquidity)?.toFixed(2) }

                          </td>

                          <td className={className('  font-black text-[16px]  font-medium  px-4 py-3', '')} style={{
                            borderBottom: i !== (indexPageTableData?.length - 1) ? '2px solid #2F3132' : '0'
                          }}>
                            <p className="text-[#4AFCDC]">
                              {item?.current_borrow_apr}
                            </p>
                          </td>

                          <td className={className('  font-black text-[16px]  font-medium   px-4 py-3', '')} style={{
                            borderBottom: i !== (indexPageTableData?.length - 1) ? '2px solid #2F3132' : '0'
                          }}>
                            {item?.current_utilization}
                          </td>

                          <td className={className('font-black text-[16px]  font-medium divide-y divide-red', 'px-4 py-3', ' ',
                            i === (indexPageTableData?.length - 1) ? 'rounded-br-xl ' : ''
                          )} style={{
                            borderBottom: i !== (indexPageTableData?.length - 1) ? '2px solid #2F3132' : '0'
                          }}>
                            <div className="flex justify-center cursor-pointer">
                              <div className="w-[130px] text-[#8ECE5B] bg-[#2C3327] hover:bg-[#262924] rounded-tl-full rounded-bl-full px-[30px] py-2" onClick={() => {
                                navigate(`/borrow#${item?.name}`);
                              }}>
                                {/*Borrow*/}
                                {
                                  t('Borrow')
                                }
                              </div>
                              <div>&nbsp;</div>
                              <div className="w-[130px] text-[#E27860] bg-[#352A28] hover:bg-[#2A2524] rounded-tr-3xl rounded-br-3xl px-[30px] py-2 cursor-pointer"  onClick={() => {
                                navigate(`/repay#${item?.name}`);
                              }}>
                                {/*Repay*/}
                                {
                                  t('Repay')
                                }
                              </div>
                            </div>
                          </td>

                        </tr>
                      );
                    })
                  }

                  </tbody>
                </table>

                {
                  <Skeleton active round loading={appLoading || !indexPageTableData?.length} className="my-6" />
                }
              </div>
            </section>

          </section>

        </section>
        {/*<Footer />*/}
      </div>
    </>
  );
}
