import React, { useState, useEffect, useCallback, useRef } from 'react';

import classNames from 'classnames';
import { Modal, ModalProps } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
// @ts-ignore
import scss from './index.scss';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import { iconsCfg } from 'config/decimals'
import { notification } from '../../Antd';
import { openErrorNotification } from 'utils/notifications';

interface ModalLoginProps extends ModalProps {
  onOk?: () => void;
  onCancel?: () => void;
}

const CONTRACT_NAME = '0xee3a4ca535844eb1cb6f7520c4fc4abbbe9ea8b459005a47bb848516cfd71611';
const faucetList = [{
  name: 'USDC',
  coin_type: `${CONTRACT_NAME}::test_coin::USDC`,
  amount: Number(Math.floor(100 * Math.pow(10, 9)))?.toString()
}, {
  name: 'ETH',
  coin_type: `${CONTRACT_NAME}::test_coin::ETH`,
  amount: Number(Math.floor(Math.pow(10, 9)))?.toString()
},{
  name: 'BTC',
  coin_type: `${CONTRACT_NAME}::test_coin::BTC`,
  amount: Number(Math.floor(0.01 * Math.pow(10, 9)))?.toString()
}];

export default React.memo<ModalLoginProps>((props) => {
  const { visible, onOk, onCancel } = props;
  const { account, signAndSubmitTransaction } = useWallet();

  // // console.log(`wallet: `, wallet);
  // debugger;

  const onClickItem = async (item) => {

    // @ts-ignore
    if (!window?.state?.user_coins?.[item?.coin_type]) {
      console.log(`here: `);
      debugger;
      await signAndSubmitTransaction({
        type: 'entry_function_payload',
        function: `0x1::managed_coin::register`, // deposit
        type_arguments: [item.coin_type],
        // @ts-ignore
        arguments: []
      })
    }

    try {
      // @ts-ignore
      let pending_tx = await signAndSubmitTransaction({
        type: 'entry_function_payload',
        function: `${CONTRACT_NAME}::test_coin::fund`, // deposit
        type_arguments: [item.coin_type],
        // @ts-ignore
        arguments: [item?.amount]
      })
      // @ts-ignore
      let tx = await window?.loader?.client?.waitForTransactionWithResult(pending_tx.hash, {checkSuccess: true});

      // @ts-ignore
      // console.log(`window?.state: `, await window.aptos.account());
      // @ts-ignore
      // let address = await window.aptos.account();
      // @ts-ignore
      await window?.loader.reload_user_balance(account?.address);
      // @ts-ignore
      // console.log(`window?.state: `, window?.state);
      // @ts-ignore
      await loader.reload_user_coin(item?.coin_type);
      // debugger;
      // setIsTransfer(false);
      onCancel();
      // @ts-ignoreu
      // console.log(`loader: `, window?.loader);
      // debugger;
      notification.destroy();
      openErrorNotification({ type: 'success', detail: tx.vm_status });

      setTimeout(() => {
        // @ts-ignore
        // window.location.reload();
      }, 3000);
    } catch (error) {
      // debugger;
      error?.message && openErrorNotification({ detail: error.message });
    }
  };

  return (
    // <div className={classNames('loginModal', scss.login_container)}>
      <Modal
        wrapClassName={classNames('loginModal', scss.login_container)}
        open={visible}
        centered
        title={
          <>
            <span>Faucet</span>
          </>
        }
        onOk={onOk}
        onCancel={onCancel}
        footer={null}
      >
        <div className="w-full flex justify-center flex-col items-center" >
          {
            faucetList.map(item => {
              return (
                <div onClick={async () => {
                  await onClickItem(item);
                }}
                    key={item.name} className="hover:border-[#fff] w-[388px] h-[50px] my-3 rounded-full text-white border border-[#363839] flex items-center justify-center cursor-pointer">
                  <div className="w-[30px] h-[30px] rounded-xl mr-3">
                    <img className="rounded-xl w-full h-full" src={iconsCfg?.[item.name]} alt='' />
                  </div>
                  <div className="text-[14px] text-[#fff]">{ item.name } faucet</div>
                </div>
              )
            })
          }
        </div>

      </Modal>
    // </div>
  );
});
