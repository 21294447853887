// import Header from 'components/Header/index';
// import Footer from 'components/Footer/index';
import className from 'classnames';

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import back from 'assets/img/icon/back.png';
import iconDatabase from 'assets/img/icon/icon-database.png';
import iconHand from 'assets/img/icon/icon-hand.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { SDKContext } from '../../contexts/SDKProvider';
import { decimalsCfg, iconsCfg } from 'config/decimals';
import { modal, modalCfg } from 'config/interestRateModel';

import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  ToolboxComponent,
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useTranslation } from 'react-i18next';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
  ToolboxComponent
]);




export default function RulesInfoPage() {
  // const { globalState, rulesInfoData } = useModel('bank');
  const { t } = useTranslation();
  // @ts-ignore
  const { globalState, rulesPageData } = useContext(SDKContext);
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(`location: `, location);
  // debugger;
  const coinName = decodeURIComponent(location.search.split('name=')[1]);
  const [currentTab, setCurrentTab] = useState<string>('Deposit');
  const { connected, account, network, connect, ...rest } = useWallet();

  let data = rulesPageData?.find(item => item.name === coinName);
  // @ts-ignore
  window.rulesPageData = data;


  return (
    <section className="w-full block lg:hidden mb-12 min-h-[100vh]">
      <section className='flex w-full pb-[12vh]'>
        <section className='flex w-full px-[6%] justify-between '>

          {/* back */}
          <section className="flex" onClick={() => {
            navigate(-1);
          }}>
            <section className="h-[49px]  text-[#31767A] flex items-center justify-center cursor-pointer border border-[#000] rounded-full hover:bg-[white]">
              <section className="w-[20px] mr-3">
                <img src={back} alt='' className="w-full" />
              </section>
              {
                t('back')
              }
            </section>
          </section>

          <section className=" flex items-center">
            <img src={iconsCfg[coinName]} alt='' className="mr-2" />
            {coinName}
          </section>
        </section>
      </section>

      <section className="px-[3%] py-[20px]">
        <section className="flex h-[36px] border border-[#454545] rounded-xl cursor-pointer text-[16px]">
          <div onClick={() => {
            setCurrentTab('Deposit');
          }} className={className('flex items-center justify-center w-1/2 rounded-l-xl ', currentTab === 'Deposit' ? 'bg-primeBlackTab' : '')}>
            <div className="w-[15px] mr-3">
              <img src={iconDatabase} alt='' className="w-full" />
            </div>
            {
              t('rulesInfoPageTab1')
            }
          </div>

          <div onClick={() => {
            setCurrentTab('Borrow');
            // initBorrowChart();
          }} className={className('flex items-center justify-center w-1/2 rounded-r-xl border-l border-[#454545] ', currentTab === 'Borrow' ? 'bg-primeBlackTab' : '')}>
            <div className="w-[15px] mr-3">
              <img src={iconHand} alt='' className="w-full" />
            </div>
            {
              t('rulesInfoPageTab2')
            }
          </div>
        </section>
      </section>

      {/* deposit info */}
      <section className="px-[3%] ">
        <section className={className('rounded-lg my-6 border border-[#212526] ', currentTab === 'Deposit' ? '' : 'hidden')}>
          <section className="bg-primeBlack px-[6%] py-[24px] flex justify-between rounded-lg">
            <div className='flex flex-col '>
              <div className="mb-[15px]">
                {
                  t('tokenPrice')
                }
              </div>
              <div className="mb-[15px]">
                {
                  t('totalDeposit')
                }
              </div>
              <div className="mb-[15px]">
                {
                  t('depositAPY')
                }
              </div>
              <div className="mb-[15px]">
                {
                  t('collateralFactor')
                }
              </div>
            </div>

            <div className='flex flex-col  '>
              <div className="mb-[15px]">
                {
                  data?.price && `$ ${data?.price}`
                }
              </div>
              <div className="mb-[15px]">
                {
                  data?.deposited_value ? `$ ${Number(data?.deposited_value)?.toFixed(2)}` : '-'
                }
              </div>
              <div className="mb-[15px]">
                {
                  data?.current_supply_apr
                }
              </div>
              <div className="mb-[15px]">
                {
                  data?.df ? Number(data?.df)?.toFixed(2) : '-'
                }
              </div>
            </div>
          </section>
        </section>
      </section>

      {/* Borrow info */}
      <section className="px-[3%] ">
        <section className={className('rounded-lg my-6 border border-[#212526] ', currentTab === 'Borrow' ? '' : 'hidden')}>
          <section className="bg-primeBlack px-[30px] py-[44px] flex justify-between rounded-lg">
            <div className='flex flex-col  '>

              <div className="mb-[15px]">
                {
                  t('totalBorrowing')
                }
              </div>
              <div className="mb-[15px]">
                {
                  t('borrowAPY')
                }
              </div>
              <div className="mb-[15px]">
                {
                  t('borrowFactor')
                }
              </div>
              <div className="mb-[15px]">
                {
                  t('borrowFee')
                }
              </div>
              <div className="mb-[15px]">
                {
                  t('utilizationRate')
                }
              </div>
              <div className="mb-[15px]">
                {
                  t('availableLiquidity')
                }
              </div>
              <div className="mb-[15px]">
                {
                  t('depositLimit')
                }
              </div>
              <div className="mb-[15px]">
                {
                  t('borrowLimit')
                }
              </div>
              <div className="mb-[15px]">
                {
                  t('liquidationThreshold')
                }
              </div>
              <div className="mb-[15px]">
                {
                  t('liquidationPenalty')
                }
              </div>
            </div>

            <div className='flex flex-col  '>
              <div className="mb-[15px]">
                {
                  data?.borrowed_value ? `$ ${parseInt(data?.borrowed_value)}` : '-'
                }
              </div>
              <div className="mb-[15px]">
                {
                  data?.current_borrow_apr
                }
              </div>
              <div className="mb-[15px]">
                {
                  data?.bf ? Number(data?.bf)?.toFixed(2) : '-'
                }
              </div>
              <div className="mb-[15px]">
                {
                  data?.borrow_fee ? (data?.borrow_fee * 100)?.toFixed(2) + '%' : '-'
                }
              </div>
              <div className="mb-[15px]">
                {
                  data?.current_utilization
                }
              </div>
              <div className="mb-[15px]">
                {
                  data?.available_liquidity ? Number(data?.available_liquidity)?.toFixed(2) : '-'
                }
              </div>
              <div className="mb-[15px]">
                {
                  (data?.pledge_limit && data?.pledge_limit !== '-') ? `${parseInt(data?.pledge_limit)}` : '-'
                }
              </div>
              <div className="mb-[15px]">
                {
                  (data?.borrow_limit && data?.borrow_limit !== '-') ? `${parseInt(data?.borrow_limit)}` : '-'
                }
              </div>
              <div className="mb-[15px]">
                {
                  data?.liquidation_Threshold ? Number(data?.liquidation_Threshold)?.toFixed(2) : '-'
                }
              </div>

              <div className="mb-[15px]">
                {
                  // @ts-ignore
                  data?.ld ? parseInt((Number(data?.ld)?.toFixed(2) - 1) * 100) + '%' : '-'
                }
              </div>

            </div>
          </section>
        </section>
      </section>

      <section className="px-[3%] w-full pb-[100px] hidden">
        <section className=" w-full bg-primeBlack px-[10px] py-[14px] rounded-lg mb-6">

          <div className={`flex justify-between relative`}>
            <div className="text-[#E4E4E4] text-[14px] pl-[6%] absolute left-0 top-[10px]">
              {currentTab === 'Borrow' ? t('borrow') : t('deposit')}
              {
                t('interestRateModel')
              }
            </div>

          </div>

          <div id="borrowModalMobile" style={{
          }} className={className('w-[88vw] h-[280px]', currentTab === 'Borrow' ? '' : 'hidden')} />

          <div id="depositModalMobile" className={className('w-[88vw] h-[280px]', currentTab === 'Deposit' ? '' : 'hidden')} />
        </section>
      </section>

      <section className="w-full px-[3%] bg-black py-2 fixed left-0 bottom-0">
        <div className="hidden bg-[#8ECE5B99] bg-[#56D7DF99]" />
        <div onClick={() => {
          currentTab === 'Borrow' ? navigate(`/borrow#${coinName}`) : navigate(`/deposit#${coinName}`)
        }} className={` w-full h-[48px]   flex justify-center items-center rounded-full ${
          currentTab === 'Borrow' ? 'bg-[#8ECE5B99]' : 'bg-[#56D7DF99]'
        }`}>
          {currentTab === 'Borrow' ? t('borrow') : t('deposit')}
        </div>
      </section>

    </section>
  );
}
