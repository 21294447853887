
import className from 'classnames';
import TwiceConfirmModal from 'components/Modal/TwiceConfirmModal';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useWallet } from '@manahippo/aptos-wallet-adapter';

// import iconDatabase from 'assets/img/icon/icon-database.png';
// import iconHand from 'assets/img/icon/icon-hand.png';
import arrowDown from 'assets/img/icon/arrow-down.png';
import arrowUp from 'assets/img/icon/arrow-up.png';
// import { Collapse } from 'antd';
// const { Panel } = Collapse;

import { iconsCfg, decimalsCfg } from 'config/decimals';
// import btc from 'assets/img/icon/BTC.png';
// import { useModel } from '@plugin-model/useModel';
// import ModalLogin from 'components/Modal/LoginModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { SDKContext } from 'contexts/SDKProvider';
import { useTranslation } from 'react-i18next';

interface SubmitCompProps {
  // options: {
  //   // name: string,
  //   // key: string,
  //   name: string,
  //   hoverColor: string,
  //   buttonText: string,
  //   coinName: string,
  //   price: string,
  //   wallet_value: string,
  // };
  options: any;
  onSubmitFn: (string) => void;
  setIsDisableInput?: (e) => void;
  setIsModalVisible: (e) => void;
  onChangeInput: (e) => void;
  setAmount: (e) => void;
  onClickSetMax: (e) => void;
  onBlur: (e) => void;
  inputValue: string;
  isBtnDisable: boolean;
  isClickMax?: boolean;
  isModalVisible: boolean;
  isDisableInput?: boolean;
  selectListData: any;
  // onCancel?: () => void;
}

const buttonList = ['borrow', 'repay']

export default function SubmitComp(props: SubmitCompProps) {
  const { onSubmitFn, options, onChangeInput, setAmount, onBlur, isClickMax, inputValue, isDisableInput, isBtnDisable, selectListData, setIsDisableInput, onClickSetMax, setIsModalVisible, isModalVisible } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  // console.log(`history: `, location);

  // @ts-ignore
  const { globalState, dashboardPageData, depositSelectListDataHasCoin, setDepositSelectListDataHasCoin, setRepaySelectListData, setBorrowSelectListData } = useContext(SDKContext);
  // const [currentTab, setCurrentTab] = useState<String>(  location.search === '?tab=1' ? 'Withdraw': 'Deposit');
  const { connected, account, network, connect, ...rest } = useWallet();

  // // const [depositAmount, setDepositAmount] = useState<string>('');
  // const [withdrawAmount, setWithdrawAmount] = useState<string>('');
  // // const [activeKey, setActiveKey] = useState<Array<string>>(['0']);
  // const [ isModalVisible, setIsModalVisible ] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [ isOnlyShowWalletCoin, setIsOnlyShowWalletCoin ] = useState<boolean>(false);
  const [modalData, setModalData] = useState({
    inputValue,
    ...options
  });

  const {
    userCoinInfo,
    userDebtInfo,
    userDepositInfo,
  } = dashboardPageData;

  let currentSelect = selectListData?.find(item => item.isSelect);
  let currentBf = userDepositInfo?.find(item => item.name === currentSelect?.name)?.bf || globalState?.lending_pools?.[currentSelect?.coin_type]?.config?.debt_factor?.format(9) || 0;

  const onClickSelect = (index, j = 0) => {
    let newPoolCoinList = [].slice.call(selectListData);
    let tmp = newPoolCoinList[index];
    tmp.isSelect = true;
    newPoolCoinList[j].isSelect = false;
    newPoolCoinList[index] = newPoolCoinList[j];
    newPoolCoinList[j] = tmp;
    // console.log(`poolCoinList: `, newPoolCoinList);
    if (options.name === 'borrow') {
      setBorrowSelectListData(newPoolCoinList);
    } else {
      setRepaySelectListData(newPoolCoinList);
    }
    setIsOpen(false);
    setAmount('');
    setIsDisableInput(false);
    // @ts-ignore
    window.location.hash = tmp.name;
  }

  let user_balance = globalState?.user_balance;
  let originRiskIndex = user_balance?.total_vdebts_value?.format(9) / user_balance?.max_vdebts_value?.format(9) || 0;
  let riskIndex = (
    options.name === 'borrow'
      ? Number(user_balance?.total_vdebts_value?.format(9)) + Number(inputValue) * Number(currentBf) * Number(currentSelect?.price)
      : Number(user_balance?.total_vdebts_value?.format(9)) - Number(inputValue) * Number(currentBf) * Number(currentSelect?.price)
  ) / user_balance?.max_vdebts_value?.format(9) || 0;
  // debugger;
  // // @ts-ignore
  // window.currentSelect = currentSelect;
  // // @ts-ignore
  // window.currentBf = currentBf;
  // console.log(`originRiskIndex: `, originRiskIndex, riskIndex);

  const onClickSetData = (index) => {
    switch (index) {
      case buttonList[0]:
        // console.log(`modalData: `, modalData);
        // debugger;
        setModalData({
          ...modalData,
          ...options,
          inputValue,
          originRiskIndex,
          riskIndex,
        });
        break;
      case buttonList[1]:
        setModalData({
          ...modalData,
          ...options,
          inputValue,
          originRiskIndex,
          riskIndex,
        });
        break;
    }
  }

  // console.log(`riskIndex: options.name `, options);
  // console.log(`riskIndex: price `, Number(currentSelect?.price));
  // console.log(`riskIndex: currentBf `, currentBf);
  // console.log(`riskIndex: `, Number(user_balance?.total_vdebts_value?.format(9)) - Number(inputValue) * Number(currentBf) * Number(currentSelect?.price), user_balance?.max_vdebts_value?.format(9));
  // console.log(`riskIndex: `, riskIndex);

  return (
    <>
      {
        buttonList.map(button => {
          return (
            <section key={button} className={options.name === button ? '' : 'hidden'}>
              <div className={className(`bg-[#242829] bg-deposit group-hover:bg-deposit group-focus:bg-deposit hover:bg-deposit focus:bg-deposit bg-depositHover hover:bg-depositHover  hidden`)} />
              <div className={className(`bg-withdraw group-hover:bg-withdraw group-focus:bg-withdraw hover:bg-withdraw focus:bg-withdraw bg-withdrawHover hover:bg-withdrawHover bg-withdrawHover  hidden`)} />
              <div className={className(`bg-borrow group-hover:bg-borrow group-focus:bg-borrow hover:bg-borrow focus:bg-borrow bg-borrowHover hover:bg-borrowHover hidden`)} />
              <div className={className(`bg-repay group-hover:bg-repay group-focus:bg-repay hover:bg-repay focus:bg-repay bg-repayHover hover:bg-repayHover text-repay  text-borrow text-repayhidden`)} />
              <div className={className(`bg-[#404647] text-[#535859]  hidden`)} />

              <div className={className('flex items-center select-none w-full h-[36px] my-[26px] bg-primeBlack rounded-2xl text-sm')}>
                <span className="px-[3%] cursor-pointer" >
                  {/*<span className={className(`text-${options.name}`)}></span>*/}
                  {options.name === 'borrow' ? (
                    <div>
                      {
                        t('You are available to borrow')
                      } <span className={className(`text-${options.name}`)}>
                        {currentSelect?.max_borrow}
                      </span>
                      &nbsp;
                      {selectListData?.[0]?.name}
                      <span className={className('', !isClickMax && 'hidden')}>
                        &nbsp;( {t('Dynamic amount based on Oracle')} )
                      </span>
                    </div>
                  ) :
                    <div>
                      {t('Your debt')} <span className={className(`text-${options.name}`)}>{selectListData?.[0]?.underlying_amount} </span> {selectListData?.[0]?.name}
                    </div>
                  }
                </span>
              </div>

              <section className="w-full min-h-[248px] mb-[60px] bg-primeBlack my-[16px] rounded-2xl">
                <section className='py-[14px] px-[3%]'>
                  <section className={className(`overflow-hidden my-3`)} style={{
                    height: isOpen ? `${selectListData?.length * 60}px` : '60px',
                    transition: `all .13s linear`
                  }}>
                    {
                      selectListData?.map((item, i) => {
                        if (i === 0) {
                          return (
                            <div key={i} className={className(`rounded-t-2xl group w-full h-[60px] bg-[#242829] flex justify-between items-center px-[3%] cursor-pointer hover:bg-${button} focus:bg-${button} `,
                              isOpen ? `bg-${button} ` : ' rounded-b-2xl hover:rounded-b-2xl')} style={{
                                // boxShadow: activeKey[0] === '1' ? ' ': '7px 7px 25px rgba(0, 0, 0, 0.2)'
                              }}>
                              <div className="flex items-center">
                                <div className="w-[36px] h-[36px] flex items-center">
                                  <img src={iconsCfg?.[selectListData[i]?.name]} alt='' />
                                </div>
                                <div className="ml-1 text-[#fff]">{
                                  selectListData[i]?.name
                                }</div>

                                <div className="w-[24px] h-[24px] cursor-pointer ml-2" onClick={() => {
                                  setIsOpen(!isOpen);
                                }}>
                                  <img src={isOpen ? arrowUp : arrowDown} alt='' />
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input type='text'
                                  disabled={isDisableInput}
                                  onBlur={onBlur}
                                  onChange={onChangeInput}
                                  value={inputValue} className={className(`w-[100px] text-sm  middle text-right bg-[#242829] text-[#fff] group-hover:bg-${button} group-focus:bg-${button}`,
                                    isOpen ? `bg-${button} ` : ' ',
                                    isDisableInput ? `hover:cursor-not-allowed` : ``
                                  )} style={{}} placeholder="Amount" />
                                <div className={
                                  className(`text-[#E4E4E4] ml-3 cursor-pointer hove:text-[#BBBBBB]`,
                                    isDisableInput ? `text-repay` : ''
                                  )
                                } onClick={onClickSetMax}>
                                  {isDisableInput ? 'Cancel' : 'MAX'}
                                </div>
                              </div>
                            </div>
                          )
                        } else {
                          return (
                            <div onClick={() => {
                              onClickSelect(i);
                            }} key={item?.name} className={
                              className(`bg-${button} w-full cursor-pointer  `, i === (selectListData?.length - 1) ? 'rounded-b-2xl' : ' ')}>
                              <div className="px-[3%] flex w-full h-[60px] justify-between">
                                <div className="flex items-center">
                                  <div className="w-[36px] h-[36px] flex items-center">
                                    <img src={iconsCfg?.[selectListData[i]?.name]} alt='' />
                                  </div>
                                  <div className="ml-1 text-[#fff]">{item?.name}</div>
                                </div>
                                <div className="flex items-end text-[#fff] flex-col justify-center w-[130px]">
                                  <div className="text-sm">
                                    {options.name === 'borrow' ? 'Liquidity' : 'Debt Amount'}
                                  </div>
                                  <div className="text-sm mt-1">
                                    {options.name === 'borrow' ? Number(item?.available_liquidity)?.toFixed(decimalsCfg[item?.name]) : Number(item?.underlying_amount)?.toFixed(decimalsCfg[item?.name])}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })
                    }
                  </section>

                  <section className="px-[3%]">
                    <div className="flex justify-between  text-sm my-[22px]">
                      <div className="text-[#BBBBBB]">
                        {/*Wallet Balance*/}
                        {
                          t('Wallet Balance')
                        }
                      </div>
                      <div className="ml-[120px] text-[#E4E4E4]">
                        {selectListData?.find(item => item.isSelect)?.wallet_value} {selectListData?.find(item => item.isSelect)?.name}
                      </div>
                    </div>

                    <div className={className('flex justify-between text-sm my-[22px] ', button !== 'withdraw' && 'hidden')}>
                      <div className="text-[#BBBBBB]">
                        {/*Available to withdraw*/}
                        {
                          t('Available to withdraw')
                        }
                      </div>
                      <div className=" text-[#E4E4E4]">
                        {selectListData?.find(item => item.isSelect)?.underlying_amount} {selectListData?.find(item => item.isSelect)?.name}
                      </div>
                    </div>

                    <div className={className('flex justify-between text-sm my-[22px] ')}>
                      <div className="text-[#BBBBBB]">
                        {/*Borrow APY*/}
                        {
                          t('Borrow APY')
                        }
                      </div>
                      <div className=" text-[#E4E4E4]">
                        {selectListData?.find(item => item.isSelect)?.current_borrow_apr}
                      </div>
                    </div>

                    <div className="flex justify-between text-sm ">
                      <div className="text-[#BBBBBB]">
                        {/*Risk Index*/}
                        {
                          t('Risk Index')
                        }
                      </div>
                      <div className=" text-[#E4E4E4]">
                        {originRiskIndex?.toFixed(2)}

                        {
                          (inputValue && riskIndex) && (
                            <>
                              -{'>'}&nbsp;<span className={`text-${modalData?.name}`}>
                                {riskIndex?.toFixed(2)}
                              </span>
                            </>
                          )
                        }

                      </div>
                    </div>
                  </section>

                </section>
              </section>
              <section className="my-[20px]">
                <section
                  onClick={() => {
                    if (isBtnDisable) return;
                    onClickSetData(button);
                    setIsModalVisible(true);
                  }}
                  className={
                    className(`relative z-10 cursor-pointer w-full h-[48px] rounded-full border-${button} flex items-center justify-center  `,
                      isBtnDisable ? ` bg-[#404647] text-[#535859]` : `bg-${button} hover:bg-${button}Hover`
                    )}>
                  {options.buttonText}
                </section>
              </section>
            </section>
          )
        })
      }

      <TwiceConfirmModal modalData={modalData}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={onSubmitFn} />
    </>
  );
}
