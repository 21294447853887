import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';

import { Modal, ModalProps } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import className from 'classnames';
import { SDKContext } from 'contexts/SDKProvider';
import { decimalsCfg } from 'config/decimals';
import { useTranslation } from 'react-i18next';

interface ModalLoginProps extends ModalProps {
  modalData: any;
  onOk?: (string) => void;
  onCancel?: () => void;
}

export default React.memo<ModalLoginProps>((props) => {
  const { t } = useTranslation();
  const { visible, onOk, onCancel, modalData } = props;

  // @ts-ignore
  const { isTransfer, setIsTransfer, dashboardPageData } = useContext(SDKContext);
  const handleError = () => {};
  // console.log(`modalData: `, modalData);

  let underlying_amount = dashboardPageData?.userDepositInfo?.find(item => item.name === modalData.coinName)?.underlying_amount;
  // console.log(`underlying_amount: `, underlying_amount);
  let debt_underlying_amount = dashboardPageData?.userDebtInfo?.find(item => item.name === modalData.coinName)?.underlying_amount || 0;
  // debugger;
  // console.log(`debt_underlying_amount: `, debt_underlying_amount);
  // debugger;
  return (
    <Modal
      closable={!isTransfer}
      maskClosable={!isTransfer}
      keyboard={false}
      className={''}
      open={visible}
      centered
      title={
        <>
          {/*<span className="text-[#4AFCDC]">Deposit</span>*/}
        </>
      }
      onCancel={onCancel}
      footer={null}
    >
      <div className="w-full flex flex-col mt-6 mb-2 px-3" >

        <div className={className(`text-deposit text-withdraw text-borrow text-repay hidden`)}/>

        <div className={className(`text-[24px] `,
          modalData.name === 'deposit' ? 'text-deposit'
            : modalData.name === 'withdraw'
            ? 'text-withdraw' : modalData.name === 'borrow' ? ' text-borrow' : 'text-repay'
          )}>{ t(modalData.name.slice(0, 1).toUpperCase() + modalData.name.slice(1)) }</div>
        <div className="flex justify-between mt-[16px] text-[#E4E4E4]">
          <div className="">{t(modalData.name.slice(0, 1).toUpperCase() + modalData.name.slice(1))} {t('Amount')}</div>
          <div>{ modalData.inputValue } { modalData.coinName} </div>
        </div>
        <div className="flex justify-between mt-[16px] text-[#E4E4E4]">
          <div>{t(modalData.name.slice(0, 1).toUpperCase() + modalData.name.slice(1))} {t('Value')}</div>
          <div>${ (Number(modalData.inputValue) * Number(modalData.price)).toFixed(2) }</div>
        </div>
        <div className="flex justify-between mt-[16px] text-[#E4E4E4]">
          <div>{t('My')} {t(modalData.name.slice(0, 1).toUpperCase() + modalData.name.slice(1))}</div>
          {
            modalData.name === 'deposit' ? (
              <div>{
                underlying_amount || 0
              } { modalData.coinName} →
                <span className={className(` `, modalData.name === 'deposit' ? 'text-deposit'
                  : modalData.name === 'withdraw'
                    ? 'text-withdraw' : modalData.name === 'borrow' ? 'text-borrow' : 'text-repay'
                )}>
              { modalData.name === 'deposit' ? (Number(underlying_amount || 0) + Number(modalData.inputValue)).toFixed(decimalsCfg[modalData.coinName]) : ''}
                  { modalData.coinName }
                  <br/>
            </span></div>
            )  : modalData.name === 'withdraw' ? (
              <div>{
                underlying_amount
              } { modalData.coinName} →
                <span className={className(` `, modalData.name === 'deposit' ? 'text-deposit'
                  : modalData.name === 'withdraw'
                    ? 'text-withdraw' : modalData.name === 'borrow' ? 'text-borrow' : 'text-repay'
                )}>
              { modalData.name === 'withdraw' ? (Number(underlying_amount) - Number(modalData.inputValue)).toFixed(decimalsCfg[modalData.coinName]) : ''}
                  { modalData.coinName }
                  <br/>
            </span></div>
            ) : modalData.name === 'borrow' ? (
              <div>{
                debt_underlying_amount
              } { modalData.coinName} →
                <span className={className(` `, modalData.name === 'deposit' ? 'text-deposit'
                  : modalData.name === 'withdraw'
                    ? 'text-withdraw' : modalData.name === 'borrow' ? 'text-borrow' : 'text-repay'
                )}>
              { modalData.name === 'borrow' ? (Number(debt_underlying_amount) + Number(modalData.inputValue)).toFixed(decimalsCfg[modalData.coinName]) : ''}
                  { modalData.coinName }
                  <br/>
            </span></div>
            ) : (
              <div>{
                debt_underlying_amount
              } { modalData.coinName} →
                <span className={className(` `, modalData.name === 'deposit' ? 'text-deposit'
                  : modalData.name === 'withdraw'
                    ? 'text-withdraw' : modalData.name === 'borrow' ? 'text-borrow' : 'text-repay'
                )}>
              { modalData.name === 'repay' ? (Number(debt_underlying_amount) - Number(modalData.inputValue)).toFixed(decimalsCfg[modalData.coinName]) : ''}
                  { modalData.coinName }
                  <br/>
            </span></div>
            )
          }

          {/*<div>{modalData.wallet_value} { modalData.coinName} →*/}
          {/*  <span className={className(` `, modalData.name === 'deposit' ? 'text-deposit'*/}
          {/*    : modalData.name === 'withdraw'*/}
          {/*      ? 'text-withdraw' : modalData.name === 'borrow' ? 'text-borrow' : 'text-repay'*/}
          {/*  )}>*/}
          {/*    { modalData.name === 'deposit' ? (Number(modalData.wallet_value) - Number(modalData.inputValue)).toFixed(decimalsCfg[modalData.coinName]) : ''}*/}
          {/*    { modalData.name === 'repay' ? (Number(modalData.wallet_value) - Number(modalData.inputValue)).toFixed(decimalsCfg[modalData.coinName]) : ''}*/}
          {/*    { modalData.name === 'borrow' ? (Number(modalData.wallet_value) + Number(modalData.inputValue)).toFixed(decimalsCfg[modalData.coinName]) : ''}*/}
          {/*    { modalData.name === 'withdraw' ? (Number(modalData.wallet_value) + Number(modalData.inputValue)).toFixed(decimalsCfg[modalData.coinName]) : ''}*/}
          {/*    { modalData.coinName }*/}
          {/*    <br/>*/}

          {/*  </span>*/}
          {/*</div>*/}

        </div>
        <div className="flex justify-between mt-[16px] text-[#E4E4E4]">
          <div>
            {/*Risk Index*/}
            {
              t('Risk Index')
            }
          </div>
          <div>{
            modalData?.originRiskIndex?.toFixed(2)
          } → <span className={
            className(` `, modalData.name === 'deposit' ? 'text-deposit'
              : modalData.name === 'withdraw'
                ? 'text-withdraw' : modalData.name === 'borrow' ? 'text-borrow' : 'text-repay'
            )
          }>
            {
              modalData?.riskIndex?.toFixed(2)
            }
          </span></div>
        </div>

        <div className="w-full flex justify-center mt-12">
          <div onClick={async () => {
            if (isTransfer) return;
            setIsTransfer(true);
            onOk(modalData?.inputValue);
          }} className={className(` w-[388px] h-[50px] rounded-full text-white border border-[#363839] flex items-center justify-center cursor-pointer`,
              isTransfer ? ` bg-[#404647] text-[#535859] ` : ` bg-${modalData.name} hover:bg-${modalData.name}Hover`
            )}>
            <div className="text-[14px]">
              { isTransfer ? t('Pending') : t('Confirm')}
            </div>
          </div>
        </div>

      </div>

    </Modal>
  );
});
