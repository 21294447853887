// import Header from 'components/Header/index';
// import Footer from 'components/Footer/index';

import React, { useContext, useMemo, useState } from 'react';
// import sdk from '../sdk';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import logoWhite from 'assets/img/logo_white.png';
import iconMoney from 'assets/img/icon/icon-money.svg';
import iconSwitch from 'assets/img/icon/icon-switch.png';
import dashboard from 'assets/img/title/dashboard.png';
import lowRisk from 'assets/img/gif/low-risk.gif';
// import middleRisk from 'assets/img/gif/middle-risk.gif';
// import highRisk from 'assets/img/gif/high-risk.gif';
import { SDKContext } from 'contexts/SDKProvider';
import Msg from 'components/Modal/Msg';
import className from 'classnames';
import { Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { iconsCfg, decimalsCfg } from 'config/decimals';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import IndexH5 from './IndexH5';

export default function DashboardPage() {
  // const { globalState } = useModel('bank');
  const { t } = useTranslation();
  // @ts-ignore
  const { globalState, dashboardPageData, SetDashboardPageData, appLoading } = useContext(SDKContext);
  const [currentTab, setCurrentTab] = useState<string>('Assets');
  const [currentUnit, setCurrentUnit] = useState<string>('usd');
  const [isShowRiskInfo, setIsShowRiskInfo] = useState<boolean>(false);
  const { connected, account, network, connect, ...rest } = useWallet();
  const navigate = useNavigate();

  const { userDebtInfo, userDepositInfo } = dashboardPageData;

  const onClickTab = (tab) => {
    if (tab === 0) {
      setCurrentUnit('usd');
      let newUserDebtData = dashboardPageData?.userDebtInfo.map(item => {
        item.isShowUSD = true;
        return item;
      });
      let newUserAssetData = dashboardPageData?.userDepositInfo.map(item => {
        item.isShowUSD = true;
        return item;
      });
      SetDashboardPageData({
        ...dashboardPageData,
        userDebtInfo: newUserDebtData,
        userDepositInfo: newUserAssetData
      });
    } else {
      setCurrentUnit('amount');
      let newUserDebtData = dashboardPageData?.userDebtInfo.map(item => {
        item.isShowUSD = false;
        return item;
      });
      let newUserAssetData = dashboardPageData?.userDepositInfo.map(item => {
        item.isShowUSD = false;
        return item;
      });
      SetDashboardPageData({
        ...dashboardPageData,
        userDebtInfo: newUserDebtData,
        userDepositInfo: newUserAssetData
      });
    }
  }

  let riskIndex = globalState?.user_balance?.risk_index.format(9);
  let debt = (globalState?.user_balance?.total_vdebts_value?.format(9) / globalState?.user_balance?.max_vdebts_value?.format(9))?.toFixed(2);
  let debtLimit = (globalState?.user_balance?.total_vassets_value?.format(9) / globalState?.user_balance?.max_vdebts_value?.format(9))?.toFixed(2);
  let limitBuffer = (1 - globalState?.user_balance?.total_vdebts_value?.format(9) / globalState?.user_balance?.max_vdebts_value?.format(9))?.toFixed(2);

  return (
    <>
      <IndexH5 />
      <div className="hidden lg:flex min-h-[100vh] flex-col items-center">
        <section className="flex flex-col justify-start min-h-[82vh]">

          <section className="w-full lg:w-[1024px] xl:w-[1280px] flex my-6">
            <section className="flex flex-col w-full lg:w-[800px] xl:w-[1040px] mr-[20px] ">
              <section className="w-[1040px] text-[48px] font-['BioRhyme']">
                <div className="w-[500px]">
                  <img src={dashboard} alt='' />
                </div>
              </section>

              <section className=" h-[241px] gap-5 my-6 flex">
                <div className=" w-1/2 border border-[#161616] p-2 rounded-xl relative bg-[#292d2ef7]">
                  <div className="bg-[#34A7AE] absolute left-0 bottom-0 h-[4px] w-[92%] left-[50%] ml-[-46%] blur z-0" />

                  <div className="flex flex-col rounded-xl  h-[231px] pr-[16px] py-[26px] px-[30px] ">
                    <div className="mb-[26px]">
                      <div className="text-[16px] pb-[5px]">
                        {/*Total deposit*/}
                        {
                          t('totalDeposit')
                        }
                      </div>
                      <div className="text-[#BBBBBB] text-[24px]">
                        $ {globalState?.user_balance?.total_assets_value ?
                        Number(globalState?.user_balance?.total_assets_value.format(9))?.toFixed(2) : '-'}
                      </div>
                    </div>

                    <div className="">
                      <div className="text-[16px] pb-[5px]">
                        {/*Accumulated income*/}
                        {
                          t('Accumulated income')
                        }
                      </div>
                      <div className="text-[#3D3F40] text-[24px]">
                        {/*Feature coming soon*/}
                        {
                          t('Feature coming soon')
                        }
                      </div>
                    </div>

                  </div>
                </div>
                <div className=" w-1/2 border border-[#161616] p-2 rounded-xl relative bg-[#292d2ef7]">
                  <div className="bg-[#5D9C2B] absolute left-0 bottom-0 h-[4px] w-[92%] left-[50%] ml-[-46%] blur z-0" />

                  <div className="flex flex-col rounded-xl  h-[231px] pr-[16px] py-[26px] px-[30px]">
                    <div className="mb-[26px] ">
                      <div className="text-[16px] pb-[5px]">
                        {/*Total debt*/}
                        {
                          t('Total debt')
                        }
                      </div>
                      <div className="text-[#BBBBBB] text-[24px]">
                        $ {globalState?.user_balance?.total_debts_value ?
                        Number(globalState?.user_balance?.total_debts_value.format(9))?.toFixed(2) : '-'}
                      </div>
                    </div>

                    <div className="">
                      <div className="text-[16px] pb-[5px]">
                        {/*Accumulated borrow interest*/}
                        {
                          t('Accumulated borrow interest')
                        }
                      </div>
                      <div className="text-[#3D3F40] text-[24px]">
                        {/*Feature coming soon*/}
                        {
                          t('Feature coming soon')
                        }
                      </div>
                    </div>

                  </div>
                </div>
              </section>
            </section>
            <section className="flex flex-col w-[220px] h-[338px] border border-[#2B302E] rounded-xl bg-[#292d2ef7] items-center relative">
              {
                riskIndex < 0.5 ? (
                  <div className="bg-[#8EBE68] absolute left-0 bottom-[0] h-[4px] w-[88%] left-[50%] ml-[-46%] blur-md z-0" />
                ) : (riskIndex >= 0.5 && riskIndex <= 0.7) ? (
                  <div className="bg-[#E2AE60] absolute left-0 bottom-[0] h-[4px] w-[88%] left-[50%] ml-[-46%] blur-md z-0" />
                ) : (riskIndex > 0.7 && riskIndex <= 1) ? (
                  <div className="bg-[#E27860] absolute left-0 bottom-[0] h-[4px] w-[88%] left-[50%] ml-[-46%] blur-md z-0" />
                ) : riskIndex > 1 ? (
                  <div className="bg-[#E27860] absolute left-0 bottom-[0] h-[4px] w-[88%] left-[50%] ml-[-46%] blur-md z-0" />
                ) : (
                  <div className="bg-[#8EBE68] absolute left-0 bottom-[0] h-[4px] w-[88%] left-[50%] ml-[-46%] blur-md z-0" />
                )
              }

              <div className="absolute right-2 top-2 cursor-pointer" onClick={() => {
                setIsShowRiskInfo(true);
              }}>
                {/*<Tooltip title="test test" placement="bottom">*/}
                  <QuestionCircleOutlined style={{
                    color: '#B4AFAE'
                  }} />
                {/*</Tooltip>*/}
              </div>
              
              <div className="text-[16px] text-[#BBBBBB] mb-3 mt-[30px]">
                {/*Risk Index*/}
                {
                  t('Risk Index')
                }
              </div>
              <div className="hidden text-[#D9FDAB] text-[#E2AE60] text-[#E27860] text-[#D9FDAB]" />
              {
                riskIndex < 0.5 ? (
                  <div className="text-[#D9FDAB] text-[16px]">
                    {/*Low Risk*/}
                    {
                      t('Low Risk')
                    }
                  </div>
                ) : (riskIndex >= 0.5 && riskIndex <= 0.7) ? (
                  <div className="text-[#E2AE60] text-[16px]">
                    {/*Medium Risk*/}
                    {
                      t('Medium Risk')
                    }
                  </div>
                ) : (riskIndex > 0.7 && riskIndex <= 1) ? (
                  <div className="text-[#E27860] text-[16px]">
                    {/*High Risk*/}
                    {
                      t('High Risk')
                    }
                  </div>
                ) : riskIndex > 1 ? (
                  <div className="text-[#D9FDAB] text-[16px]">
                    {/*Under liquidation*/}
                    {
                      t('Under liquidation')
                    }
                  </div>
                ) : (
                  <div className="text-[#D9FDAB] text-[16px]">
                    {/*Low Risk*/}
                    {
                      t('Low Risk')
                    }
                  </div>
                )
              }

              <div className="w-[134px] h-[85px] flex items-center justify-center mt-[16px]" >
                <img src={lowRisk} alt='' />
              </div>

              <div className="mt-[10px] w-[180px] ">
                <div className="flex justify-between py-[10px] border-b border-b-[#393C3A]">
                  <div className='text-[#B4AFAE]'>
                    {/*Debt*/}
                    {
                      t('Debt')
                    }
                  </div>
                  <div className='text-[#E4E4E4]'>{
                    isNaN(Number(debt)) ? '-' : debt
                  }</div>
                </div>

                <div className="flex justify-between py-[10px] border-b border-b-[#393C3A]">
                  <div className='text-[#B4AFAE]'>
                    {/*Debt limit*/}
                    {
                      t('Debt limit')
                    }
                  </div>
                  <div className='text-[#E4E4E4]'>{
                    isNaN(Number(debtLimit)) ? '-' : debtLimit
                  }</div>
                </div>

                <div className="flex justify-between py-[10px] border-b border-b-[#393C3A]">
                  <div className='text-[#B4AFAE]'>
                    {/*Liquidate buffer*/}
                    {
                      t('Liquidate buffer')
                    }
                  </div>
                  <div className='text-[#E4E4E4]'>{
                    isNaN(Number(limitBuffer)) ? '-' : limitBuffer
                  }
                  </div>
                </div>
              </div>

            </section>
          </section>

          <section className="flex my-6 ">
            {/*<div className="text-[24px]">Market</div>*/}

            <section className="flex justify-between w-full">

              <section className="flex h-[40px] border border-[#454545] rounded-xl cursor-pointer">

                <div onClick={() => {
                  // onClickTab(1);
                  setCurrentTab('Assets');
                }}  className={className('flex items-center justify-center w-[240px] rounded-l-xl ', currentTab === 'Assets' ? 'bg-[#1C1F20]' : '')}>
                  <div className="w-[24px] mr-3">
                    <img src={iconSwitch} alt='' className="w-full" />
                  </div>
                  {/*Collateral details*/}
                  {
                    t('Collateral details')
                  }
                </div>

                <div onClick={() => {
                  // onClickTab(0);
                  setCurrentTab('Debt');
                }} className={className('flex items-center justify-center w-[240px]  rounded-r-xl border-l border-[#454545]', currentTab === 'Debt' ? 'bg-[#1C1F20]' : '')}>
                  <div className="w-[24px] mr-3">
                    <img src={iconMoney} alt='' className="w-full" />
                  </div>
                  {/*Debt Details*/}
                  {
                    t('Debt Details')
                  }
                </div>


              </section>

              <div className="flex ml-6 w-[156px] h-[30px] bg-[#404647] rounded-full select-none text-[12px]">
                <div className="hidden text-[#31767A]" />
                <div onClick={() => {
                  onClickTab(0);
                  // setCurrentUnit('usd');
                }} className={className('flex justify-center items-center w-[78px] py-[8px] rounded-full cursor-pointer text-[#B4AFAE] ', currentUnit === 'usd' ? ' !text-[#31767A] bg-[#fff]' : ' hover:text-[#fff]')}>
                <span>
                  USD
                </span>
                </div>
                <div onClick={() => {
                  onClickTab(1);
                  // setCurrentUnit('amount');
                }} className={className('flex justify-center items-center w-[78px] py-[8px] rounded-full cursor-pointer text-[#B4AFAE] ', currentUnit === 'amount' ? ' !text-[#31767A] bg-[#fff] ' : ' hover:text-[#fff]')}>
                <span>
                  {/*Amount*/}
                  {
                    t('Amount')
                  }
                </span>
                </div>
              </div>

            </section>
          </section>

          <section className={className(currentTab === 'Debt' ? ` mb-[100px] ` : 'hidden')}>

            <section className="">

              <div
                className=""
              >
                <table
                  className="w-full lg:w-[1024px] xl:w-[1280px] border-separate"
                  style={{
                    borderSpacing: '0',
                  }}
                >
                  <thead className={`h-[53px] text-[18px]`}>
                  <tr className={`text-left bg-[#25282A] text-center`}>
                    <th className="px-4 py-2 text-[#E4E4E4]  font-light rounded-tl-xl">
                      {/*Asset*/}
                      {
                        t('Asset')
                      }
                    </th>
                    <th className="px-4 py-2 text-[#E4E4E4]  font-light">
                      {/*Debt value*/}
                      {
                        t('Debt value')
                      }
                    </th>
                    <th className="px-4 py-2 text-[#E4E4E4]  font-light">
                      {/*Borrow APY*/}
                      {
                        t('Borrow APY')
                      }
                    </th>
                    <th className="px-4 py-2 text-[#E4E4E4]  font-light">
                      {/*Wallet balance*/}
                      {
                        t('Wallet balance')
                      }
                    </th>
                    <th className="px-4 py-2 text-[#E4E4E4]  font-light rounded-tr-xl">
                      {
                        t('Operation')
                      }
                    </th>
                  </tr>
                  </thead>
                  <tbody className=" bg-[#1D2021]" style={{}}>

                  {
                    !userDebtInfo?.every(item => Number(item?.underlying_amount) === 0) && userDebtInfo?.map((item, i) => {
                      return (
                        <tr onClick={() => {}} className={'group h-[53px] text-center '} key={item?.name || i}>
                          <td className={className(' font-black text-[16px]  font-medium   border-b-[red] px-4 py-3', '',
                            i === (userDebtInfo?.length - 1) ? 'rounded-bl-xl' : ''
                          )} style={{
                            borderBottom: i !== (userDebtInfo?.length - 1) ? '2px solid #2F3132' : '0'
                          }}>
                            <div className='flex items-center '>
                              <img className="px-3" src={iconsCfg?.[item.name]} alt='' />
                              {item.name}
                            </div>
                            {/*{item?.name}*/}
                          </td>

                          <td className={className('  font-black text-[16px]  font-medium px-4 py-3', '')}
                              style={{
                                borderBottom: i !== (userDebtInfo?.length - 1) ? '2px solid #2F3132' : '0'
                              }}>
                            {/*{parseInt(item?.deposited_value)}*/}
                            { item?.isShowUSD ?
                              `$${Number(item?.value)?.toFixed(2)}`
                              : Number(item?.underlying_amount)?.toFixed(decimalsCfg[item?.name] || 0) }
                          </td>

                          {/*<td className={className('  text-[#3D3F40] text-[16px]  font-medium   px-4 py-3', '')} style={{*/}
                          {/*  borderBottom: i !== (userDebtInfo?.length - 1) ? '2px solid #2F3132' : '0'*/}
                          {/*}}>*/}
                          {/*  Feature coming soon*/}
                          {/*</td>*/}

                          <td className={className('  font-black text-[16px]  font-medium  px-4 py-3', '')} style={{
                            borderBottom: i !== (userDebtInfo?.length - 1) ? '2px solid #2F3132' : '0'
                          }}>
                            <p className="text-[#4AFCDC]">
                              {item?.current_borrow_apr}
                            </p>
                          </td>

                          <td className={className('  font-black text-[16px]  font-medium   px-4 py-3', '')} style={{
                            borderBottom: i !== (userDebtInfo?.length - 1) ? '2px solid #2F3132' : '0'
                          }}>
                            {item?.wallet_value}
                          </td>

                          <td className={className('font-black text-[16px]  font-medium divide-y divide-red', 'px-4 py-3', ' ',
                            i === (userDebtInfo?.length - 1) ? 'rounded-br-xl ' : ''
                          )} style={{
                            borderBottom: i !== (userDebtInfo?.length - 1) ? '2px solid #2F3132' : '0'
                          }}>
                            <div className="flex justify-center cursor-pointer">
                              <div className="w-[130px] text-[#8ECE5B] bg-[#2C3327] hover:bg-[#262924] rounded-tl-full rounded-bl-full px-[30px] py-2" onClick={() => {
                                navigate(`/borrow#${item?.name}`);
                              }}>
                                {/*Borrow*/}
                                {
                                  t('Borrow')
                                }
                              </div>
                              <div>&nbsp;</div>
                              <div className="w-[130px] text-[#E27860] bg-[#352A28] hover:bg-[#2A2524] rounded-tr-3xl rounded-br-3xl px-[30px] py-2 cursor-pointer"  onClick={() => {
                                navigate(`/repay#${item?.name}`);
                              }}>
                                {/*Repay*/}
                                {
                                  t('Repay')
                                }
                              </div>
                            </div>
                          </td>

                        </tr>
                      );
                    })
                  }

                  </tbody>
                </table>

                {
                  ((!userDebtInfo?.length && !appLoading) || userDebtInfo?.every(item => Number(item?.underlying_amount) === 0)) && (
                    <div className="h-[280px] bg-[#282C2D]/70 flex flex-col justify-center items-center rounded-b-3xl">
                      <div className="w-[144px] h-[144px] justify-center flex">
                        <img src={require('assets/img/dashboard/empty-debt.svg').default} alt='' />
                      </div>
                      <div className="text-[#B4AFAE] my-3">
                        {/*You do not have debt yet.*/}
                        {
                          t('You do not have debt yet.')
                        }
                      </div>
                    </div>
                  )
                }

                {
                  <Skeleton active round loading={appLoading} className="my-6" />
                }
              </div>
            </section>



          </section>

          <section className={className(currentTab === 'Assets' ? `mb-[100px]` : 'hidden')}>

            <div className="">
              <table
                className="w-full lg:w-[1024px] xl:w-[1280px] border-separate"
                style={{
                  borderSpacing: '0',
                }}
              >
                <thead className={`h-[53px] text-[18px]`}>
                <tr className={`text-left bg-[#25282A] text-center`}>
                  <th
                    className="px-4 py-2 text-[#E4E4E4]  font-light rounded-tl-xl"
                    style={{}}
                  >
                    {/*Asset*/}
                    {
                      t('Asset')
                    }
                  </th>
                  <th className="px-4 py-2 text-[#E4E4E4]  font-light">
                    {/*Asset value*/}
                    {
                      t('Asset value')
                    }
                  </th>
                  {/*<th className="px-4 py-2 text-[#E4E4E4]  font-light">Accumulated profit</th>*/}
                  <th className="px-4 py-2 text-[#E4E4E4]  font-light">
                    {/*Deposit APY*/}
                    {
                      t('Deposit APY')
                    }
                  </th>
                  <th className="px-4 py-2 text-[#E4E4E4]  font-light">
                    {/*Wallet balance*/}
                    {
                      t('Wallet balance')
                    }
                  </th>
                  <th className="py-2 text-[#E4E4E4]  font-light rounded-tr-xl pl-16">
                    {/*Operation*/}
                    {
                      t('Operation')
                    }
                  </th>
                </tr>
                </thead>
                <tbody className=" bg-[#1D2021]" style={{}}>

                {
                  userDepositInfo?.map((item, i) => {
                    return (
                      <tr onClick={() => {
                      }} className={'group h-[53px] text-center '} key={item?.name || i}>
                        <td className={className(' font-black text-[16px]  font-medium   border-b-[red] px-4 py-3', '',
                          i === (userDepositInfo?.length - 1) ? 'rounded-bl-xl' : ''
                        )} style={{
                          borderBottom: i !== (userDepositInfo?.length - 1) ? '2px solid #2F3132' : '0'
                        }}>
                          <div className='flex items-center '>
                            <img className="px-3" src={iconsCfg?.[item.name]} alt='' />
                            {item.name}
                          </div>
                        </td>

                        <td className={className('  font-black text-[16px]  font-medium px-4 py-3', '')}
                            style={{
                              borderBottom: i !== (userDepositInfo?.length - 1) ? '2px solid #2F3132' : '0'
                            }}>
                          {/*{parseInt(item.deposited_value)}*/}
                          {item.isShowUSD ? `$${Number(item?.value)?.toFixed(2)}` : Number(item?.underlying_amount)?.toFixed(decimalsCfg[item?.name])}
                        </td>

                        {/*<td className={className('  font-black text-[16px]  font-medium   px-4 py-3', '')} style={{*/}
                        {/*  borderBottom: i !== (userDepositInfo?.length - 1) ? '2px solid #2F3132' : '0'*/}
                        {/*}}>*/}
                        {/*  <p className="text-[#4AFCDC]">*/}
                        {/*    {item?.current_supply_apr}*/}
                        {/*  </p>*/}
                        {/*</td>*/}

                        <td className={className('  font-black text-[16px]  font-medium  px-4 py-3', '')} style={{
                          borderBottom: i !== (userDepositInfo?.length - 1) ? '2px solid #2F3132' : '0'
                        }}>
                          <p className="text-[#4AFCDC]">
                            {item?.current_supply_apr}
                          </p>
                        </td>

                        <td className={className('  font-black text-[16px]  font-medium  px-4 py-3', '')} style={{
                          borderBottom: i !== (userDepositInfo?.length - 1) ? '2px solid #2F3132' : '0'
                        }}>
                          {item?.wallet_value}
                        </td>

                        <td className={className('font-black text-[16px]  font-medium divide-y divide-red', ' py-3', ' ',
                          i === (userDepositInfo?.length - 1) ? 'rounded-br-xl' : ''
                        )} style={{
                          borderBottom: i !== (userDepositInfo?.length - 1) ? '2px solid #2F3132' : '0'
                        }}>
                          <div className="flex justify-end cursor-pointer mr-12">
                            <div className="w-[150px] text-[#79CDBE] bg-[#2A3C3E] hover:bg-[#232A2A] rounded-tl-full rounded-bl-full px-[30px] py-2" onClick={() => {
                              // navigate(`/deposit?tab=0#${item.name}`);
                              navigate(`/deposit#${item.name}`);
                            }}>
                              {/*Deposit*/}
                              {
                                t('Deposit')
                              }
                            </div>
                            <div>&nbsp;</div>
                            <div className="w-[150px] text-[#E2AE60] bg-[#352F28] hover:bg-[#2A2824] rounded-tr-3xl rounded-br-3xl px-[30px] py-2 cursor-pointer"  onClick={() => {
                              // navigate(`/deposit?tab=1#${item.name}`);
                              navigate(`/withdraw#${item.name}`);
                            }}>
                              {/*Withdraw*/}
                              {
                                t('Withdraw')
                              }
                            </div>
                          </div>
                        </td>

                      </tr>
                    );
                  })
                }

                </tbody>
              </table>

            </div>

            {
              (!userDepositInfo?.length && !appLoading) && (
                <div className="h-[280px] bg-[#282C2D]/70 flex flex-col justify-center items-center rounded-b-3xl">
                  <div className="w-[144px] h-[144px] justify-center flex">
                    <img src={require('assets/img/dashboard/empty-collateral.svg').default} alt='' />
                  </div>
                  <div className="text-[#B4AFAE] my-3">
                    {/*You do not have collateral yet.*/}
                    {
                      t('You do not have collateral yet.')
                    }
                  </div>
                </div>
              )
            }

            {
              <Skeleton active round loading={appLoading} className="my-6" />
            }
          </section>


          <Msg visible={isShowRiskInfo} onCancel={() => setIsShowRiskInfo(false)} />

        </section>
      </div>
    </>
  );
}

