
import usdc from 'assets/img/icon/usdc-icon.svg';
import usdt from 'assets/img/icon/usdt-icon.svg';
import btc from 'assets/img/icon/btc-icon.svg';
import apt from 'assets/img/icon/apt-icon.svg';
import eth from 'assets/img/icon/eth-icon.svg';

export const decimalsCfg = {
  'USDC': 4,
  'lzUSDC': 4,
  'USDT': 4,
  'Aptos Coin': 4,
  'ETH': 4,
  'lzWETH': 4,
  'BTC': 6,
  'SOL': 4,
}

export const iconsCfg = {
  'USDC': usdc,
  'lzUSDC': usdc,
  'USDT': usdt,
  'lzUSDT': usdt,
  'Aptos Coin': apt,
  'ETH': eth,
  'lzWETH': eth,
  'BTC': btc,
  'SOL': btc,
}
