import className from 'classnames';
import { Skeleton, List } from 'antd';
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import { SDKContext } from 'contexts/SDKProvider';
import { useNavigate } from 'react-router-dom';
import useAptosWallet from 'hooks/useAptosWallet';
import { iconsCfg } from 'config/decimals';
import { useTranslation } from 'react-i18next';
import menu from 'assets/img/h5/index-menu.png'
import marketSlogan from 'assets/img/h5/slogan-text/market.png'
import { navList } from 'config/menuList';

export default function MarketPage() {
  const { t } = useTranslation();
  const { activeWallet, closeModal } = useAptosWallet();
  // @ts-ignore
  const { globalState, indexPageTableData, setIndexPageTableData, setAppLoading, appLoading, isLoginModal, setIsLoginModal } = useContext(SDKContext);
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<String>(indexPageTableData.every(item => item.isShowUSD) ? 'usd' : 'amount');
  const { connected, account, network, connect, ...rest } = useWallet();


  const onClickTab = (tab) => {
    if (tab === 0) {
      setCurrentTab('usd');
      let newData = indexPageTableData.map(item => {
        item.isShowUSD = true;
        return item;
      });
      setIndexPageTableData(newData);
    } else {
      let newData = indexPageTableData.map(item => {
        item.isShowUSD = false;
        return item;
      });
      setCurrentTab('amount');
      setIndexPageTableData(newData);
    }
  }

  return (
    <section className="flex lg:hidden">
      <section className='px-[3%] w-full'>

        <section className='min-h-[92vh] '>

          <div className='max-w-[360px] h-[46px] my-[14px] px-[3%]'>
            <img src={marketSlogan} alt="" />
          </div>

          <div className="border border-[#161616] p-2 rounded-[12px] relative ">
            <div className="bg-[#34A7AE] absolute left-0 bottom-0 h-[4px] w-[92%] left-[50%] ml-[-46%] blur z-0" />

            <div className="flex flex-col justify-center items-center  rounded-[12px] border border-[#454545] h-[130px] bg-[#292d2ef7]">


              <div className="flex w-full">
                <div className="flex-1 text-[14px] pb-[8px] text-center font-bold" style={{
                  textShadow: '0px 0px 6px rgba(255, 255, 255, 0.25)'
                }}>
                  $ {globalState?.total_pledged_value ? parseInt(globalState?.total_pledged_value?.format(9)) : '-'}
                </div>

                <div className="flex-1 text-[14px] pb-[8px] text-center font-bold" style={{
                  textShadow: '0px 0px 6px rgba(255, 255, 255, 0.25)'
                }}>
                  $ {globalState?.total_borrowed_value ? parseInt(globalState?.total_borrowed_value.format(9)) : '-'}
                </div>

                <div className=" flex-1 text-[14px] pb-[8px] text-center font-bold" style={{
                  textShadow: '0px 0px 6px rgba(255, 255, 255, 0.25)'
                }}>
                  $ {globalState?.total_value_locked ? parseInt(globalState?.total_value_locked.format(9)) : '-'}
                </div>
              </div>


              <div className="flex w-full">
                <div className="flex-1 mt-[20px] text-[#B4AFAE] text-[14px] text-center border-l-[1px]  border-r-[1px] border-[#454545] ">
                  {
                    t('Total')
                  }
                  <br />
                  {
                    t('Deposit')
                  }
                </div>

                <div className="flex-1 mt-[20px] text-[#B4AFAE] text-[14px] text-center border-l-[1px]  border-r-[1px] border-[#454545] ">
                  {
                    t('Total')
                  }
                  <br />
                  {
                    t('Borrowing')
                  }
                </div>

                <div className="flex justify-center items-center flex-1 mt-[20px] text-[#B4AFAE] text-[14px] text-center border-l-[1px]  border-r-[1px] border-[#454545] ">
                  {
                    t('TVL')
                  }
                  <br />
                </div>
              </div>



            </div>


          </div>

          <section className="flex justify-between px-[3%] mt-[24px]">
            <div className="text-[24px]">
              {
                t('indexTableTitleMarket')
              }
            </div>

            <div className="flex ml-6 w-[156px] p-[2px] bg-[#404647] rounded-full select-none text-[12px]">
              <div className="hidden text-[#31767A]" />
              <div onClick={() => {
                onClickTab(0);
              }} className={className('flex justify-center items-center w-[78px] py-[8px] rounded-full cursor-pointer text-[#B4AFAE] ', currentTab === 'usd' ? ' !text-[#31767A] bg-[#fff]' : ' hover:text-[#fff]')}>
                <span>
                  USD
                </span>
              </div>
              <div onClick={() => {
                onClickTab(1);
              }} className={className('flex justify-center items-center w-[78px] py-[8px] rounded-full cursor-pointer text-[#B4AFAE] ', currentTab === 'amount' ? ' !text-[#31767A] bg-[#fff] ' : ' hover:text-[#fff]')}>
                <span>
                  {
                    t('indexTableTitleAmount')
                  }
                </span>
              </div>
            </div>
          </section>


          {/* table */}
          <section className="mb-[100px]">
            <div
              className="px-[3%]"
            >
              <table
                className="w-full border-separate"
                style={{
                  borderSpacing: '0 1rem',
                }}
              >
                <thead className={`h-[48px]`}>
                  <tr className={`text-center`}>
                    <th
                      className="px-3 py-2 text-[#E4E4E4] font-light text-sm"
                      style={{
                      }}
                    >
                      {
                        t('indexTableTheadAssets')
                      }
                    </th>

                    <th className="px-3 py-2 text-[#E4E4E4] font-light text-sm">
                      {/*Deposit APY*/}
                      {
                        t('indexTableTheadDepositAPY')
                      }
                    </th>

                    <th className="px-3 py-2 text-[#E4E4E4] font-light text-sm">
                      {/*Borrow APY*/}
                      {
                        t('indexTableTheadBorrowAPY')
                      }
                    </th>

                  </tr>
                </thead>
                <tbody className="border text-center" style={{}}>

                  {
                    indexPageTableData.map((item: any) => {
                      return (
                        <tr onClick={() => {
                          if (!activeWallet) {
                            setIsLoginModal(true);
                            return;
                          }
                          navigate(`/rulesInfo?name=${item.name}`);
                        }} key={item.name} className={'group h-[49px] cursor-pointer border hover:bg-[#282C2D]/50 bg-[#282C2D]/70'}>
                          <td className={className('rounded-tl-xl  text-[14px]  font-normal rounded-bl-xl ', 'px-4', 'py-2', '')}>
                            <div className='flex items-center '>
                              <img className="px-2" src={iconsCfg?.[item.name]} alt='' />
                              {item.name}
                            </div>
                          </td>


                          <td className={className('text-[14px]  font-normal   ', 'px-4', 'py-2', '')}>
                            <div className="text-[#4AFCDC]">
                              {item.current_supply_apr}
                            </div>
                          </td>

                          <td className={className('rounded-tr-xl rounded-br-xl text-[14px]  font-normal   ', 'px-4', 'py-2', '')}>
                            <div className="text-[#AAF56F]">
                              {item.current_borrow_apr}
                            </div>
                          </td>

                        </tr>
                      );
                    })
                  }

                </tbody>
              </table>
              {
                <Skeleton active round loading={appLoading || !indexPageTableData?.length} />
              }
            </div>

          </section>
        </section>
      </section>

    </section>
  );

}
