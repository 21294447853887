import overviewLight from 'assets/img/h5/menu/overview-light.png';
import overviewGray from 'assets/img/h5/menu/overview-gray.png';
import borrowGray from 'assets/img/h5/menu/borrow-gray.png';
import borrowLight from 'assets/img/h5/menu/borrow-light.png';
import depositGray from 'assets/img/h5/menu/deposit-gray.png';
import depositLight from 'assets/img/h5/menu/deposit-light.png';
import dashboardLight from 'assets/img/h5/menu/deposit-light.png';
import dashboardGray from 'assets/img/h5/menu/deposit-gray.png';


import discord from 'assets/img/soical/discord.png';
import twitter from 'assets/img/soical/twitter.png';
import medium from 'assets/img/soical/medium.svg';


const navList = [{
  name: '/market',
  textMobile: 'Overview',
  text: 'marketOverview',
  url: '/market',
  icon: overviewGray,
  iconActive: overviewLight,
}, {
  name: '/deposit-list',
  textMobile: 'Deposit',
  text: 'depositWithdraw',
  url: '/deposit-list',
  icon: depositGray,
  iconActive: depositLight,
}, {
  name: '/borrow-list',
  textMobile: 'Borrow',
  text: 'borrowRepay',
  url: '/borrow-list',
  icon: borrowGray,
  iconActive: borrowLight,
}, {
  name: '/dashboard',
  textMobile: 'Dashboard',
  text: 'dashboard',
  url: '/dashboard',
  icon: dashboardGray,
  iconActive: dashboardLight,
}];


const iconList = [{
  img: discord,
  url: 'https://discord.gg/sUNjUEYX8a',
}, {
  img: twitter,
  url: 'https://twitter.com/Patronus_Fi',
}, {
  img: medium,
  url: 'https://medium.com/@Patronus_Fi',
}];

const langMap = {
  'en': 'English',
  'ko': 'Korean'
}

const langArr = ['en', 'ko'];


export {
  navList,
  iconList,
  langMap,
  langArr,
}