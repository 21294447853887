// import Test from './test';
import { useNavigate } from 'react-router-dom';
// import { history } from 'umi';
import React, { useContext, useEffect, useState } from 'react';
// @ts-ignore
// import { formatAddress } from '@/utils/string';
import { walletAddressEllipsis } from 'utils/utility';
import useAptosWallet from 'hooks/useAptosWallet';

import logo from 'assets/img/logo_white_and_text.png';
import beta from 'assets/img/icon/beta.svg';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import className from 'classnames';
// import { useModel } from '@@/plugin-model/useModel';
import FaucetModal from 'components/Modal/FaucetModal';
import { HexString } from 'aptos';
import { SDKContext } from '../../contexts/SDKProvider';
import { useTranslation } from 'react-i18next';
// import useCurrentPage from '../../hooks/useCurrentPage';
import { useLocation } from 'react-router-dom';
// import { Modal, ModalProps } from 'antd';

import menu from 'assets/img/h5/index-menu.png'
import logo_white from 'assets/img/logo_white.png'

import { navList, iconList, langMap } from 'config/menuList';

const menuList = [{
  name: 'whitePaper',
  url: 'https://patronus.fi/pre/media/Patronus_White_Paper_English.pdf'
},
{
  name: 'wiki',
  url: 'https://wiki.patronus.fi/brief-introduction/vision-and-mission'
},
{
  name: 'Github',
  url: 'https://github.com/patronusfi'
},
// {
//   name: 'Docs',
//   url: ''
// },
  // {
  //   name: 'English',
  //   url: ''
  // }
];




const hexStringV0ToV1 = (v0: any) => {
  if (typeof v0 === 'string') {
    return new HexString(v0);
  } else if (v0.hexString) {
    return new HexString(v0.toString());
  } else {
    throw new Error(`Invalid hex string object: ${v0}`);
  }
};

const noHeaderList = ['/'];
export default function Header(props) {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  // @ts-ignore
  const { globalState, indexPageTableData, setAppLoading, isLoginModal, setIsLoginModal } = useContext(SDKContext);
  const { activeWallet, closeModal } = useAptosWallet();
  const navigate = useNavigate();
  const wallet = useWallet();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isTranslate, setIsTranslate] = useState(false);
  // const { appLoading, isModalVisible, setIsModalVisible } = useModel('app');
  const { connected, account, network, connect, ...rest } = wallet;

  // @ts-ignore
  window.isModalVisible = isModalVisible;
  // @ts-ignore
  window.wallet = wallet;

  useEffect(() => {
    if (account && connected) {
      setIsLoginModal(false);
    }
  }, [account, network, connected]);

  const changeLanguage = (lng) => {
    console.log(`lng: `, lng);
    window.localStorage.setItem('lng', lng);
    i18n.changeLanguage(lng);
  };

  const disconnect = async () => {
    await rest.disconnect();
    // in case other account not refresh
    window.location.reload();
  }

  const isHidden = noHeaderList.some((item) => item === pathname);

  // @ts-ignore
  return (
    // <header className='flex justify-center '>
    <>

      {/* pc header start */}
      <header className={
        className('hidden lg:flex justify-center ', !isHidden ? ' block' : ' hidden')
      }>
        <section className="flex w-[1280px] h-[90px] justify-between items-center relative">
          <section className="flex items-center">
            <section className="w-[150px] cursor-pointer" onClick={() => {
              // navigate('/');
              if (window.location.origin === 'https://app.patronus.fi') {
                window.location.href = 'https://patronus.fi/';
              } else {
                window.location.href = 'https://qapatronus.fi/';
              }
            }}>
              <img className="w-full" src={logo} alt="" />
            </section>

            {/*<section className={*/}
            {/*  className('w-[66px] ml-2 ', window.location.hostname === 'app.patronus.fi' && ' hidden')*/}
            {/*}>*/}
            {/*  <img className="w-full" src={beta} alt="" />*/}
            {/*</section>*/}

          </section>

          <section className="flex items-center  mr-[200px]">
            {
              navList.map((item) => {
                return (
                  <div key={item.text} className={className(
                    'select-none mx-5 text-[#E4E4E4] hover:text-[#fff] hover:text-shadow-white font-light cursor-pointer ',
                    item.name === `${window.location.pathname}` ? 'text-[#fff] font-normal' : '')}
                    onClick={() => {
                      if (!connected || !account) {
                        // return setIsLoginModal(true);
                      }
                      if (item.name === `${window.location.pathname}`) return;
                      navigate(item.url);
                    }}>
                    {
                      t(item.text)
                    }
                  </div>
                )
              })
            }

            <section className="absolute right-0 top-[25px] z-[2]">
              {
                account?.address ? (
                  <>
                    <div className=" w-[150px] group text-[14px]  h-[40px] overflow-y-hidden hover:h-[80px] rounded-t-full rounded-b-full border border-[#3c3c3c] border-solid hover:border-[#B7B7B7] hover:rounded-t-[20px] hover:rounded-b-[20px]" style={{
                      backdropFilter: 'blur(10px)',
                      WebkitBackdropFilter: 'blur(10px)',
                    }}>
                      <div className="cursor-pointer text-center   leading-[35px]  ">
                        {walletAddressEllipsis(activeWallet?.toString())}
                      </div>
                      {/*<div className="cursor-pointer text-center h-[40px] leading-[35px] " onClick={() => {*/}
                      {/*  setIsLoginModal(false);*/}
                      {/*  setIsModalVisible(true);*/}
                      {/*}}>*/}
                      {/*  {*/}
                      {/*    t('faucet')*/}
                      {/*  }*/}
                      {/*</div>*/}
                      <div className=" cursor-pointer text-center h-[40px] leading-[35px] text-[#F84848]" onClick={disconnect}>
                        {
                          t('disconnect')
                        }
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="w-[150px] text-center ml-[80px] py-[10px] border border-[#3c3c3c] text-[14px] border-solid rounded-full cursor-pointer hover:border-[#B7B7B7]" onClick={async () => {
                      setIsLoginModal(true);
                    }}>
                      {
                        t('connectWallet')
                      }
                    </div>
                  </>
                )
              }
            </section>
          </section>
        </section>

        <FaucetModal visible={isModalVisible} onCancel={() => setIsModalVisible(false)} />
      </header>
      {/* pc header end */}

      {/* h5 header start */}
      <header className="flex lg:hidden sticky z-[11] left-0 top-0  h-[54px] w-full " style={{
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
        }}>
        <section className="flex px-[3%] w-screen justify-between">
          <div className="">
            <section className='w-[54px]' onClick={() => {
              navigate('/')
            }}>
              <img src={logo_white} alt="Logo" />
            </section>
          </div>
          <div className="flex ">
            {/* absolute right-0 top-[25px] z-[2] */}
            <section className="">
              {
                account?.address ? (
                  <>
                    <div className="mt-[8px]  mr-[20px] w-[150px] group text-[14px] h-[40px] overflow-y-hidden hover:h-[120px] rounded-t-full rounded-b-full border border-[#3c3c3c] border-solid hover:border-[#B7B7B7] hover:rounded-t-[20px] hover:rounded-b-[20px]">
                      <div className="cursor-pointer text-center   leading-[35px]  ">
                        {walletAddressEllipsis(activeWallet?.toString())}
                      </div>
                      <div className="cursor-pointer text-center h-[40px] leading-[35px] " onClick={() => {
                        setIsLoginModal(false);
                      }}>
                        {
                          t('faucet')
                        }
                      </div>
                      <div className=" cursor-pointer text-center h-[40px] leading-[35px] text-[#F84848]"
                        onClick={disconnect}>
                        {
                          t('disconnect')
                        }
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mt-[8px] mr-[20px] w-[150px] text-center  py-[10px] border border-[#3c3c3c] text-[14px] border-solid rounded-full cursor-pointer hover:border-[#B7B7B7]" onClick={async () => {
                      setIsLoginModal(true);
                    }}>
                      {
                        t('connectWallet')
                      }
                    </div>
                  </>
                )
              }
            </section>

            <section className="w-[36px] flex items-center" onClick={() => {
              setIsMenuVisible(true);
            }}>
              <img src={menu} alt="Logo" />
            </section>


          </div>
        </section>

        <section className={`fixed top-0 right-0 flex justify-center w-screen h-screen bg-[#454545] ${isMenuVisible ? 'flex' : 'hidden'}`}
          onClick={() => {
            // setIsMenuVisible(true);
          }}>
          <section className='flex flex-col'>
            <section className="flex px-[3%] w-screen justify-between">
              <div className="">
                <section className='w-[54px]' onClick={() => {
                  navigate('/')
                }}>
                  <img src={logo_white} alt="Logo" />
                </section>
              </div>
              <div className="flex ">
                {/* absolute right-0 top-[25px] z-[2] */}
                <section className="">
                  {
                    account?.address ? (
                      <>
                        <div className="mt-[8px]  mr-[20px] w-[150px] group text-[14px] h-[40px] overflow-y-hidden hover:h-[120px] rounded-t-full rounded-b-full border border-[#3c3c3c] border-solid hover:border-[#B7B7B7] hover:rounded-t-[20px] hover:rounded-b-[20px]">
                          <div className="cursor-pointer text-center   leading-[35px]  ">
                            {walletAddressEllipsis(activeWallet?.toString())}
                          </div>
                          <div className="cursor-pointer text-center h-[40px] leading-[35px] " onClick={() => {
                            setIsLoginModal(false);
                            // setIsModalVisible(true);
                          }}>
                            {/*Faucet*/}
                            {
                              t('faucet')
                            }
                          </div>
                          <div className=" cursor-pointer text-center h-[40px] leading-[35px] text-[#F84848]"
                            onClick={disconnect}>
                            {
                              t('disconnect')
                            }
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="mt-[8px] mr-[20px] w-[150px] text-center  py-[10px] border border-[#3c3c3c] text-[14px] border-solid rounded-full cursor-pointer hover:border-[#B7B7B7]" onClick={async () => {
                          setIsLoginModal(true);
                        }}>
                          {/*Connect Wallet*/}
                          {
                            t('connectWallet')
                          }
                        </div>
                      </>
                    )
                  }
                </section>

                <section className="w-[36px] h-[54px] pt-[20px] flex justify-center" onClick={() => {
                  setIsMenuVisible(false);
                  setIsTranslate(false);
                }}>
                  <span role="img" aria-label="close" className="anticon anticon-close ant-modal-close-icon"><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></span>
                </section>

              </div>
            </section>

            <section className="px-[20px]">
              <ul className='flex flex-col mt-[32px]'>
                {
                  menuList.map((item, i) => {
                    return (
                      <li key={item.url} className='py-[28px] pl-[6px] text-[14px] border-t border-[#B7B7B7]/20' onClick={() => {
                        // i !== (menuList.length - 1) ? navigate(item.url) : setIsTranslate(!isTranslate)
                        navigate(item.url);
                      }}>
                        {
                          t(item.name)
                        }
                      </li>
                    );
                  })
                }

                <li  className='flex justify-between py-[28px] pl-[6px] text-[14px] border-t border-[#B7B7B7]/20' onClick={() => {
                  setIsTranslate(!isTranslate)
                }}>
                  <div className="">Language</div>
                  <div className="text-[#B4AFAE]">
                    {
                      langMap[window.localStorage.getItem('lng')]
                    }
                  </div>
                </li>

              </ul>

              <ul className="mt-[10px] flex">
                {
                  iconList.map((item, i) => {
                    return (
                      <li key={item.url}>
                        <img className={`w-[33px] ${i > 0 && 'ml-[15px]'}`} src={item.img} alt="" onClick={() => {
                          navigate(item.url);
                        }} />
                      </li>
                    )
                  })
                }
              </ul>

              <div className={`${!isTranslate && ' hidden '} absolute bottom-0 left-0 w-full text-center`} style={{
                background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(183, 183, 183, 0.2)'
              }}>
                <div className="m-6" style={{
                  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(183, 183, 183, 0.2)',
                  boxShadow: 'inset 4px 4px 10px rgba(0, 0, 0, 0.2), inset -4px -4px 10px rgba(88, 103, 103, 0.15)',
                }}>
                  <div className="pt-6" onClick={() => {
                    changeLanguage('en');
                  }}>
                    {
                      t('English')
                    }
                  </div>
                  <div className="py-6" onClick={() => {
                    changeLanguage('ko');
                  }}>
                    {
                      t('Korean')
                    }
                  </div>
                </div>
              </div>
            </section>

          </section>

        </section>

      </header>
      {/* h5 header end */}

    </>
  );
}
