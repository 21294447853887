import ClassName from 'classnames';

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import back from 'assets/img/icon/back.png';
import iconDatabase from 'assets/img/icon/icon-database.png';
import iconHand from 'assets/img/icon/icon-hand.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { SDKContext } from '../../contexts/SDKProvider';
import { decimalsCfg, iconsCfg } from 'config/decimals';
import { modal, modalCfg } from 'config/interestRateModel';
import Indexh5 from './IndexH5';

import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  ToolboxComponent,
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useTranslation } from 'react-i18next';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
  ToolboxComponent
]);


export default function RulesInfoPage() {
  // const { globalState, rulesInfoData } = useModel('bank');
  const { t } = useTranslation();
  // @ts-ignore
  const { globalState, rulesPageData } = useContext(SDKContext);
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(`location: `, location);
  // debugger;
  const coinName = decodeURIComponent(location.search.split('name=')[1]);
  const [currentTab, setCurrentTab] = useState<string>('Deposit');
  const { connected, account, network, connect, ...rest } = useWallet();

  let data = rulesPageData?.find(item => item.name === coinName);
  // @ts-ignore
  window.rulesPageData = data;

  useEffect(() => {

    const base_apr = modalCfg[coinName]['base_apr'];
    const target_apr = modalCfg[coinName]['target_apr'];
    const target_utilization = modalCfg[coinName]['target_utilization'];
    const max_apr = modalCfg[coinName]['max_apr'];
    const system_fee = modalCfg[coinName]['system_fee'];

    const borrowXAxisList = [];
    const depositXAxisList = [];
    const borrowAPRList = [];
    const borrowAPYList = [];

    const depositAPRList = [];
    const depositAPYList = [];

    for (let i = 0; i < 11; i++) {
      borrowXAxisList.push((i * 10));
      depositXAxisList.push((i * 10));
    }

    for (let i = 0; i < target_utilization * 10; i++) {
      let a = (target_apr - base_apr) / target_utilization;
      let b = base_apr;
      borrowAPRList.push(
        (Math.floor((a * borrowXAxisList[i] / 100 + b) * Math.pow(10, 4) ) / Math.pow(10, 4) * 100).toFixed(2)
      );
    }
    for (let i = target_utilization * 10; i < borrowXAxisList.length; i++) {
      let a = (max_apr - target_apr) / (1 - target_utilization);
      let b = target_apr - a * target_utilization;
      borrowAPRList.push(
        (Math.floor((a * borrowXAxisList[i] / 100 + b) * Math.pow(10, 4) ) / Math.pow(10, 4) * 100).toFixed(2)
      );
    }
    for (let i = 0; i < target_utilization * 10; i++) {
      let a = (target_apr - base_apr) / target_utilization;
      let b = base_apr;
      borrowAPYList.push(
        (Math.floor((Math.exp(a * borrowXAxisList[i] / 100 + b) - 1) * Math.pow(10, 4) ) / Math.pow(10, 4) * 100).toFixed(2)
      );
    }
    for (let i = target_utilization * 10; i < borrowXAxisList.length; i++) {
      let a = (max_apr - target_apr) / (1 - target_utilization);
      let b = target_apr - a * target_utilization;
      borrowAPYList.push(
        (Math.floor((Math.exp(a * borrowXAxisList[i] / 100 + b) - 1) * Math.pow(10, 4) ) / Math.pow(10, 4) * 100).toFixed(2)
      );
    }

    for (let i = 0; i < target_utilization * 10; i++) {
      let a = (target_apr - base_apr) / target_utilization;
      let b = base_apr;
      let c = 1 - system_fee;
      depositAPRList.push(
        (Math.floor(c * depositXAxisList[i] / 100 * (a * depositXAxisList[i] / 100 + b) * Math.pow(10, 4)) / Math.pow(10, 4) * 100).toFixed(2)
      );
    }
    for (let i = target_utilization * 10; i < depositXAxisList.length; i++) {
      let a = (max_apr - target_apr) / (1 - target_utilization);
      let b = target_apr - a * target_utilization;
      let c = 1 - system_fee
      depositAPRList.push(
        (Math.floor(c * depositXAxisList[i] / 100 * (a * depositXAxisList[i] / 100 + b) * Math.pow(10, 4)) / Math.pow(10, 4) * 100).toFixed(2)
      );
    }
    for (let i = 0; i < target_utilization * 10; i++) {
      let a = (target_apr - base_apr) / target_utilization;
      let b = base_apr;
      let c = 1 - system_fee;
      depositAPYList.push(
        (Math.floor(c * (Math.exp(a * depositXAxisList[i] / 100 + b) - 1) * Math.pow(10, 4)) / Math.pow(10, 4) * 100).toFixed(2)
      );
    }
    for (let i = target_utilization * 10; i < depositXAxisList.length; i++) {
      let a = (max_apr - target_apr) / (1 - target_utilization);
      let b = target_apr - a * target_utilization;
      let c = 1 - system_fee;
      depositAPYList.push(
        (Math.floor(c * (Math.exp(a * depositXAxisList[i] / 100 + b) - 1) * Math.pow(10, 4)) / Math.pow(10, 4) * 100).toFixed(2)
      );
    }

    // let borrowModalChart = echarts.init(document.getElementById('borrowModal'));
    // let borrowModalMobile = echarts.init(document.getElementById('borrowModalMobile'));
    // let depositModalChart = echarts.init(document.getElementById('depositModal'));
    // let depositModalMobile = echarts.init(document.getElementById('depositModalMobile'));

    let borrowModalOption = {
      tooltip: {
        trigger: 'axis',
        valueFormatter: function (value) {
          return value + '%';
        }
      },
      grid: {
        left: '5%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        // data: borrowXAxisList.map(item => {
        //   return item * 100 + '%';
        // })
        data: borrowXAxisList,
        axisLabel: {
          formatter: '{value}%'
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '{value}%'
        }
      },
      series: [
        {
          name: 'APR',
          type: 'line',
          stack: 'Total',
          data: borrowAPRList,
          lineStyle: {
            color: '#48F8C3',
          },
          // smooth: true
        },
        {
          name: 'APY',
          type: 'line',
          stack: 'Total',
          data: borrowAPYList,
          lineStyle: {
            color: '#EAC360',
            width: 2,
            type: 'dashed'
          },
          // smooth: true
        }
      ]
    }
    // borrowModalChart.setOption(borrowModalOption);
    // borrowModalMobile.setOption(borrowModalOption);


    let depositModalOption = {
      tooltip: {
        trigger: 'axis',
        valueFormatter: function (value) {
          return value + '%';
        }
      },
      grid: {
        left: '4%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        // data: depositXAxisList.map(item => {
        //   return item * 100 + '%';
        // })
        data: depositXAxisList,
        axisLabel: {
          formatter: '{value}%'
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '{value}%'
        }
      },
      series: [
        {
          name: 'APR',
          type: 'line',
          stack: 'Total',
          data: depositAPRList,
          lineStyle: {
            color: '#48F8C3',
          },
          // smooth: true
        },
        {
          name: 'APY',
          type: 'line',
          stack: 'Total',
          data: depositAPYList,
          lineStyle: {
            color: '#EAC360',
            width: 2,
            type: 'dashed'
          },
          // smooth: true
        }
      ]
    }
    // depositModalChart.setOption(depositModalOption);
    // depositModalMobile.setOption(depositModalOption);

    // const modalChartResize = () => {
    //   borrowModalChart.resize();
    //   borrowModalMobile.resize();
    //   depositModalChart.resize();
    //   depositModalMobile.resize();
    // }
    // window.addEventListener('resize', modalChartResize);
    // return () => {
    //   window.removeEventListener('resize', modalChartResize);
    // };
  }, [])

  return (
    <>
      <Indexh5 />
      <div className="hidden md:hidden lg:flex  min-h-[100vh] flex-col items-center">
        <section className="flex flex-col justify-start min-h-[82vh]">

          <section className="w-full md:w-[768px] lg:w-[1024px] xl:w-[1280px]">

            {/* back */}
            <section className="flex" onClick={() => {
              navigate(-1);
            }}>
              <section className="h-[49px] px-[20px] text-[#31767A] flex items-center justify-center cursor-pointer border border-[#000] rounded-full hover:bg-[white]">
                <section className="w-[20px] mr-3">
                  <img src={back} alt='' className="w-full" />
                </section>
                {/*BACK*/}
                {
                  t('back')
                }
              </section>
            </section>

            <section className="my-6 flex items-center">
              <img src={iconsCfg[coinName]} alt='' className="mr-2" />
              {coinName}
            </section>

            {/* tabs */}
            <section className="flex justify-between w-full ">

              <section className="flex h-[60px] border border-[#454545] rounded-xl cursor-pointer text-[16px] md:w-[480px]">
                <div onClick={() => {
                  setCurrentTab('Deposit');
                }} className={ClassName('flex items-center justify-center w-1/2 rounded-l-xl ', currentTab === 'Deposit' ? 'bg-primeBlackTab' : '')}>
                  <div className="w-[15px] mr-3">
                    <img src={iconDatabase} alt='' className="w-full" />
                  </div>
                  {/*Deposit Info*/}
                  {
                    t('rulesInfoPageTab1')
                  }
                </div>

                <div onClick={() => {
                  setCurrentTab('Borrow');
                }}  className={ClassName('flex items-center justify-center w-1/2 rounded-r-xl border-l border-[#454545] ', currentTab === 'Borrow' ? 'bg-primeBlackTab' : '')}>
                  <div className="w-[15px] mr-3">
                    <img src={iconHand} alt='' className="w-full" />
                  </div>
                  {/*Borrow Info*/}
                  {
                    t('rulesInfoPageTab2')
                  }
                </div>
              </section>

              <section
                onClick={() => {
                  navigate(`/deposit#${coinName}`)
                }}
                className={ClassName('cursor-pointer w-[200px] h-[48px] rounded-full border border-[#3B8B8F] flex items-center justify-center bg-[#3B8B8F] hover:bg-[#31767A]', currentTab === 'Deposit' ? '' : 'hidden')}>
                {/*Deposit*/}
                {
                  t('deposit')
                }
              </section>

              <section
                onClick={() => {
                  navigate(`/borrow#${coinName}`);
                }}
                className={ClassName('cursor-pointer w-[200px] h-[48px] rounded-full border border-[#8ECE5B]/60 flex items-center justify-center bg-[#8ECE5B]/60 hover:bg-[#4E7034]', currentTab === 'Borrow' ? '' : 'hidden')}>
                {/*Borrow*/}
                {
                  t('borrow')
                }
              </section>

            </section>

            {/* deposit info */}
            <section className={ClassName('rounded-lg my-6 border border-[#212526] ', currentTab === 'Deposit' ? '' : 'hidden')}>
              <section className="bg-primeBlack px-[30px] py-[44px] flex rounded-lg">
                <div className='flex flex-col w-[300px] pl-[50px]'>
                  {/*<div className="mb-[15px]">Token Price</div>*/}
                  {/*<div className="mb-[15px]">Total Deposit</div>*/}
                  {/*<div className="mb-[15px]">Deposit APY</div>*/}
                  {/*<div className="mb-[15px]">Collateral Factor</div>*/}
                  <div className="mb-[15px]">
                    {
                      t('tokenPrice')
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      t('totalDeposit')
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      t('depositAPY')
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      t('collateralFactor')
                    }
                  </div>
                </div>

                <div className='flex flex-col w-[400px] pl-[0]'>
                  <div className="mb-[15px]">
                    {
                      data?.price && `$ ${data?.price}`
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      data?.deposited_value ? `$ ${Number(data?.deposited_value)?.toFixed(2)}` : '-'
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      data?.current_supply_apr
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      data?.df ? Number(data?.df)?.toFixed(2) : '-'
                    }
                  </div>
                </div>
              </section>
            </section>

            {/* Borrow info */}
            <section className={ClassName('rounded-lg my-6 border border-[#212526] ', currentTab === 'Borrow' ? '' : 'hidden')}>
              <section className="bg-primeBlack px-[30px] py-[44px] flex rounded-lg">
                <div className='flex flex-col w-[400px] pl-[50px]'>

                  <div className="mb-[15px]">
                    {
                      t('totalBorrowing')
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      t('borrowAPY')
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      t('borrowFactor')
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      t('borrowFee')
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      t('utilizationRate')
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      t('availableLiquidity')
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      t('depositLimit')
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      t('borrowLimit')
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      t('liquidationThreshold')
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      t('liquidationPenalty')
                    }
                  </div>
                </div>

                <div className='flex flex-col w-[500px] pl-[100px]'>
                  <div className="mb-[15px]">
                    {
                      data?.borrowed_value ? `$ ${parseInt(data?.borrowed_value)}` : '-'
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      data?.current_borrow_apr
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      data?.bf ? Number(data?.bf)?.toFixed(2) : '-'
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      data?.borrow_fee ? (data?.borrow_fee * 100 )?.toFixed(2) + '%' : '-'
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      data?.current_utilization
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      data?.available_liquidity ? Number(data?.available_liquidity)?.toFixed(2) : '-'
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      (data?.pledge_limit && data?.pledge_limit !== '-') ? `${parseInt(data?.pledge_limit)}` : '-'
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      (data?.borrow_limit && data?.borrow_limit !== '-') ? `${parseInt(data?.borrow_limit)}` : '-'
                    }
                  </div>
                  <div className="mb-[15px]">
                    {
                      data?.liquidation_Threshold ? Number(data?.liquidation_Threshold)?.toFixed(2) : '-'
                    }
                  </div>

                  <div className="mb-[15px]">
                    {
                      // @ts-ignore
                      data?.ld ? parseInt((Number(data?.ld)?.toFixed(2) - 1) * 100) + '%' : '-'
                    }
                  </div>

                </div>
              </section>
            </section>

            {/* borrow detail */}
            <section className={ClassName('rounded-lg my-6 border border-[#212526] ', currentTab === 'Borrow' ? '' : 'hidden')}>
              <section className="bg-primeBlack px-[30px] py-[20px] flex rounded-lg">
                <section className="flex flex-col">
                  <div className="mb-4 ml-[50px] text-[#E4E4E4] text-[24px]">
                    {/*Interest Rate Model*/}
                    {
                      t('interestRateModel')
                    }
                  </div>

                  <div className="mb-4 ml-[50px] leading-[25px] ">
                    {/*{*/}
                    {/*  modal[coinName]*/}
                    {/*}*/}
                    {
                      t(modal[coinName])
                    }
                  </div>
                </section>
              </section>
            </section>

            <section className="bg-primeBlack px-[30px] py-[44px] rounded-lg mb-6 hidden">

              <div className={"flex justify-between "}>
                <div className="text-[#E4E4E4] text-[24px] ml-[50px]">
                  { currentTab === 'Borrow' ? t('borrow') : t('deposit') }
                  {
                    t('interestRateModel')
                  }
                </div>
                <div className="hidden">
                  <a href='#'>
                    Check Description {'>'}
                  </a>
                </div>
              </div>

              {/*<div id="borrowModal" className="mt-[20px] w-full h-[410px]" />*/}
              <div id="borrowModal" className={ClassName('my-[20px] w-[1218px] h-[410px]', currentTab === 'Borrow' ? '' : 'hidden')} />

              {/*<div id="depositModal" className="mt-[20px] w-full h-[410px]" />*/}
              <div id="depositModal" className={ClassName('my-[20px] w-[1218px] h-[410px]', currentTab === 'Deposit' ? '' : 'hidden')} />
            </section>

          </section>

        </section>
        {/*<Footer />*/}
      </div>
    </>
  );
}
