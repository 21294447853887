import React, { useEffect, useState } from 'react';
import logo from 'assets/img/logo_white.png';
import './index.css';

// import feedback from 'assets/img/icon/feedback-icon.svg';
// import feedbackFull from 'assets/img/icon/feedback-full-icon.svg';
import feedbackMsg from 'assets/img/icon/feedback-msg-icon.svg';
import className from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { navList, iconList, langMap, langArr } from 'config/menuList';

const notShowMenuList = [
  '/rulesInfo',
  '/deposit',
  '/withdraw',
  '/repay',
  '/borrow',
]

export default function FooterPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [isMouseOverFeed, setIsMouseOverFeed] = useState(false);

  const changeLanguage = (lng) => {
    window.localStorage.setItem('lng', lng);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    window.localStorage.getItem('lng') && i18n.changeLanguage(window.localStorage.getItem('lng'));
  }, [])


  return (
    <>

      {/* pc footer menu start */}
      <section className='hidden lg:flex '>
        <div className="fixed bottom-[80px] right-[20px] flex-col feedback">
          <a href="https://patronusfinance.canny.io/feature-requests" target="_blank" rel="noreferrer">
            <div className={className('bg-[#31767A] w-[52px] h-[52px] rounded-full flex justify-center items-center',
              isMouseOverFeed ? ' !w-[144px]' : ' '
            )} onMouseOver={() => {
              setIsMouseOverFeed(true);
            }} onMouseLeave={() => {
              setIsMouseOverFeed(false);
            }} style={{
              transition: `all .1s linear`
            }}>
              <div className={className('bg-[#56D7DF]/60 w-[46px] h-[46px] rounded-full flex justify-center items-center',
                isMouseOverFeed ? ' !w-[136px]' : ' '
              )} style={{
                transition: `all .1s linear`
              }}>
                <div className="w-[26px] h-[26px] cursor-pointer">
                  <img src={feedbackMsg} alt='' />
                </div>
                <div className={className('ml-[6px]', isMouseOverFeed ? ' ' : ' hidden')} style={{
                  transition: `all .1s linear`
                }}>
                  {
                    t('feedback')
                  }
                </div>
              </div>
            </div>
          </a>
        </div>

        <footer className="w-full lg:flex fixed bottom-0 left-1/2 -translate-x-1/2 justify-center z-0">
          <section className="w-[1024px] xl:w-[1280px] flex justify-between ">
            <section className="w-[45px] h-[47px] cursor-pointer flex justify-center" onClick={() => {
              if (window.location.origin === 'https://app.patronus.fi') {
                window.location.href = 'https://patronus.fi/';
              } else {
                window.location.href = 'https://qapatronus.fi/';
              }
            }}>
              <img className="w-full" alt='' src={logo} />
            </section>

            <section className='flex items-center cursor-pointer mb-[10px]  relative'>
              <section className="flex items-center cursor-pointer mb-[10px] mr-[180px]">
                {
                  iconList.map(item => {
                    return (
                      <div className="w-[30px] mx-3" key={item?.img} onClick={() => {
                        window.open(item.url);
                      }}>
                        <img src={item?.img} alt='' className="w-full" />
                      </div>
                    )
                  })
                }
              </section>

              <section className="flex items-center cursor-pointer mb-[10px] absolute bottom-0 right-[280px] ">
                <div className="w-[150px] group text-[14px] overflow-y-hidden h-[40px] hover:h-[80px] rounded-t-full rounded-b-full border  border-solid hover:border-[#B7B7B7] hover:rounded-t-[20px] hover:rounded-b-[20px]">
                  {/* <div className="cursor-pointer text-center leading-[35px] " onClick={() => {
                    changeLanguage('en');
                  }}>
                    {
                      t('English')
                    }
                  </div>
                  <div className="cursor-pointer text-center h-[40px] leading-[35px] " onClick={() => {
                    changeLanguage('ko');
                  }}>
                    {
                      t('Korean')
                    }
                  </div> */}

                  <div className="cursor-pointer text-center leading-[35px] " onClick={() => {
                    changeLanguage(window.localStorage.getItem('lng'));
                  }}>
                    {
                      t(langMap[window.localStorage.getItem('lng')])
                    }
                  </div>

                  {
                    langArr.map((item, i) => {
                      return (
                        <div key={item} className={`cursor-pointer text-center leading-[35px] ${item === window.localStorage.getItem('lng') && ' hidden '}`} onClick={() => {
                          changeLanguage(item);
                        }}>
                          {
                            t(langMap[item])
                          }
                        </div>
                      );
                    })
                  }
                </div>
              </section>

              <section className="flex items-center cursor-pointer mb-[10px]">
                <div className="mx-3 border px-4  rounded-2xl w-[80px] h-[40px] flex justify-center items-center">
                  <a href='https://wiki.patronus.fi/' rel="noopener noreferrer" target="_blank">
                    {
                      t('wiki')
                    }
                  </a>
                </div>

                <div className="mx-3 border px-4  rounded-2xl w-[148px] h-[40px] flex justify-center items-center">
                  <a href='https://patronus.fi/pre/media/Patronus_White_Paper_English.pdf' rel="noopener noreferrer" target="_blank">
                    {
                      t('whitePaper')
                    }
                  </a>
                </div>
              </section>
            </section>
          </section>
        </footer>
      </section>
      {/* pc footer menu end */}

      {/* h5 footer menu start */}
      <section className={`bg-[#0F1414CC] w-full sticky z-[10] left-0 bottom-0  items-center justify-center  h-[66px]  border border-[#494949]/80 rounded-tl-[23px] rounded-tr-[23px] ${notShowMenuList.findIndex(item => item === window.location.pathname) > -1 ? 'hidden' : 'flex lg:hidden'
        }`} style={{
          backdropFilter: 'blur(5px)',
          WebkitBackdropFilter: 'blur(5px)',
        }}>
        {
          navList.map(item => {
            return (
              <section key={item.url} className='flex flex-col flex-1' onClick={() => {
                navigate(item.url);
              }}>
                <section className="flex justify-center ">
                  <section className='flex w-[30px] h-[30px]'>
                    {
                      item.name === `${window.location.pathname}` ? (<img src={
                        item.iconActive
                      } alt="" />) : (<img src={
                        item.icon
                      } alt="" />)
                    }
                  </section>
                </section>
                <section className={
                  className('flex justify-center text-sm ',
                    item.name === `${window.location.pathname}` ? 'text-[#fff]' : 'text-[#fff]/50'
                  )
                }>
                  {
                    t(item.textMobile)
                  }
                </section>
              </section>
            )
          })
        }

      </section>
      {/* h5 footer menu end */}

    </>
  );
}
