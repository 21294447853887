const loginModal = {
  "loginModalTitle": "지갑 선택",
  "loginModalDescribe": "사용하기 전에 충분한 연구를 진행하시고, 본인 책임 하에 사용해주세요.",
  "loginModalFaucet": "수도꼭지"
}

const header = {
  "marketOverview": "시장 개요",
  "depositWithdraw": "입출금",
  "borrowRepay": "차입/상환",
  "dashboard": "대시보드",
  "Dashboard": "대시보드",
  "Overview": "개요",
  "faucet": "수도꼭지",
  "disconnect": "연결 해제",
  "connectWallet": "지갑 연결",
  "whitePaper": "백서",
  'feedback': '피드백',
  'wiki': '위키',
  'Github': 'Github',
  'Docs': 'Docs',
  'English': 'English',
  'Korean': '한국어를',
}

const indexPage = {
  ...header,
  "totalDeposit": "총 입금액",
  "Total": "총",
  "totalBorrow": "총 대출액",
  "Borrowing": "대출액",
  "TVL": 'TVL',
  "indexTableTitleMarket": "시장",
  "indexTableTitleAmount": "금액",
  "indexTableTheadAssets": "자산",
  "indexTableTheadDepositAPY": "입금 APY",
  "indexTableTheadBorrowAPY": "대출 APY",
  "indexTableTheadWalletBalance": "지갑 잔고"
}

const rulesInfoPage = {
  back: '뒤로',
  deposit: '예치',
  borrow: '대출',
  rulesInfoPageTab1: '예치 정보',
  rulesInfoPageTab2: '대출 정보',
  tokenPrice: '토큰 가격',
  depositAPY: '예치 APY',
  collateralFactor: '담보 비율',
  rulesInfoPageChartTitle: '예치 이자율 모델',
  interestRateModel: '이자율 모델',
  interestRateModelDesc: '이자율 모델',
  totalBorrowing: '총 대출액',
  borrowAPY: '대출 APY',
  borrowFactor: '대출 비율',
  borrowFee: '대출 수수료',
  utilizationRate: '활용률',
  availableLiquidity: '유효성 있는 유동성',
  depositLimit: '예치 한도',
  borrowLimit: '대출 한도',
  liquidationThreshold: '청산 기준치',
  liquidationPenalty: '청산 벌금',

  stableCoinText: '이 통화의 최저 대출 APY는 2%입니다. \n' +
    '활용률이 0.9 이하인 경우 대출 APY는 8.3%이며, 활용률이 1인 경우 대출 APY는 80%입니다. \n' +
    '활용률이 0.9 이하인 경우 대출 APY = 0.02 + 0.07 × 활용률입니다. 활용률이 0.9보다 큰 경우 대출 APY = -6.37 + 7.17 × 활용률입니다.',
  btcEthText: '이 통화의 최저 대출 APY는 2%입니다. \n' +
    '활용률이 0.8 이하인 경우 대출 APY는 10%이며, 활용률이 1인 경우 대출 APY는 80%입니다. \n' +
    '활용률이 0.8 이하인 경우 대출 APY = 0.02 + 0.1 × 활용률입니다. \n' +
    '활용률이 0.8보다 큰 경우 대출 APY = -2.7 + 3.5 × 활용률입니다.',
  aptText: '이 통화의 최저 대출 APY는 2%입니다. \n' +
    '활용률이 0.5 이하인 경우 대출 APY는 20%이며, 활용률이 1인 경우 대출 APY는 300%입니다. \n' +
    '활용률이 0.5 이하인 경우 대출 APY = 0.02 + 0.36 × 활용률입니다. \n' +
    '활용률이 0.5보다 큰 경우 대출 APY = -2.6 + 5.6 × 활용률입니다.',
}

const dashboardPage = {
  'Accumulated income': '누적 수입',
  'Feature coming soon': '곧 출시될 예정입니다',
  'Accumulated borrow interest': '누적 대출 이자',
  'Total debt': '총 부채',
  'Risk Index': '위험 지수',
  'Low Risk': '저위험',
  'Medium Risk': '중위험',
  'High Risk': '고위험',
  'Under liquidation': '청산 예정',
  'Asset value': '자산 가치',
  'Deposit APY': '입금액 APY',
  'Borrow APY': '대출 APY',
  'Asset': '자산',
  'Amount': '금액',
  'Debt': '부채',
  'Debt limit': '대출 한도',
  'Liquidate buffer': '청산 버퍼',
  'Debt Details': '부채 세부 정보',
  'Collateral details': '담보 세부 정보',
  'Debt value': '부채 가치',
  'Wallet balance': '지갑 잔액',
  'Operation': '작업',
  'Borrow': '대출',
  'Repay': '상환',
  'Deposit': '입금',
  'Withdraw': '출금',
  'You do not have debt yet.': '아직 대출이 없습니다.',
  'You do not have collateral yet.': '아직 담보가 없습니다.',
  'TheRiskIndexShows': '이 패널에서 위험 지수는 4단계로 표시됩니다: ',
  'andUnderLiquidation': '그리고 청산 예정입니다. ',
  'TheFollowingThreeFactors': '다음 3가지 요소는 구체적인 위험 요소를 이해하는 데 도움이 됩니다.\n 1) 부채: 담보화된 자산 총액과 대출 받은 자산 총액의 함수이며, 계정이 미담보화됐는지 여부를 판단하는 데 사용됩니다. 값이 높을수록 위험이 높으며, 청산 위험성이 높아집니다.\n' +
    ' 2) 대출 한도: 총 담보 자산과 이론적 최대 대출 가치의 함수이며, 1) > 2) 일 때, 대출 총액이 한도를 초과하므로 청산 위험성이 매우 높아집니다.\n' +
    ' 3) 청산 버퍼: 1-1)에서 계산된 청산 버퍼의 백분율입니다.'
}

const depositPage = {
  'Deposit asset to earn income.': '수익을 얻으려면 자산을 입금하세요.',
  'Total Deposit': '총 입금',
  'Wallet Balance': '지갑 잔액',
  'Show wallet assets only': '지갑 자산만 보기',
  'MAX': '최대',
  'Pending': '보류 중',
  'Confirm': '확인',
  'Value': '가치',
  'My': '내',
  'Available to withdraw': '인출 가능한 금액',
}


const borrowPage = {
  'Users can borrow by depositing idle assets.': '사용자는 유휴 자산을 예금으로 입금하여 대출을 받을 수 있습니다.',
  'Total Borrowing': '총 대출 금액',
  'You are available to borrow': '대출 가능 금액',
  'Dynamic amount based on Oracle': '오라클을 기반으로 한 동적 금액',
  'Your debt': '대출 채무',
  'Liquidity': '유동성',
  'Utilization Rate': '이용률',
}

export default {
  "Welcome to React": " Bienvenue à React et lizi",
  ...loginModal,
  ...indexPage,
  ...rulesInfoPage,
  ...dashboardPage,
  ...depositPage,
  ...borrowPage,
}
