import { Navigate, RouteObject, useLocation, useNavigate, useRoutes } from 'react-router-dom';


import Market from 'pages/Market/Index';
import RulesInfo from 'pages/RulesInfo/Index';
import DepositList from 'pages/Deposit/List';
import BorrowList from 'pages/Borrow/List';
import Deposit from 'pages/Deposit/Deposit';
import Withdraw from 'pages/Deposit/Withdraw';
import Borrow from 'pages/Borrow/Borrow';
import Repay from 'pages/Borrow/Repay';
import Dashboard from 'pages/Dashboard/Index';
import Test from 'pages/Test/index';
import { useSelector } from 'react-redux';
import { getIsResourcesNotFound } from 'modules/common/reducer';

export type TRoute = RouteObject & {
  name: 'Market' | '404' | 'RulesInfo' | 'DepositList' | 'Deposit' | 'Withdraw' | 'BorrowList' | 'Borrow' | 'Repay' | 'Dashboard' | 'OfficeWebsite' | 'Test';
  hidden?: boolean; //to hide the visibility in header menu
};

export const routes: TRoute[] = [
  // {
  //   path: '/',
  //   name: 'OfficeWebsite',
  //   element: <OfficeWebsite />
  // },
  {
    path: '/market',
    name: 'Market',
    element: <Market />
  },
  {
    path: '/rulesInfo',
    name: 'RulesInfo',
    element: <RulesInfo />
  },
  {
    path: '/deposit-list',
    name: 'DepositList',
    element: <DepositList />
  },
  {
    path: '/deposit',
    name: 'Deposit',
    element: <Deposit />
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    element: <Withdraw />
  },
  {
    path: '/borrow-list',
    name: 'BorrowList',
    element: <BorrowList />
  },
  {
    path: '/borrow',
    name: 'Borrow',
    element: <Borrow />
  },
  {
    path: '/repay',
    name: 'Repay',
    element: <Repay />
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    element: <Dashboard />
  },
  {
    path: '/test',
    name: 'Test',
    element: <Test />
  },
  {
    path: '*',
    name: '404',
    element: <Navigate replace to="/market" />
  }
];

const Routes = () => {
  const activeRoutes = [...routes];
  const isResourcesNotFound = useSelector(getIsResourcesNotFound);
  const nav = useNavigate();
  const location = useLocation();
  if (isResourcesNotFound) {
    // activeRoutes.forEach((r) => {
    //   if (['Swap', 'Faucet'].includes(r.name)) {
    //     r.hidden = true;
    //   }
    // });
    setTimeout(() => {
      if (location.pathname !== '/') nav('/');
    });
  }

  const elements = useRoutes(activeRoutes as RouteObject[]);
  return elements;
};

export default Routes;
