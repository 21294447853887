import React, { useState, useEffect, useCallback, useRef } from 'react';

import classNames from 'classnames';
import { Modal, ModalProps } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
// @ts-ignore
import scss from './index.scss';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import { useTranslation } from 'react-i18next';

interface ModalLoginProps extends ModalProps {
  onOk?: () => void;
  onCancel?: () => void;
}

export default React.memo<ModalLoginProps>((props) => {
  const { visible, onOk, onCancel } = props;
  const { t } = useTranslation();
  // const wallet = useWallet();

  const handleError = () => {};

  return (
      <Modal
        wrapClassName={classNames('msgModal', scss.login_container)}
        open={visible}
        centered
        title={
          <>
            <span className="text-[#E4E4E4]">
              {/*Risk Index*/}
              {
                t('Risk Index')
              }
            </span>
          </>
        }
        onOk={onOk}
        onCancel={onCancel}
        footer={null}
      >
        <div className="w-full flex justify-center flex-col items-center" >
          <div className="px-[40px] text-[#E4E4E4]">
            {/*The Risk Index shows four levels in this panel: <span className="text-[#D9FDAB]">Low</span>, <span className="text-[#E27860]">Medium</span>, <span className="text-[#E2AE60]">High</span> and Under Liquidation.*/}
            {/*The following three factors help you understand the specific risk components.*/}
            {/*1) Debt: A function of total collateralized assets and total borrowed assets, and is used to determine whether an account is under-collateralized. The higher the value, the higher the risk and the higher the probability of liquidation.*/}
            {/*2) Debt limit: A function of the total collateral assets and the theoretical maximum borrowing value, when 1) {'>'} 2), it means that your total borrowing assets value exceeds the limit, and the probability of liquidation is very high.*/}
            {/*3) Liquidate buffer: The percentage of buffer from liquidation, calculated from 1-1).*/}

            {t('TheRiskIndexShows')}
            <span className="text-[#D9FDAB]">{t('Low')}</span>, <span className="text-[#E27860]">{t('Medium')}</span>, <span className="text-[#E2AE60]">{t('High')}</span> {t('andUnderLiquidation')}
            {
              t('TheFollowingThreeFactors')
            }
          </div>
        </div>
      </Modal>
    // </div>
  );
});
