import className from 'classnames';
import { Skeleton, List } from 'antd';
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import { SDKContext } from 'contexts/SDKProvider';
import market from 'assets/img/title/market.png';
import { useNavigate } from 'react-router-dom';
import useAptosWallet from 'hooks/useAptosWallet';
import { iconsCfg } from 'config/decimals';
import { useTranslation } from 'react-i18next';
import Indexh5 from './IndexH5';

export default function MarketPage() {
  const { t } = useTranslation();
  const { activeWallet, closeModal } = useAptosWallet();
  // @ts-ignore
  const { globalState, indexPageTableData, setIndexPageTableData, setAppLoading, appLoading, isLoginModal, setIsLoginModal } = useContext(SDKContext);
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<String>(indexPageTableData.every(item => item.isShowUSD) ? 'usd' : 'amount');
  const { connected, account, network, connect, ...rest } = useWallet();

  const onClickTab = (tab) => {
    if (tab === 0) {
      setCurrentTab('usd');
      let newData = indexPageTableData.map(item => {
        item.isShowUSD = true;
        return item;
      });
      setIndexPageTableData(newData);
    } else {
      let newData = indexPageTableData.map(item => {
        item.isShowUSD = false;
        return item;
      });
      setCurrentTab('amount');
      setIndexPageTableData(newData);
    }
  }

  return (
    <>
      <Indexh5 />
      <div className="hidden lg:flex  min-h-[100vh] flex flex-col items-center">
        <section className="flex flex-col justify-start min-h-[46vh] w-[1024px] xl:w-[1280px]">
          <section className="w-[500px] text-[48px] font-['BioRhyme-Bold'] mt-6">
            <img src={market} alt='' />
          </section>

          {/* overview */}
          <section className="grid grid-cols-3 h-[134px] gap-3 my-6">
            <div className="border border-[#161616] p-2 rounded-[12px] relative">
              <div className="bg-[#34A7AE] absolute left-0 bottom-0 h-[4px] w-[92%] left-[50%] ml-[-46%] blur z-0" />

              <div className="flex flex-col rounded-[12px] justify-end items-end border border-[#454545] h-[130px] pr-[16px]  pb-[16px] bg-[#292d2ef7]">
                <div className="text-2xl pb-[8px]" style={{
                  textShadow: '0px 0px 6px rgba(255, 255, 255, 0.25)'
                }}>
                  $ {globalState?.total_pledged_value ? parseInt(globalState?.total_pledged_value?.format(9)) : '-'}
                </div>
                <div className="text-[#B4AFAE]">
                  {
                    t('totalDeposit')
                  }
                </div>
              </div>
            </div>
            <div className="border border-[#161616] p-2 rounded-[12px] relative">
              <div className="bg-[#5D9C2B] absolute left-0 bottom-0 h-[4px] w-[92%] left-[50%] ml-[-46%] blur z-0" />

              <div className="flex flex-col rounded-[12px] justify-end items-end border border-[#454545] h-[130px] pr-[16px]  pb-[16px] bg-[#292d2ef7]">
                <div className="text-2xl pb-[8px] " style={{
                  textShadow: '0px 0px 6px rgba(255, 255, 255, 0.25)'
                }}>
                  $ {globalState?.total_borrowed_value ? parseInt(globalState?.total_borrowed_value.format(9)) : '-'}
                </div>
                <div className="text-[#B4AFAE]">
                  {
                    t('totalBorrow')
                  }
                </div>
              </div>
            </div>
            <div className="border border-[#161616] p-2 rounded-[12px] relative">
              <div className="bg-[#92A423] absolute left-0 bottom-0 h-[4px] w-[92%] left-[50%] ml-[-46%] blur z-0" />

              <div className="flex flex-col rounded-[12px] justify-end items-end border border-[#454545] h-[130px] pr-[16px]  pb-[16px] bg-[#292d2ef7]">
                <div className="text-2xl pb-[8px] text-shadow-[0px 0px 6px rgba(255, 255, 255, 0.25)]" style={{
                  textShadow: '0px 0px 6px rgba(255, 255, 255, 0.25)'
                }}>
                  $ {globalState?.total_value_locked ? parseInt(globalState?.total_value_locked.format(9)) : '-'}
                </div>
                <div className="text-[#B4AFAE]">
                  TVL
                </div>
              </div>
            </div>
          </section>

          <section className="flex mt-[50px] justify-between">
            <div className="text-[24px]">
              {/*Market*/}
              {
                t('indexTableTitleMarket')
              }
            </div>

            <div className="flex ml-6 w-[156px] p-[2px] bg-[#404647] rounded-full select-none text-[12px]">
              <div className="hidden text-[#31767A]" />
              <div onClick={() => {
                onClickTab(0);
                // setCurrentTab('usd');
              }} className={className('flex justify-center items-center w-[78px] py-[8px] rounded-full cursor-pointer text-[#B4AFAE] ', currentTab === 'usd' ? ' !text-[#31767A] bg-[#fff]' : ' hover:text-[#fff]')}>
                <span>
                  USD
                </span>
              </div>
              <div onClick={() => {
                onClickTab(1);
                // setCurrentTab('amount');
              }} className={className('flex justify-center items-center w-[78px] py-[8px] rounded-full cursor-pointer text-[#B4AFAE] ', currentTab === 'amount' ? ' !text-[#31767A] bg-[#fff] ' : ' hover:text-[#fff]')}>
                <span>
                  {/*Amount*/}
                  {
                    t('indexTableTitleAmount')
                  }
                </span>
              </div>
            </div>
          </section>

          {/* table */}
          <section className="">
            <div
              className=""
            >

              <table
                className="w-full xl:w-[1280px] border-separate mb-[100px]"
                style={{
                  borderSpacing: '0 1rem',
                }}
              >
                <thead className={`h-[48px]`}>
                <tr className={`text-center`}>
                  <th
                    className="px-4 py-2 text-[#E4E4E4] font-light"
                    style={{
                      // textIndent: '20px',
                    }}
                  >
                    {/*Asset*/}
                    {
                      t('indexTableTheadAssets')
                    }
                  </th>
                  <th className="px-4 py-2 text-[#E4E4E4] font-light">
                    {/*Total Deposit*/}
                    {
                      t('totalDeposit')
                    }
                  </th>
                  <th className="px-4 py-2 text-[#E4E4E4] font-light">
                    {/*Deposit APY*/}
                    {
                      t('indexTableTheadDepositAPY')
                    }
                  </th>
                  <th className="px-4 py-2 text-[#E4E4E4] font-light">
                    {/*Total Borrowing*/}
                    {
                      t('totalBorrow')
                    }
                  </th>
                  <th className="px-4 py-2 text-[#E4E4E4] font-light">
                    {/*Borrow APY*/}
                    {
                      t('indexTableTheadBorrowAPY')
                    }
                  </th>
                  <th className="px-4 py-2 text-[#E4E4E4] font-light">
                    {/*Wallet Balance*/}
                    {
                      t('indexTableTheadWalletBalance')
                    }
                  </th>
                </tr>
                </thead>
                <tbody className="border text-center" style={{}}>

                {
                  indexPageTableData.map((item: any) => {
                    return (
                      <tr onClick={() => {
                        if (!activeWallet) {
                          setIsLoginModal(true);
                          return;
                        }
                        navigate(`/rulesInfo?name=${item.name}`);
                      }} key={item.name} className={'group h-[49px] cursor-pointer border hover:bg-[#282C2D]/50 bg-[#282C2D]/70'}>
                        <td className={className('rounded-tl-xl  text-[16px]  font-normal rounded-bl-xl ', 'px-4', 'py-2', '')}>
                          <div className='flex items-center '>
                            <img className="px-3" src={iconsCfg?.[item.name]} alt='' />
                            {
                              item.name
                            }
                          </div>
                        </td>

                        <td className={className('   text-[16px]  font-normal  ', 'px-4', 'py-2', '')}>
                          {item.isShowUSD ? `$${Number(item.deposited_value)?.toFixed(2)}` : Number(item.deposited_amount)?.toFixed(2)}
                        </td>

                        <td className={className('   text-[16px]  font-normal   ', 'px-4', 'py-2', '')}>
                          <div className="text-[#4AFCDC]">
                            {item.current_supply_apr}
                          </div>
                        </td>

                        <td className={className('   text-[16px]  font-normal   ', 'px-4', 'py-2', '')}>
                          {/*{parseInt(item.borrowed_value)}*/}
                          {item.isShowUSD ? `$${Number(item.borrowed_value)?.toFixed(2)}` : Number(item.borrowed_amount)?.toFixed(2)}
                        </td>

                        <td className={className('   text-[16px]  font-normal   ', 'px-4', 'py-2', '')}>
                          <div className="text-[#AAF56F]">
                            {item.current_borrow_apr}
                          </div>
                        </td>

                        <td className={className('rounded-tr-xl   text-[16px]  font-normal rounded-br-xl  ', 'px-4', 'py-2', '')}>
                          {item.wallet_value}
                        </td>
                      </tr>
                    );
                  })
                }

                </tbody>
              </table>
              {
                <Skeleton active round loading={appLoading || !indexPageTableData?.length} />
              }
            </div>

          </section>

        </section>
        {/*<Footer />*/}
      </div>
    </>
  );
}
